import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import styles from "./DeletePostPopup.module.scss";
import { TPost } from "typings/post";

type DeletePostPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  post?: TPost;
  deletePost: (postId: number) => void;
};

const DeletePostPopup: FC<DeletePostPopupProps> = ({ post, deletePost, handleHide, isOpen }) => {
  const closePopup = () => handleHide();

  return (
    <Dialog
      visible={isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <i className={classNames("pi pi-exclamation-circle", styles["warning-icon"])} />
        <p className={styles["title"]}>Вы уверены, что хотите удалить пост?</p>
        <p className={styles["text"]}>{post?.header || ""}</p>
        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button
            className={styles["remove-button"]}
            onClick={() => post?.id && deletePost(post?.id)}
          >
            Удалить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeletePostPopup;
