import { ERRORS } from "constants/errors";
import { Button } from "primereact/button";
import { phrases } from "constants/phrases";
import { useState } from "react";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { TAuthData } from "typings/auth";
import { loginThunk } from "slices/authSlice";
import { useNavigate } from "react-router";
import { useAppDispatch } from "utils/hooks";
import { forgotPassword } from "api/forgot-password";
import { DEFAULT_LOGIN_DATA } from "constants/auth";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";

import Logo from "../../assets/login-logo.png";
import styles from "./Login.module.scss";
import ForgetPasswordPopup from "popups/forget-password/ForgetPasswordPopup";

export const Login = () => {
  const [loginData, setLoginData] = useState<TAuthData>(DEFAULT_LOGIN_DATA);
  const [isOpenPwdRecoveryPopup, setIsOpenPwdRecoveryPopup] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOpenPwdRecoveryPopup = () => setIsOpenPwdRecoveryPopup(true);
  const handleClosePwdRecoveryPopup = () => setIsOpenPwdRecoveryPopup(false);

  const handleUserData = (e) =>
    setLoginData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const onLogin = async () => {
    try {
      const response = await dispatch(loginThunk(loginData));
      if (response.payload.token) {
        dispatch(addSuccessMessage(phrases.login_sucess));
        navigate("/");
      } else throw new Error(response.payload.message || phrases.smthWentWrongText);
    } catch (err: any) {
      if (err.message.includes(ERRORS.invalid_email.backend_value))
        return dispatch(addErrorMessage(ERRORS.invalid_email.alert));
      else if (err.message.includes(ERRORS.invalid_pwd.backend_value))
        return dispatch(addErrorMessage(ERRORS.invalid_pwd.alert));
      else return dispatch(addErrorMessage(err.message || phrases.smthWentWrong));
    }
  };

  const forgotPswd = async (email: string) => {
    try {
      const response = await forgotPassword(email);
      if (response.status === 200 || response.status === 201) {
        dispatch(addSuccessMessage(phrases.send_pwd));
        handleClosePwdRecoveryPopup();
      } else throw new Error(response.data);
    } catch (err: any) {
      if (err.response?.data?.message.includes(ERRORS.user_not_found.backend_value))
        return dispatch(addErrorMessage(ERRORS.user_not_found.alert));
      return dispatch(addErrorMessage(err.response?.data?.message || phrases.smthWentWrong));
    }
  };

  return (
    <div className={styles["login-body"]}>
      <div className={styles["login-wrapper"]}>
        <div className={styles["logo-wrap"]}>
          <img src={Logo} alt="logo" />
        </div>
        <InputText
          maxLength={320}
          id="email"
          name="email"
          placeholder="Email"
          value={loginData.email}
          onChange={handleUserData}
          className={styles["login-input"]}
        />
        <Password
          maxLength={256}
          id="password"
          name="password"
          placeholder="Пароль"
          feedback={false}
          toggleMask
          value={loginData.password}
          onChange={handleUserData}
          className={styles["login-input"]}
        />
        <Button
          label="Войти"
          type="button"
          className={styles["login-button"]}
          onClick={onLogin}
        ></Button>
        <p className={styles["pwd-recovery-text"]} onClick={handleOpenPwdRecoveryPopup}>
          Забыли пароль?
        </p>
      </div>

      <ForgetPasswordPopup
        isOpen={isOpenPwdRecoveryPopup}
        handleHide={handleClosePwdRecoveryPopup}
        forgotPswd={forgotPswd}
      />
    </div>
  );
};
