import { FC } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { TDirection } from "typings/direction";
import { renderColor, renderColumn, renderDate } from "./actions";

import styled from "./DirectionsTable.module.scss";

type DirectionsTableProps = {
  isLoadingTable: boolean;
  directionsList: TDirection[];
  handleOpenEditDirectionPopup: () => void;
  handleOpenRemoveDirectionPopup: () => void;
  changeSelectedDirection: (direction: TDirection) => void;
};

const DirectionsTable: FC<DirectionsTableProps> = (props) => {
  const renderAction = (rowData: TDirection) => {
    return (
      <div className={styled["col-action-wrapper"]} onClick={() => { }}>
        <button
          className={classNames(styled["action-btn"], styled["edit-btn"], styled["settings-btn"])}
          onClick={() => {
            props.changeSelectedDirection(rowData);
            props.handleOpenEditDirectionPopup();
          }}
        >
          <i className="pi pi-pencil" />
        </button>
        <button
          className={classNames(styled["action-btn"], styled["delete-btn"], styled["settings-btn"])}
          onClick={() => {
            props.changeSelectedDirection(rowData);
            props.handleOpenRemoveDirectionPopup();
          }}
        >
          <i className="pi pi-trash" />
        </button>
      </div>
    );
  };

  return (
    <div className={styled["table-wrapper"]}>
      <div className={styled["table-header"]}>
        <p className={styled["table-name"]}>Направления</p>
      </div>
      <DataTable
        value={props.directionsList}
        responsiveLayout="scroll"
        dataKey="id"
        paginator
        rows={15}
        loading={props.isLoadingTable}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        emptyMessage="Направлений не найдено"
      >
        <Column field="id" header="ID" style={{ width: "10%" }} body={renderColumn("id")}></Column>
        <Column
          field="header"
          header="Заголовок"
          style={{ width: "30%" }}
          body={renderColumn("header")}
        ></Column>
        <Column field="color" header="Цвет" style={{ width: "20%" }} body={renderColor}></Column>
        <Column
          field="createdAt"
          header="Создано"
          style={{ width: "15%" }}
          body={renderDate("createdAt")}
        ></Column>
        <Column
          field="updatedAt"
          header="Обновлено"
          style={{ width: "15%" }}
          body={renderDate("updatedAt")}
        ></Column>
        <Column field="actions" header="" style={{ width: "10%" }} body={renderAction}></Column>
      </DataTable>
    </div>
  );
};

export default DirectionsTable;
