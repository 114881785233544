import { Card } from "primereact/card";
import styles from "./ProfileInfo.module.scss";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useRef, useState } from "react";
import { Link, useRevalidator } from "react-router-dom";
import { TAdminProfile } from "typings/admin";
import { ADMIN_ROLES } from "constants/admins";
import { editAdminView } from "api/admins/editAdminView";
import { useDispatch } from "react-redux";
import { phrases } from "constants/phrases";
import { addErrorMessage } from "slices/toastSlice";
import { uploadFile } from "api/storage/uploadFile";
import { updateAdminInfo } from "api/admins/editAdminInfo";
import { FileUpload } from "primereact/fileupload";
import { useGetRoles } from "utils/useGetRoles";
import user_placeholder from "assets/user_placeholder.svg";
import pager from "assets/pager.svg";

type linkKeys = "id" | "email" | "website" | "telegram" | "instagram" | "youtube";

const links: {
  icon?: string;
  customIcon?: string;
  key: linkKeys;
  getLink?: (string) => string;
  getText?: (string) => string;
}[] = [
  { customIcon: pager, key: "id", getLink: (id) => `/admins/${id}` },
  { icon: "pi pi-envelope", key: "email", getLink: (email) => `mailto:${email}` },
  { icon: "pi pi-globe", key: "website" },
  {
    icon: "pi pi-telegram",
    key: "telegram",
    getText: (telegram) => telegram.replace("https://t.me/", ""),
  },
  {
    icon: "pi pi-instagram",
    key: "instagram",
    getText: (instagram) => instagram.replace("https://www.instagram.com/", "").replace("/", ""),
  },
  {
    icon: "pi pi-youtube",
    key: "youtube",
    getText: (youtube) => youtube.replace("https://www.youtube.com/@", ""),
  },
];

type ProfileInfoProps = {
  profile: TAdminProfile;
};

const ProfileInfo = ({ profile }: ProfileInfoProps) => {
  const [checked, setChecked] = useState(profile?.isPromo ?? false);

  const { isAdmin } = useGetRoles();

  const dispatch = useDispatch();
  const onChangePromo = (e) => {
    const newValue = e.target.checked;
    setChecked(newValue);
    try {
      editAdminView(profile.id, { isPromo: newValue });
    } catch (error: any) {
      dispatch(addErrorMessage(error.response.data.message || phrases.smthWentWrongText));
    }
  };
  const showDisplayHomeCheckbox = profile?.role === "speaker";
  const revalidator = useRevalidator();

  const uploaderRef = useRef<FileUpload>(null);

  const customSelect = async () => {
    setTimeout(() => uploaderRef.current?.upload(), 0);
  };

  const customUploader = async ({ files }) => {
    const file = files[files.length - 1];
    if (!file) return;
    try {
      let imageUrl;
      if (file) {
        const fileFormData = new FormData();
        fileFormData.append("file", file);
        const {
          data: { url },
        } = await uploadFile(fileFormData);
        imageUrl = url;
      }
      await updateAdminInfo(profile.id, { ...profile, avatar: imageUrl });
      revalidator.revalidate();
    } catch (error: any) {
      dispatch(addErrorMessage(error?.response?.data?.message || phrases.smthWentWrongText));
    }
  };

  return (
    <Card>
      <div className={styles["card-body"]}>
        <FileUpload
          accept="image/*"
          maxFileSize={1000000}
          customUpload
          uploadHandler={customUploader}
          onSelect={customSelect}
          ref={uploaderRef}
          itemTemplate={() => null}
          contentClassName={styles.uploadContent}
          headerTemplate={(options) => {
            return (
              <div className={styles.imgWrapper}>
                <div className={styles.uploadWrapper}>{options.chooseButton}</div>
                <img
                  onClick={(event) => {
                    options?.chooseButton?.props?.onClick(event);
                  }}
                  src={profile?.avatar ?? user_placeholder}
                  alt="profile"
                  className={styles["profile-image"]}
                />
              </div>
            );
          }}
          chooseLabel="Browse"
        />

        <div className={styles["profile-name"]}>
          <h6>
            {profile?.firstName} {profile?.lastName}
          </h6>
          <p>{`@${profile?.id}`}</p>
        </div>
        <h6>{ADMIN_ROLES.find((el) => el.value === profile?.role)?.label || "Неизвестный"}</h6>
        {profile?.biography ? <p className={styles["profile-bio"]}>{profile?.biography}</p> : null}
        <div className={styles["links"]}>
          {links
            .filter((link) => profile[link.key])
            .map((link) => (
              <div key={link.key} className={styles["link"]}>
                {link.icon && <i className={link.icon} />}
                {link.customIcon && <img src={link.customIcon} alt="custom icon" />}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={link.getLink ? link.getLink(profile[link.key]) : String(profile[link.key])}
                >
                  {link.getText ? link.getText(profile[link.key]) : profile[link.key]}
                </a>
              </div>
            ))}
          {showDisplayHomeCheckbox && isAdmin ? (
            <div className={styles["checkbox-wrapper"]}>
              <div className={styles["checkbox"]}>
                <Checkbox
                  inputId="display_on_home_page_checkbox"
                  checked={checked}
                  onChange={onChangePromo}
                />
              </div>
              <label htmlFor="display_on_home_page_checkbox">Выводить на главную</label>
            </div>
          ) : null}
        </div>
        <Link to="settings" className="w-full">
          <Button
            label="Настройки"
            icon="pi pi-angle-right"
            iconPos="right"
            className="p-button-sm w-full"
          />
        </Link>
      </div>
    </Card>
  );
};

export default ProfileInfo;
