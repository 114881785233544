import { TDatesPairType } from "typings/dates";
import { format, isValid } from "date-fns";
import { getDatesFromString } from "utils/dates";
import { useState, FC, useRef, useEffect } from "react";

import ReactInputDateMask from "./ReactInputDateMask";

import styles from "./InputDate.module.scss";

type InputDateTypes = {
  dates: TDatesPairType;
  setDates: (dates: TDatesPairType) => void;
};

const getInputValueFromDates = (dates: TDatesPairType) =>
  dates
    ? dates
      .map((date) => {
        if (date) {
          try {
            const dateString = format(date, "dd.MM.yyyy");
            return dateString;
          } catch (err) {
            console.error(err);
            return undefined;
          }
        } else {
          return undefined;
        }
      })
      ?.filter((el) => el)
      ?.join(" - ")
    : "";

const InputDate: FC<InputDateTypes> = ({ dates, setDates }) => {
  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const onInputChange = (value) => {
    setInputValue(value);
  };

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const newInputValue = getInputValueFromDates(dates);
    if (newInputValue !== inputValue) {
      setInputValue(newInputValue);
    }
  }, [dates]);

  const changeToActive = () => {
    setIsActive(true);
  };

  useEffect(() => {
    if (isActive) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isActive]);

  const onBlur = () => {
    const newDates = getDatesFromString(inputValue);
    const isDatesValid = !(newDates || []).some((el) => !isValid(el));
    if (isDatesValid) {
      setDates(newDates);
    }
    setIsActive(false);
  };

  return (
    <div className={styles["selected-date"]}>
      <div className={styles["input-wrapper"]} onClick={changeToActive}>
        <ReactInputDateMask
          value={inputValue}
          onChange={onInputChange}
          onBlur={onBlur}
          inputRef={inputRef}
        />
      </div>
    </div>
  );
};

export default InputDate;
