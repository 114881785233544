import styles from "./ReviewCard.module.scss";
import { InputText } from "primereact/inputtext";
import { TReview, TReviewWithId } from "typings/reviews";
import AddedImage from "components/common/added-image/AddedImage";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import SingleFileUpload from "components/common/single-file-upload/SingleFileUpload";
import { useState } from "react";
import { uploadFile } from "api/storage/uploadFile";
import PopupDateTimeCalendar from "popups/date-time/PopupDateTimeCalendar";

type ReviewCardProps = {
  review: TReviewWithId;
  editReview: (reviewId: string, data: TReview) => void;
  deleteReview: (reviewId: string) => void;
};

type Inputs = {
  name: string;
  age: string;
  date: Date | null;
  text: string;
  fileId: string;
  imageFileId: string;
};

const ReviewCard = ({ review, editReview, deleteReview }: ReviewCardProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const { register, handleSubmit, control } = useForm<Inputs>({
    defaultValues: {
      name: review.name,
      age: review.age,
      date: review.date ? new Date(review.date) : null,
      text: review.text,
    },
    mode: "onBlur",
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    editReview(review.id, {
      ...review,
      ...data,
      date: data.date ? data.date.toISOString() : review.date,
    });
  };

  const deleteFile = () => {
    editReview(review.id, { ...review, file: undefined });
  };

  const deleteImageFile = () => {
    editReview(review.id, { ...review, imageFile: undefined });
  };

  const addFile = (file: File | null) => {
    const upload = async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      const response = await uploadFile(formData);
      editReview(review.id, { ...review, file: response.data });
    };
    if (!file) {
      deleteFile();
    } else {
      upload(file);
    }
    setFile(file);
  };

  const addImageFile = (file: File | null) => {
    const upload = async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      const response = await uploadFile(formData);
      editReview(review.id, { ...review, imageFile: response.data });
    };
    if (!file) {
      deleteImageFile();
    } else {
      upload(file);
    }
    setImageFile(file);
  };

  return (
    <form
      className={styles["card-body"]}
      onBlur={handleSubmit(onSubmit)}
      onSubmit={(event) => event.preventDefault()}
    >
      <div className={styles["first-row"]}>
        <InputText
          maxLength={600}
          {...register("name")}
          placeholder="Имя"
          className="flex-1 w-full p-inputtext-light-bg"
        />
        <InputText
          maxLength={100}
          {...register("age")}
          placeholder="Возраст"
          className="flex-1 w-full p-inputtext-light-bg"
        />
        <Controller
          name="date"
          control={control}
          render={({ field }) => (
            <PopupDateTimeCalendar
              placeholder="Дата отзыва"
              inputClassName="flex-1 w-full p-inputtext-light-bg"
              date={field.value}
              setDate={(date) => {
                field.onChange(date);
                handleSubmit(onSubmit)();
              }}
            />
          )}
        />
        <button
          type="button"
          className={styles["delete-item-button"]}
          onClick={(event) => deleteReview(review.id)}
        >
          <i className={"pi pi-trash"} />
        </button>
      </div>
      <InputText
        maxLength={1300}
        {...register("text")}
        placeholder="Текст отзыва"
        className="w-full p-inputtext-light-bg"
      />
      <div className={styles["added-image-wrapper"]}>
        {!file && review.file ? (
          <AddedImage name={review.file.name} deleteFile={deleteFile} />
        ) : (
          <SingleFileUpload
            file={file}
            setFile={addFile}
            chooseLabel="Загрузить видео"
            accept="video/*"
            maxFileSize={10000000}
          />
        )}
      </div>
      <div className={styles["added-image-wrapper"]}>
        {!imageFile && review.imageFile ? (
          <AddedImage name={review.imageFile.name} deleteFile={deleteImageFile} />
        ) : (
          <SingleFileUpload
            file={imageFile}
            setFile={addImageFile}
            chooseLabel="Загрузить обложку видео"
          />
        )}
      </div>
    </form>
  );
};

export default ReviewCard;
