import { FC } from "react";
import { Button } from "primereact/button";

import styled from "./Directions.module.scss";

type HeaderLayoutProps = {
    handleOpenAddDirectionPopup: () => void;
};

const HeaderLayout: FC<HeaderLayoutProps> = (props) => {
    return (
        <div className={styled["header-layout"]}>
            <Button
                type="button"
                className={styled["add-button"]}
                onClick={props.handleOpenAddDirectionPopup}
            >
                <i className="pi pi-plus" />
                Добавить направление
            </Button>
        </div>
    );
};

export default HeaderLayout;
