import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { TCustomSubscriptionRequest, TUser } from "typings/user";

import styles from "./CustomSubscriptionPopup.module.scss";
import { Controller, useForm } from "react-hook-form";
import PopupDateTimeCalendar from "popups/date-time/PopupDateTimeCalendar";
import { classNames } from "primereact/utils";
import { useEffect } from "react";
import { endOfDay } from "date-fns";
import formattingDate from "utils/formattingDate";

type CustomSubscriptionProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedUser: TUser | null;
  saveCustomSubscription: (userId: number, data: TCustomSubscriptionRequest) => void;
};

type TInputs = {
  isOn: boolean;
  untilAt?: Date;
};

const CustomSubscription = (props: CustomSubscriptionProps) => {
  const { selectedUser } = props;

  const { subscriptionInfo } = selectedUser || {};

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid },
  } = useForm<TInputs>({
    defaultValues: {
      isOn: subscriptionInfo?.isCustom || false,
      untilAt: subscriptionInfo?.untilAt ? new Date(subscriptionInfo?.untilAt) : undefined,
    },
  });

  const closePopup = () => {
    props.handleHide();
    setValue("isOn", false);
    setValue("untilAt", undefined);
  };

  useEffect(() => {
    if (props.selectedUser) {
      const { isCustom, untilAt } = props.selectedUser.subscriptionInfo || {};
      if (isCustom) {
        setValue("isOn", true);
        if (untilAt) {
          setValue("untilAt", new Date(untilAt));
        }
      }
    }
  }, [props.selectedUser, setValue]);

  const onSubmit = async ({ isOn, untilAt }: TInputs) => {
    if (!props.selectedUser || !untilAt) {
      return;
    }
    props.saveCustomSubscription(props.selectedUser?.id, {
      isOn: isOn,
      untilAt: untilAt.toISOString(),
    });
    closePopup();
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <form className={styles["content"]} onSubmit={handleSubmit(onSubmit)}>
        <p className={styles["title"]}>Редактирование пользователя</p>
        <div className={styles["is-on-wrapper"]}>
          <label htmlFor="isOn">Активировать подписку</label>
          <Controller
            name="isOn"
            control={control}
            render={({ field }) => (
              <Checkbox
                onChange={(event) => field.onChange(event.target.checked)}
                checked={field.value}
                inputId="isOn"
              />
            )}
          />
        </div>
        <div className={styles["date-wrapper"]}>
          <label>Дата окончания</label>
          <Controller
            name="untilAt"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <PopupDateTimeCalendar
                minDate={endOfDay(new Date())}
                placeholder="Дата"
                inputClassName={classNames(styles["date"], "p-inputtext-light-bg")}
                date={field.value}
                setDate={(date) => {
                  field.onChange(date);
                  handleSubmit(onSubmit);
                }}
              />
            )}
          />
        </div>
        <div className={styles.dateCancelSubscription}>
          <p>
            Дата отмены подписки:{" "}
            <b>
              {props.selectedUser?.subscriptionInfo?.offAt
                ? formattingDate(props.selectedUser?.subscriptionInfo?.offAt, "dd.mm.yyyy")
                : "Не отменена"}
            </b>
          </p>
        </div>
        <div className={styles["buttons-container"]}>
          <Button type="button" className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button disabled={!isValid} className={styles["edit-button"]}>
            Сохранить
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default CustomSubscription;
