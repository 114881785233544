import { Button } from "primereact/button";
import { FC } from "react";

import styled from "./Course.module.scss";
import { Link } from "react-router-dom";

type CourseProps = {};

const Course: FC<CourseProps> = () => {
  return (
    <div className={styled["page-container"]}>
      <div className={styled["empty-course"]}>
        <p>Курс не найден</p>
        <Link to={`/courses`}>
          <Button className={styled["back-courses-btn"]}>Вернуться к списку курсов</Button>
        </Link>
      </div>
    </div>
  );
};

export default Course;
