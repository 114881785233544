import styles from "./EarningsAdditional.module.scss";
import { TPayment } from "typings/payment";
import Balance from "./Balance";
import Changes from "./Changes";
import PaymentsTable from "./table/PaymentsTable";
import { useCallback, useEffect, useState } from "react";
import { DEFAULT_PARAMS } from "constants/table-params";
import { tableControlsType } from "typings/table-controls";
import { Button } from "primereact/button";
import { Link, useParams } from "react-router-dom";
import { addErrorMessage } from "slices/toastSlice";
import { useDispatch } from "react-redux";
import { getBalancesHistory, getBalancesHistoryMy } from "api/balances-history";
import { phrases } from "constants/phrases";
import { useGetRoles } from "utils/useGetRoles";

const EarningsAdditional = () => {
  const [paymentsList, setCoursesList] = useState<TPayment[]>([]);
  const [params, setParams] = useState<tableControlsType>(DEFAULT_PARAMS);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isAdmin } = useGetRoles();

  const getPayments = useCallback(async () => {
    try {
      let res;
      if (id) {
        res = await getBalancesHistory({ ...params, adminId: id });
      } else {
        res = await getBalancesHistoryMy(params);
      }
      setCoursesList(res.data.data);
      setTotalRecords(res.data.totalItems);
      setIsLoadingTable(false);
    } catch (error: any) {
      dispatch(addErrorMessage(error.response.data.message || phrases.smthWentWrongText));
    }
  }, [dispatch, id, params]);

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["button-back-wrapper"]}>
        {isAdmin && (
          <Link to="../courses">
            <Button className="p-button-sm" icon="pi pi-angle-left" iconPos="left" label="Назад" />
          </Link>
        )}
      </div>
      <div className={styles["balance-wrapper"]}>
        <Balance />
      </div>
      <Changes />
      <div className={styles["payments-table-wrapper"]}>
        <PaymentsTable
          params={params}
          setParams={setParams}
          paymentsList={paymentsList}
          totalRecords={totalRecords}
          isLoadingTable={isLoadingTable}
        />
      </div>
    </div>
  );
};

export default EarningsAdditional;
