import { FC } from "react";
import { classNames } from "primereact/utils";
import { TDatesPairType } from "typings/dates";
import { TDefaultRefferalSystemStatistics } from "typings/refferal-system";

import styled from "./RefferalSystem.module.scss";
import PopupCalendar from "popups/popup-calendar/PopupCalendar";

type RefferalSystemLayoutProps = {
    dates: TDatesPairType;
    setDates: (dates: TDatesPairType) => void;
    statistics: TDefaultRefferalSystemStatistics;
};

const RefferalSystemLayout: FC<RefferalSystemLayoutProps> = (props) => {
    return (
        <div className={styled["content"]}>
            <PopupCalendar
                disabled={false}
                dates={props.dates}
                setDates={(dates) => props.setDates(dates)}
            />

            <div className={styled["card-list"]}>
                <div className={classNames(styled["card"], styled["green-card"])}>
                    <p className={styled["card-title"]}>Регистраций</p>
                    <span className={styled["row"]}>
                        <i className={classNames("pi pi-cog", styled["settings-icon"])} />
                        <p className={styled["card-value"]}>{props.statistics.countSingUp}</p>
                    </span>
                </div>
                <div className={classNames(styled["card"])}>
                    <p className={styled["card-title"]}>Количество оплаченных подписок</p>
                    <span className={styled["row"]}>
                        <i className={classNames("pi pi-cog", styled["settings-icon"])} />
                        <p className={styled["card-value"]}>{props.statistics.countFirstPaySubscriptions}</p>
                    </span>
                </div>
                <div className={classNames(styled["card"])}>
                    <p className={styled["card-title"]}>Прибыль с оплаченных подписок</p>
                    <span className={styled["row"]}>
                        <i className={classNames("pi pi-cog", styled["settings-icon"])} />
                        <p className={styled["card-value"]}>
                            {props.statistics.sumAmountFirstPaySubscriptions}
                        </p>
                    </span>
                </div>
                <div className={classNames(styled["card"], styled["green-card"])}>
                    <p className={styled["card-title"]}>Общая прибыль</p>
                    <span className={styled["row"]}>
                        <i className={classNames("pi pi-cog", styled["settings-icon"])} />
                        <p className={styled["card-value"]}>{props.statistics.sumAmount}</p>
                    </span>
                </div>
                <div className={classNames(styled["card"])}>
                    <p className={styled["card-title"]}>Количество продленных подписок</p>
                    <span className={styled["row"]}>
                        <i className={classNames("pi pi-cog", styled["settings-icon"])} />
                        <p className={styled["card-value"]}>{props.statistics.countNextPaySubscriptions}</p>
                    </span>
                </div>
                <div className={classNames(styled["card"])}>
                    <p className={styled["card-title"]}>Прибыль с продленных подписок</p>
                    <span className={styled["row"]}>
                        <i className={classNames("pi pi-cog", styled["settings-icon"])} />
                        <p className={styled["card-value"]}>{props.statistics.sumAmountNextPaySubscriptions}</p>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default RefferalSystemLayout;
