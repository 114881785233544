import { Column } from "primereact/column";
import { TCourse } from "typings/course";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { tableControlsType } from "typings/table-controls";
import { FC, useEffect, useState } from "react";
import {
  renderAuthor,
  renderColumn,
  renderCurator,
  renderDate,
  renderDirection,
  renderStatus,
} from "./actions";

import styled from "./CoursesTable.module.scss";
import useDebounce from "utils/use-debounce";
import { Link } from "react-router-dom";

type CoursesTableProps = {
  params: tableControlsType;
  coursesList: TCourse[];
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  totalRecords: number;
  isLoadingTable: boolean;
  handleOpenRemoveCoursePopup: () => void;
  changeSelectedCourse: (data: TCourse) => void;
};

const CoursesTable: FC<CoursesTableProps> = (props) => {
  const [searchLocalValue, setSearchLocalValue] = useState<string>("");

  const debouncedSearchValue = useDebounce(searchLocalValue, 500);

  useEffect(() => {
    if (debouncedSearchValue !== props.params?.search) {
      props.setParams((prev) => ({ ...prev, search: debouncedSearchValue }));
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (searchLocalValue !== props.params?.search && typeof props.params?.search === "string") {
      setSearchLocalValue(props.params?.search);
    }
  }, [props.params?.search]);

  const onPage = (event) => {
    props.setParams(event);
  };

  const renderAction = (rowData: TCourse) => {
    return (
      <div className={styled["col-action-wrapper"]} onClick={() => {}}>
        <Link to={`${rowData.id}`}>
          <button
            className={classNames(styled["action-btn"], styled["edit-btn"], styled["settings-btn"])}
          >
            <i className="pi pi-pencil" />
          </button>
        </Link>
        <button
          className={classNames(styled["action-btn"], styled["delete-btn"], styled["settings-btn"])}
          onClick={() => {
            props.changeSelectedCourse(rowData);
            props.handleOpenRemoveCoursePopup();
          }}
        >
          <i className="pi pi-trash" />
        </button>
      </div>
    );
  };

  const onColumnSort = (params) => {
    props.setParams((prev) => ({
      ...prev,
      OrderBy: params.sortField,
      sortOrder: params.sortOrder,
    }));
  };

  return (
    <div className={styled["table-wrapper"]}>
      <div className={styled["table-header"]}>
        <p className={styled["table-name"]}>Курсы</p>
        <span
          className={classNames("p-input-icon-left p-main-input", styled["search-input-wrapper"])}
        >
          <i className="pi pi-search" />
          <InputText
            maxLength={700}
            value={searchLocalValue}
            type="text"
            placeholder="Поиск"
            onChange={(e) => setSearchLocalValue(e.target.value)}
            className={styled["search-input"]}
          />
        </span>
      </div>
      <DataTable
        value={props.coursesList}
        responsiveLayout="scroll"
        dataKey="id"
        paginator
        lazy
        first={props.params.first}
        rows={15}
        totalRecords={props.totalRecords}
        onPage={onPage}
        loading={props.isLoadingTable}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        emptyMessage="Курсов не найдено"
        sortField={props.params.OrderBy}
        sortOrder={props.params.sortOrder}
        onSort={onColumnSort}
        removableSort
      >
        <Column
          sortable
          field="id"
          header="ID"
          style={{ width: "10%" }}
          body={renderColumn("id")}
        ></Column>
        <Column
          sortable
          field="name"
          header="Название"
          style={{ width: "20%" }}
          body={renderColumn("name")}
        ></Column>
        <Column
          field="direction"
          header="Направление"
          style={{ width: "10%" }}
          body={renderDirection}
        ></Column>
        <Column
          field="speaker"
          header="Автор"
          style={{ width: "10%" }}
          body={renderAuthor}
        ></Column>
        <Column
          field="createdAt"
          header="Создано"
          style={{ width: "10%" }}
          body={renderDate("createdAt")}
        ></Column>
        <Column
          field="updatedAt"
          header="Обновлено"
          style={{ width: "10%" }}
          body={renderDate("updatedAt")}
        ></Column>
        <Column
          field="curator"
          header="Куратор"
          style={{ width: "20%" }}
          body={renderCurator}
        ></Column>
        <Column
          field="status"
          header="Статус"
          style={{ width: "10%" }}
          body={renderStatus}
        ></Column>
        <Column field="actions" header="" style={{ width: "10%" }} body={renderAction}></Column>
      </DataTable>
    </div>
  );
};

export default CoursesTable;
