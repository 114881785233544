import BreadCrumb from "components/BreadCrumb";
import { useMatches } from "react-router-dom";

import { Params } from "react-router-dom";

interface IMatches {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: unknown;
}

type HandleType = {
  crumb: (data) => { label?: string; to?: string };
};

type AccType = { path: string; arr: { label: string; to: string }[] };

const BreadCrumbNav = () => {
  const matches: IMatches[] | undefined = useMatches();
  const crumbs = matches
    ?.filter((match) => Boolean(match.handle && (match.handle as HandleType).crumb))
    ?.map((match) => {
      const crumb = (match.handle as HandleType).crumb(match.data);
      return crumb;
    });

  const initialAcc: { path: string; arr: { label: string; to: string }[] } = { path: "", arr: [] };

  const { arr } = crumbs.reduce((acc: AccType, value) => {
    if (value && value.label && value.to) {
      const path = acc.path + "/" + value.to || "";
      acc.arr.push({ label: value.label, to: path });
      return { path, arr: acc.arr };
    }
    return acc;
  }, initialAcc);

  return (
    <div className="top-bar">
      <BreadCrumb model={arr} />
    </div>
  );
};

export default BreadCrumbNav;
