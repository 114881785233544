import { useCallback } from "react";
import styled from "./Tag.module.scss";
import chroma from "chroma-js";

const Tag = ({ name, color }) => {
  const getBgColor = useCallback(() => {
    try {
      return chroma(color).luminance() > 0.7
        ? chroma(color).darken(1.5)
        : chroma(color).brighten(2.5);
    } catch (err) {
      console.error(err);
    }
  }, [color]);

  return (
    <span
      className={styled["tag"]}
      style={{
        color,
        backgroundColor: getBgColor(),
      }}
    >
      {name}
    </span>
  );
};

export default Tag;
