import { FC } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import styled from "./Courses.module.scss";

type HeaderLayoutProps = {
  handleOpenAddCoursePopup: () => void;
  directions: { value: number; label: string }[];
  selectedDirection: number;
  changeSelectedDirection: (direction: number) => void;
  speakers: { value: number; label: string }[];
  selectedSpeaker: number;
  changeSelectedSpeaker: (speakerId: number) => void;
};

const HeaderLayout: FC<HeaderLayoutProps> = (props) => {
  return (
    <div className={styled["header-layout"]}>
      <Button
        type="button"
        className="p-button-sm"
        icon="pi pi-plus"
        label="Добавить курс"
        onClick={props.handleOpenAddCoursePopup}
      />
      <div className={styled["dropdown-wrapper"]}>
        <Dropdown
          options={props.directions}
          onChange={(e) => props.changeSelectedDirection(e.value)}
          value={props.selectedDirection}
          placeholder="Выберите направление"
          className={styled["dropdown"]}
        />
      </div>
      <div className={styled["dropdown-wrapper"]}>
        <Dropdown
          options={props.speakers}
          onChange={(e) => props.changeSelectedSpeaker(e.value)}
          value={props.selectedSpeaker}
          placeholder="Выберите спикера"
          className={styled["dropdown"]}
        />
      </div>
    </div>
  );
};

export default HeaderLayout;
