import { FC } from "react";

import Courses from "features/Courses/Courses";

type CoursesPageProps = {};

const CoursesPage: FC<CoursesPageProps> = () => {
    return <Courses />;
};

export default CoursesPage;
