import { Button } from "primereact/button";
import { FC } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";

import styled from "./Promo.module.scss";
import { phrases } from "constants/phrases";
import { useLoaderData, useRevalidator } from "react-router";
import { addErrorMessage } from "slices/toastSlice";
import { useAppDispatch } from "utils/hooks";
import { updateFaqElement } from "api/faq/updateFaqElement";
import { TFaqElementUpdate } from "typings/faq";
import FaqElement from "components/common/FaqElement/FaqElement";
import { addFaqElement } from "api/faq/addFaqElement";
import { deleteFaqElement } from "api/faq/deleteFaqElement";
import { TFaqLoaderResult } from "typings/loaders";

type PopularFaqProps = {
  search: string;
};

const PopularFaq: FC<PopularFaqProps> = ({ search }) => {
  const { faq, tags } = useLoaderData() as TFaqLoaderResult;

  const localFaq = faq
    .map((el, index) => ({ ...el, index }))
    .filter((el) => el.question.toLowerCase().includes(search.toLowerCase()));

  const revalidator = useRevalidator();
  const dispatch = useAppDispatch();

  const addQuestion = async () => {
    try {
      await addFaqElement("income", { answer: "", question: "", tagIds: [] });
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
    revalidator.revalidate();
  };

  const editFaqElement = async (id: number | string, faq: TFaqElementUpdate) => {
    try {
      await updateFaqElement("income", id, {
        answer: faq.answer,
        question: faq.question,
        tagIds: faq.tagIds,
      });
      revalidator.revalidate();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
  };

  const onDeleteFaqElement = async (faqElementId: number) => {
    try {
      await deleteFaqElement("income", faqElementId);
      revalidator.revalidate();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
  };

  const addTag = async (elementId: number, tagId: number) => {
    const oldFaq = faq.find((faqElement) => faqElement.id === elementId);
    if (!oldFaq) return;
    await editFaqElement(elementId, {
      ...oldFaq,
      tagIds: tagId ? [tagId] : [],
    });
  };

  return (
    <div className={styled["accordion-container"]}>
      <Accordion className={styled["accordion"]} activeIndex={0}>
        <AccordionTab header="Популярные вопросы">
          <div className={styled["accordion-list"]}>
            {localFaq.map((el) => (
              <FaqElement
                key={el.id}
                faqElement={el}
                index={el.index}
                deleteFaqElement={onDeleteFaqElement}
                editFaqElement={editFaqElement}
                tags={tags}
                addTag={addTag}
              />
            ))}
          </div>
          <Button onClick={addQuestion} className={styled["accordion-list-button"]}>
            Добавить вопрос
          </Button>
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default PopularFaq;
