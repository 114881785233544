import { useCallback, useEffect, useState } from "react";
import ProfileLayout from "./Profile.layout";

import { getBalancesHistoryStatistics, getBalancesHistoryStatisticsMy } from "api/balances-history";
import { addErrorMessage } from "slices/toastSlice";
import { useDispatch } from "react-redux";
import { phrases } from "constants/phrases";

type ProfileProps = {
  id?: string;
};

const Profile = ({ id }: ProfileProps) => {
  const [balanceStatistics, setBalanceStatistics] = useState<any>(null);
  const dispatch = useDispatch();

  const getBalanceStatistics = useCallback(async () => {
    try {
      let res;
      if (id) {
        res = await getBalancesHistoryStatistics({ adminId: id });
      } else {
        res = await getBalancesHistoryStatisticsMy();
      }
      setBalanceStatistics(res.data);
    } catch (error: any) {
      dispatch(addErrorMessage(error.response.data.message || phrases.smthWentWrongText));
    }
  }, [dispatch, id]);

  useEffect(() => {
    getBalanceStatistics();
  }, [getBalanceStatistics]);

  return <ProfileLayout id={id} balanceStatistics={balanceStatistics} />;
};

export default Profile;
