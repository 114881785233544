import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { getDateMetaObject } from "utils/dates";
import { Calendar, CalendarProps, CalendarDateTemplateParams } from "primereact/calendar";

import styles from "./MonthPicker.module.scss";
import { PropsWithChildren } from "react";

type MonthPickerProps = {
  date?: Date | null;
  setDate?: (date: Date | null) => void;
  spanClassName?: string;
} & CalendarProps;

const MonthPicker = ({
  date: externalDate = null,
  setDate: externalSetDate,
  disabled,

  ...rest
}: PropsWithChildren<MonthPickerProps>) => {
  const { i18n } = useTranslation();
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const [date, setDate] = useState<Date | null>(null);

  const inputValue = externalDate
    ? externalDate.toLocaleString("ru", { month: "numeric", year: "numeric" })
    : "";

  useEffect(() => {
    if ((!externalDate && date) || (externalDate && externalDate !== date)) setDate(externalDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalDate]);

  const openCalendar = () => !disabled && setPopupIsOpen(true);
  const closeCalendar = () => {
    setDate(externalDate);
    setPopupIsOpen(false);
  };

  const onSubmit = () => {
    externalSetDate && externalSetDate(date);
    setPopupIsOpen(false);
  };

  const onChange = (e) => setDate(e.value);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (popupIsOpen) {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  }, [popupIsOpen]);

  return (
    <>
      <span className={classNames("p-input-icon-left p-input-calendar")}>
        <i className={classNames("pi pi-calendar", styles["calendar-icon"])} />
        <InputText
          onClick={openCalendar}
          // @ts-ignore
          ref={inputRef}
          placeholder={inputValue || "Выберите месяц"}
          className={classNames({
            "p-input-calendar-open": popupIsOpen,
          })}
          disabled={disabled}
        />
      </span>

      <Dialog
        visible={popupIsOpen}
        modal
        onHide={closeCalendar}
        className={styles["dialog"]}
        showHeader={false}
      >
        <div className={styles["calendar-popup-wrapper"]}>
          <div className={styles["calendar-controls-wrapper"]}>
            <div className={styles["calendar-wrapper"]}>
              <Calendar
                inline
                value={date as Date}
                onChange={onChange}
                locale="ru"
                view="month"
                {...rest}
              />
            </div>
            <div className={styles["popup-buttons-wrapper"]}>
              <Button className={styles["cancel-button"]} onClick={closeCalendar}>
                Отмена
              </Button>
              <Button className={styles["add-button"]} onClick={onSubmit}>
                Выбрать
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default MonthPicker;
