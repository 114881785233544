import { FC } from "react";
import { TUser } from "typings/user";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import styles from "./UnblockUserPopup.module.scss";

type UnblockUserPopupProps = {
    isOpen: boolean;
    handleHide: () => void;
    selectedUser: TUser | null;
    editStatusUser: (newStatus: number) => void;
};

const UnblockUserPopup: FC<UnblockUserPopupProps> = (props) => {
    const closePopup = () => props.handleHide();

    const blockUser = () => {
        props.editStatusUser(1);
        closePopup();
    };

    return (
        <Dialog
            visible={props.isOpen}
            modal
            onHide={closePopup}
            className={styles["dialog"]}
            showHeader={false}
        >
            <div className={styles["content"]}>
                <i className={classNames("pi pi-exclamation-triangle", styles["warning-icon"])} />
                <p className={styles["title"]}>Вы уверены, что хотите разблокировать следующую запись?</p>
                <p className={styles["text"]}>
                    {props.selectedUser
                        ? props.selectedUser.firstName + " " + props.selectedUser.lastName
                        : "Пользователь"}
                </p>
                <div className={styles["buttons-container"]}>
                    <Button className={styles["cancel-button"]} onClick={closePopup}>
                        Отмена
                    </Button>
                    <Button className={styles["unblock-button"]} onClick={blockUser}>
                        Разблокировать
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default UnblockUserPopup;
