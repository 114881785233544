import { FC } from "react";
import { TDirection } from "typings/direction";

import HeaderLayout from "./HeaderLayout";
import DirectionsTable from "./table/DirectionsTable";

type DicrectionsLayoutProps = {
    isLoadingTable: boolean;
    directionsList: TDirection[];
    handleOpenAddDirectionPopup: () => void;
    handleOpenEditDirectionPopup: () => void;
    handleOpenRemoveDirectionPopup: () => void;
    changeSelectedDirection: (direction: TDirection) => void;
};

const DicrectionsLayout: FC<DicrectionsLayoutProps> = (props) => {
    return (
        <div>
            <HeaderLayout handleOpenAddDirectionPopup={props.handleOpenAddDirectionPopup} />

            <DirectionsTable
                directionsList={props.directionsList}
                isLoadingTable={props.isLoadingTable}
                changeSelectedDirection={props.changeSelectedDirection}
                handleOpenEditDirectionPopup={props.handleOpenEditDirectionPopup}
                handleOpenRemoveDirectionPopup={props.handleOpenRemoveDirectionPopup}
            />
        </div>
    );
};

export default DicrectionsLayout;
