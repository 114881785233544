import { FC } from "react";

import styled from "./Promo.module.scss";
import PromoLayout from "./Promo.layout";

type PromoProps = {};

const Promo: FC<PromoProps> = () => {
  return (
    <div className={styled["page-container"]}>
      <PromoLayout />
    </div>
  );
};

export default Promo;
