import { getReviews } from "api/info/getReviews";
import { phrases } from "constants/phrases";
import { addErrorMessage } from "slices/toastSlice";
import { store } from "store";

const { dispatch } = store;

const profileLoader = async () => {
  try {
    const res = await getReviews();
    return res.data;
  } catch (err: any) {
    dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
  }
};

export default profileLoader;
