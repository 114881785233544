import styles from "./Services.module.scss";
import Service from "./Service";
import { TService } from "typings/service";
import { Button } from "primereact/button";
import { phrases } from "constants/phrases";
import { useState, useCallback, useEffect } from "react";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { useAppDispatch } from "utils/hooks";
import { useProfileData } from "../useProfileData";
import { getServices } from "api/servives/getServices";
import { useParams } from "react-router-dom";
import { createService } from "api/servives/createService";
import { updateService } from "api/servives/updateService";
import { deleteService } from "api/servives/deleteService";
import { useGetRoles } from "utils/useGetRoles";

const Services = () => {
  const { id } = useParams();
  const { profile } = useProfileData();

  const { isAdmin } = useGetRoles();

  const [services, setServices] = useState<TService[]>([]);

  const dispatch = useAppDispatch();

  const getServicesData = useCallback(async () => {
    try {
      const response = await getServices(profile?.id);
      if (response.status === 200 || response.status === 201) {
        setServices(response.data);
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
  }, [dispatch, profile?.id]);

  useEffect(() => {
    getServicesData();
  }, [getServicesData]);

  const addService = async () => {
    try {
      await createService({ name: "", amount: 0 }, id);
      dispatch(addSuccessMessage(phrases.service_success_added));
      getServicesData();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
  };

  const editService = async (data: TService) => {
    try {
      await updateService(data.id, {
        name: data.name,
        amount: data.amount,
      });
      dispatch(addSuccessMessage(phrases.service_success_updated));
      getServicesData();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
  };

  const onDeleteService = async (serviceId: number) => {
    try {
      await deleteService(serviceId);
      dispatch(addSuccessMessage(phrases.service_success_deleted));
      getServicesData();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
  };

  return (
    <div className={styles["services-list"]}>
      {services.map((service) => (
        <Service
          service={service}
          key={service.id}
          editService={editService}
          deleteService={onDeleteService}
          enableEdit={!id || isAdmin}
        />
      ))}
      {services.length < 7 && (
        <div>
          <Button label="Добавить услугу" className="p-button-sm" onClick={addService} />
        </div>
      )}
    </div>
  );
};

export default Services;
