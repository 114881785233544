import { TUser } from "typings/user";
import { Checkbox } from "primereact/checkbox";

import formattingDate from "utils/formattingDate";
import isBefore from "date-fns/isBefore";
import formatDate from "utils/formatDate";

export const renderColumn = (value: string) => (rowData: TUser) => {
  return (
    <div className="col-wrapper" onClick={() => {}}>
      <p className="table-text">{rowData[value]}</p>
    </div>
  );
};

export const renderStatus = (rowData: TUser) => {
  return (
    <div className="col-wrapper" onClick={() => {}}>
      <p className="table-text">{rowData.status === 1 ? "Активен" : "Заблокирован"}</p>
    </div>
  );
};

export const renderSubscriptionStatus = (rowData: TUser) => {
  return (
    <div className="col-wrapper" onClick={() => {}}>
      <p className="table-text">
        {rowData.subscriptionInfo
          ? isBefore(new Date(), new Date(rowData.subscriptionInfo.untilAt))
            ? `Активна до ${formatDate(new Date(rowData.subscriptionInfo.untilAt))} ✅`
            : `Закончилась ${formatDate(new Date(rowData.subscriptionInfo.untilAt))}`
          : "Подписка неактивна"}
      </p>
    </div>
  );
};

export const renderTelegramStatus = (rowData: TUser) => {
  return (
    <div className="col-wrapper" onClick={() => {}}>
      <p className="table-text">
        {rowData.isSubscribeTelegram === 1 ? "Присоединился ✅" : "Не присоединился"}
      </p>
    </div>
  );
};

export const renderFIO = (rowData: TUser) => {
  return (
    <div className="col-wrapper" onClick={() => {}}>
      <p className="table-text">{rowData.firstName + " " + rowData.lastName}</p>
    </div>
  );
};

export const renderTest = (rowData: TUser) => {
  return (
    <div className="col-wrapper" onClick={() => {}}>
      <Checkbox onChange={() => {}} checked={rowData.isTest} disabled></Checkbox>
    </div>
  );
};

export const renderDate = (field: string) => (rowData: TUser) => {
  return (
    <div className="col-wrapper">
      <p className="table-text">{formattingDate(rowData[field], "dd.mm.yyyy")}</p>
    </div>
  );
};
