import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { FC, useState } from "react";
import { CORRECT_EMAIL } from "constants/email";

import styles from "./ForgetPasswordPopup.module.scss";
import { addErrorMessage } from "slices/toastSlice";
import { useAppDispatch } from "utils/hooks";

type ForgetPasswordPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  forgotPswd: (email: string) => void;
};

const ForgetPasswordPopup: FC<ForgetPasswordPopupProps> = ({ forgotPswd, handleHide, isOpen }) => {
  const [email, setEmail] = useState<string>("");

  const closePopup = () => {
    handleHide();
    setEmail("");
  };
  const dispatch = useAppDispatch();

  const forgotPasssword = () => {
    if (!CORRECT_EMAIL.test(email)) {
      return dispatch(addErrorMessage("Неверный формат E-mail"));
    } else {
      forgotPswd(email);
      handleHide();
      setEmail("");
    }
  };

  return (
    <Dialog
      visible={isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <i className={classNames("pi pi-exclamation-triangle", styles["warning-icon"])} />
        <p className={styles["title"]}>Вы уверены, что хотите сбросить пароль?</p>
        <p className={styles["description"]}>
          Мы можем помочь вам сбросить пароль от вашей учетной записи. В этом случае, ваш пароль
          будет сброшен, а вам, на указанную почту, придет ссылка для создания нового пароля.
        </p>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>E-mail учетной записи</p>
          <InputText
            maxLength={256}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Ваш e-mail"
            className={styles["email-input"]}
          />
        </div>
        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button
            className={styles["reset-button"]}
            onClick={() => {
              forgotPasssword();
            }}
          >
            Сбросить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ForgetPasswordPopup;
