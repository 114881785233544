import styled from "./OnboardingStatistics.module.scss";
import OnboardingStatisticsLayout from "./OnboardingStatistics.layout";

import { phrases } from "constants/phrases";
import { useAppDispatch } from "utils/hooks";
import { addErrorMessage } from "slices/toastSlice";
import { TOnbordingStatArray } from "typings/onboardingStat";
import { getOnboardingStatAsync } from "api/onboarding-stat/getOnboardingStat";
import { useCallback, useEffect, useState } from "react";

const OnboardingStatistics = () => {
    const [onboardingStat, setOnboardingStat] = useState<TOnbordingStatArray | null>(null);

    const dispatch = useAppDispatch();

    const getOnboardingStat = useCallback(async () => {
        try {
            const response = await getOnboardingStatAsync();
            if (response.status === 200 || response.status === 201) {
                setOnboardingStat(response.data);
            } else throw new Error();
        } catch (err: any) {
            dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
        }
    }, [dispatch]);

    useEffect(() => {
        getOnboardingStat();
    }, [getOnboardingStat]);

    return (
        <div className={styled["page-container"]}>
            <OnboardingStatisticsLayout onboardingStat={onboardingStat} />
        </div>
    );
};

export default OnboardingStatistics;
