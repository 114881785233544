import { PLATFORMS, SUBSCRIPTIONS_STATUSES } from "constants/subscriptions";
import { TSubsription } from "typings/subscription";

import formattingDate from "utils/formattingDate";

export const renderColumn = (value: string) => (rowData: TSubsription) => {
  return (
    <div className="col-wrapper" onClick={() => {}}>
      <p className="table-text">{rowData[value]}</p>
    </div>
  );
};

export const renderDate = (field: string) => (rowData: TSubsription) => {
  return (
    <div className="col-wrapper">
      <p className="table-text">{formattingDate(rowData[field], "dd.mm.yyyy")}</p>
    </div>
  );
};

export const renderUserID = (rowData: TSubsription) => {
  return (
    <div className="col-wrapper">
      <p className="table-text">{rowData.student.id}</p>
    </div>
  );
};

export const renderUserEmail = (rowData: TSubsription) => {
  return (
    <div className="col-wrapper">
      <p className="table-text">{rowData.student.email}</p>
    </div>
  );
};

export const renderUserData = (rowData: TSubsription) => {
  return (
    <div style={{ width: "100%" }}>
      <p className="table-text">{rowData.student.firstName + " " + rowData.student.lastName}</p>
      <p className="table-text">{rowData.student.email}</p>
      <p className="table-text">{rowData.student.phone}</p>
    </div>
  );
};

export const renderPlatform = (rowData: TSubsription) => {
  return (
    <div className="col-wrapper">
      <p className="table-text">{PLATFORMS[rowData.platform]}</p>
    </div>
  );
};

export const renderRebillIDStatus = (rowData: TSubsription) => {
  return (
    <div className="col-wrapper">
      <p className="table-text">
        {rowData.isHaveRebill ? <i className="pi pi-check" /> : <i className="pi pi-minus" />}
      </p>
    </div>
  );
};

export const renderStatus = (rowData: TSubsription) => {
  let status = "rejected";

  if (rowData.isAutoPayment === true && rowData.status === "NEW") status = "repeat_new";
  if (rowData.isAutoPayment === true && rowData.status === "CONFIRMED") status = "repeat_confirmed";
  if (rowData.isAutoPayment === true && rowData.status === "REJECTED") status = "repeat_rejected";
  if (rowData.isAutoPayment === true && rowData.status === "REFUNDED") status = "repeat_refunded";
  if (rowData.isAutoPayment === false && rowData.status === "NEW") status = "new";
  if (rowData.isAutoPayment === false && rowData.status === "CONFIRMED") status = "confirmed";
  if (rowData.isAutoPayment === false && rowData.status === "REJECTED") status = "rejected";
  if (rowData.isAutoPayment === true && rowData.status === "REFUNDED") status = "refunded";

  return (
    <div className="col-wrapper">
      <p className="table-text">{SUBSCRIPTIONS_STATUSES[status]}</p>
    </div>
  );
};
