import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { TSubsription } from "typings/subscription";
import { tableControlsType } from "typings/table-controls";
import { FC, useEffect, useState } from "react";
import {
  renderColumn,
  renderDate,
  renderPlatform,
  renderRebillIDStatus,
  renderStatus,
  renderUserData,
  renderUserEmail,
  renderUserID,
} from "./actions";

import styled from "./SubscriptionsTable.module.scss";
import useDebounce from "utils/use-debounce";

type SubscriptionsTableProps = {
  params: tableControlsType;
  subscriptionsList: TSubsription[];
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  totalRecords: number;
  isLoadingTable: boolean;
};

const SubscriptionsTable: FC<SubscriptionsTableProps> = (props) => {
  const [searchLocalValue, setSearchLocalValue] = useState<string>("");

  const debouncedSearchValue = useDebounce(searchLocalValue, 500);

  useEffect(() => {
    if (debouncedSearchValue !== props.params?.search) {
      props.setParams((prev) => ({ ...prev, search: debouncedSearchValue }));
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (searchLocalValue !== props.params?.search && typeof props.params?.search === "string") {
      setSearchLocalValue(props.params?.search);
    }
  }, [props.params?.search]);

  const onPage = (event) => {
    props.setParams(event);
  };

  return (
    <div className={styled["table-wrapper"]}>
      <div className={styled["table-header"]}>
        <p className={styled["table-name"]}>Подписки</p>
        <span
          className={classNames("p-input-icon-left p-main-input", styled["search-input-wrapper"])}
        >
          <i className="pi pi-search" />
          <InputText
            maxLength={700}
            value={searchLocalValue}
            type="text"
            placeholder="Поиск"
            onChange={(e) => setSearchLocalValue(e.target.value)}
            className={styled["search-input"]}
          />
        </span>
      </div>
      <DataTable
        value={props.subscriptionsList}
        responsiveLayout="scroll"
        dataKey="id"
        paginator
        lazy
        first={props.params.first}
        rows={15}
        totalRecords={props.totalRecords}
        onPage={onPage}
        loading={props.isLoadingTable}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        emptyMessage="Подписок не найдено"
      >
        <Column field="id" header="ID" style={{ width: "10%" }} body={renderColumn("id")}></Column>
        <Column
          field="orderId"
          header="ID платежа"
          style={{ width: "10%" }}
          body={renderColumn("orderId")}
        ></Column>
        <Column
          field="createdAt"
          header="Дата"
          style={{ width: "15%" }}
          body={renderDate("createdAt")}
        ></Column>
        <Column
          field="student"
          header="ID пользователя"
          style={{ width: "20%" }}
          body={renderUserID}
        ></Column>
        <Column
          field="email"
          header="Пользователь"
          style={{ width: "20%" }}
          body={renderUserData}
        ></Column>
        <Column
          field="amount"
          header="Сумма"
          style={{ width: "20%" }}
          body={renderColumn("amount")}
        ></Column>
        <Column
          field="platform"
          header="Платежная система"
          style={{ width: "10%" }}
          body={renderPlatform}
        ></Column>
        <Column
          field="isHaveRebill"
          header="Наличие RebillID"
          style={{ width: "10%" }}
          body={renderRebillIDStatus}
        ></Column>
        <Column
          field="status"
          header="Статус"
          style={{ width: "10%" }}
          body={renderStatus}
        ></Column>
      </DataTable>
    </div>
  );
};

export default SubscriptionsTable;
