import { FC, useMemo, useState } from "react";
import styles from "./Reviews.module.scss";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { TReview, TReviewWithId } from "typings/reviews";
import ReviewCard from "./ReviewCard";
import { Button } from "primereact/button";

const fields = ["name", "age", "date", "text"];

const filterReviews = (reviews: TReviewWithId[], filter: string) =>
  reviews.filter((review) =>
    fields.some((field) => review[field].toLowerCase().includes(filter.toLocaleLowerCase().trim()))
  );

type ReviewsLayoutProps = {
  reviews: TReviewWithId[];
  editReview: (reviewId: string, data: TReview) => void;
  addReview: () => void;
  deleteReview: (reviewId: string) => void;
};

const ReviewsLayout: FC<ReviewsLayoutProps> = ({
  reviews,
  editReview,
  addReview,
  deleteReview,
}) => {
  const [search, setSearch] = useState<string>("");

  const filteredReviews = useMemo(() => filterReviews(reviews, search), [reviews, search]);

  return (
    <div className={styles["reviews-layout-wrapper"]}>
      <span
        className={classNames("p-input-icon-left p-main-input", styles["search-input-wrapper"])}
      >
        <i className="pi pi-search" />
        <InputText
          maxLength={700}
          type="text"
          placeholder="Поиск"
          className={classNames(styles["search-input"], "w-full p-inputtext-light-bg")}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </span>
      <div className={styles["reviews-list"]}>
        {filteredReviews.map((review) => (
          <ReviewCard
            key={review.id}
            review={review}
            editReview={editReview}
            deleteReview={deleteReview}
          />
        ))}
      </div>
      <div className={styles["add-review-button"]}>
        <Button label="Добавить отзыв" className="p-button-m" onClick={addReview} />
      </div>
    </div>
  );
};

export default ReviewsLayout;
