import { FC, useState } from "react";
import styled from "./Profile.module.scss";
import ProfileInfo from "./ProfileInfo/ProfileInfo";
import { Outlet, useLoaderData, } from "react-router";
import { TBalanceStatistics } from "typings/balance";
import { TAdminProfile } from "typings/admin";
import { OutletContextType } from "./useProfileData";
import { assignToCourse } from "api/admins/assignToCourse";
import { useAppDispatch } from "utils/hooks";
import { phrases } from "constants/phrases";
import { addErrorMessage } from "slices/toastSlice";

type ProfileLayoutProps = {
  id?: string;
  balanceStatistics: TBalanceStatistics;
};


const ProfileLayout: FC<ProfileLayoutProps> = ({ id, balanceStatistics }) => {
  const profile = useLoaderData() as TAdminProfile;
  const [isOpenAddCoursePopup, setIsOpenAddCoursePopup] = useState<boolean>(false);
  const handleCloseAddCoursePopup = () => setIsOpenAddCoursePopup(false);
  const handleOpenAddCoursePopup = () => setIsOpenAddCoursePopup(true);

  const dispatch = useAppDispatch();
  const addCourse = async (courseId: number) => {
    try {
      await assignToCourse(profile?.id, { courseId, isDelete: false });
      handleCloseAddCoursePopup();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
  };

  return (
    <div className={styled["page-container"]}>
      <div className={styled["profile-card"]}>
        <ProfileInfo profile={profile} />
        <Outlet context={{ balanceStatistics, profile, handleOpenAddCoursePopup, handleCloseAddCoursePopup, isOpenAddCoursePopup, addCourse } satisfies OutletContextType} />
      </div>
    </div>
  );
};

export default ProfileLayout;
