import { FC } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { TApplication } from "typings/applications";
import { tableControlsType } from "typings/table-controls";
import {
  renderAuthorApplication,
  renderColumn,
  renderDate,
  renderServices,
  renderSpeakerInfo,
  renderStatus,
} from "./actions";

import styled from "./ApplicationsTable.module.scss";

type ApplicationsTableProps = {
  params: tableControlsType;
  applicationsList: TApplication[];
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  totalRecords: number;
  isLoadingTable: boolean;
  changeSelectedApplication: (application: TApplication) => void;
  handleOpenChangeStatusApplicationPopup: () => void;
};

const ApplicationsTable: FC<ApplicationsTableProps> = (props) => {
  const onPage = (event) => {
    props.setParams(event);
  };

  const renderAction = (rowData: TApplication) => {
    return (
      <div className={styled["col-action-wrapper"]} onClick={() => {}}>
        <button
          className={classNames(styled["action-btn"], styled["edit-btn"], styled["settings-btn"])}
          onClick={() => {
            props.changeSelectedApplication(rowData);
            props.handleOpenChangeStatusApplicationPopup();
          }}
        >
          <i className="pi pi-pencil" />
        </button>
      </div>
    );
  };

  return (
    <div className={styled["table-wrapper"]}>
      <div className={styled["table-header"]}>
        <p className={styled["table-name"]}>Услуги</p>
      </div>
      <DataTable
        value={props.applicationsList}
        responsiveLayout="scroll"
        dataKey="id"
        paginator
        lazy
        first={props.params.first}
        rows={15}
        totalRecords={props.totalRecords}
        onPage={onPage}
        loading={props.isLoadingTable}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        emptyMessage="Услуг не найдено"
      >
        <Column field="id" header="ID" style={{ width: "10%" }} body={renderColumn("id")}></Column>
        <Column
          field="createdAt"
          header="Создано"
          style={{ width: "15%" }}
          body={renderDate("createdAt")}
        ></Column>
        <Column
          field="createdAt"
          header="Кто подал заявку"
          style={{ width: "20%" }}
          body={renderAuthorApplication}
        ></Column>
        <Column
          field="email"
          header="Спикер"
          style={{ width: "20%" }}
          body={renderSpeakerInfo}
        ></Column>
        <Column
          field="services"
          header="Услуги"
          style={{ width: "20%" }}
          body={renderServices}
        ></Column>
        <Column
          field="status"
          header="Статус"
          style={{ width: "10%" }}
          body={renderStatus}
        ></Column>
        <Column field="actions" header="" style={{ width: "5%" }} body={renderAction}></Column>
      </DataTable>
    </div>
  );
};

export default ApplicationsTable;
