import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { FC, useEffect, useState } from "react";

import styles from "./EditUserPasswordPopup.module.scss";

type EditUserPasswordPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  updateUserPassword: (password: string) => void;
};

const EditUserPasswordPopup: FC<EditUserPasswordPopupProps> = (props) => {
  const [password, setPassword] = useState<string>("");
  const [rePassword, setRePassword] = useState<string>("");

  const closePopup = () => {
    props.handleHide();
  };

  useEffect(() => {
    if (props.isOpen) {
      setPassword("");
      setRePassword("");
    }
  }, [props.isOpen]);

  const editUser = (event) => {
    event.preventDefault();
    if (!password || !rePassword || password !== rePassword) return;
    props.updateUserPassword(password);
    closePopup();
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <form className={styles["content"]} onSubmit={editUser}>
        <p className={styles["title"]}>Редактирование пароля</p>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Пароль</p>
          <Password
            feedback={false}
            maxLength={256}
            placeholder="Пароль"
            toggleMask
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
            autoComplete="new-password"
          />
        </div>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Повторите пароль</p>
          <Password
            feedback={false}
            maxLength={256}
            placeholder="Пароль"
            toggleMask
            value={rePassword}
            onChange={(e) => setRePassword(e.target.value)}
            className={styles.input}
            autoComplete="new-password"
          />
        </div>

        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} type="button" onClick={closePopup}>
            Отмена
          </Button>
          <Button
            type="submit"
            className={styles["edit-button"]}
            disabled={!password || !rePassword || password !== rePassword}
          >
            Сохранить
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default EditUserPasswordPopup;
