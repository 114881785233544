import styled from "./PaymentsStatistics.module.scss";
import MonthPicker from "popups/month-picker/MonthPicker";
import { endOfMonth } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import startOfMonth from "date-fns/esm/fp/startOfMonth/index.js";
import { Button } from "primereact/button";
import { tableControlsType } from "typings/table-controls";

type HeaderLayoutProps = {
  monthEnd: Date | null;
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  setMonthEnd: React.Dispatch<React.SetStateAction<Date | null>>;
  downloadSubscriptions: () => void;
};

const HeaderLayout = ({
  monthEnd,
  setMonthEnd,
  setParams,
  downloadSubscriptions,
}: HeaderLayoutProps) => {
  return (
    <div className={styled["header-layout"]}>
      <div className={styled["filters"]}>
        <MonthPicker
          view="month"
          date={monthEnd && startOfMonth(utcToZonedTime(monthEnd, "Europe/Moscow"))}
          setDate={(date) => {
            if (!date) return;
            setMonthEnd(zonedTimeToUtc(endOfMonth(date), "Europe/Moscow"));
            setParams((prev) => ({ ...prev, page: 0 }));
          }}
        />
        <Button label="Сбросить" className="p-button-sm" onClick={() => setMonthEnd(null)} />
      </div>
      <Button label="Экспорт в csv" onClick={downloadSubscriptions} className="p-button-sm" />
    </div>
  );
};

export default HeaderLayout;
