import { TCourse } from "typings/course";
import { COURSE_STATUSES_LABELS } from "constants/course";

import Tag from "components/common/tags/Tag";
import formattingDate from "utils/formattingDate";

export const renderColumn = (value: string) => (rowData: TCourse) => {
  return (
    <div className="col-wrapper" onClick={() => {}}>
      <p className="table-text">{rowData[value]}</p>
    </div>
  );
};

export const renderStatus = (rowData: TCourse) => {
  return (
    <div className="col-wrapper">
      <p className="table-text">{COURSE_STATUSES_LABELS[rowData.status]}</p>
    </div>
  );
};

export const renderDirection = (rowData: TCourse, { field }) => {
  const tag = rowData[field];
  return tag ? <Tag name={tag.header.toUpperCase()} color={tag.color} /> : null;
};

export const renderAuthor = (rowData: TCourse) => {
  return (
    <div className="col-wrapper">
      <p className="table-text">
        {rowData.speaker?.firstName || "-"} {rowData.speaker?.lastName || ""}
      </p>
    </div>
  );
};

export const renderCurator = (rowData: TCourse) => {
  return (
    <div className="col-wrapper">
      <p className="table-text">
        {rowData.curators?.[0]?.firstName || ""} {rowData.curators?.[0]?.lastName || "-"}
      </p>
    </div>
  );
};

export const renderDate = (field: string) => (rowData: TCourse) => {
  return (
    <div className="col-wrapper">
      <p className="table-text">{formattingDate(rowData[field], "dd.mm.yyyy")}</p>
    </div>
  );
};
