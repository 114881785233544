import http from "./https";

type TCreateNewsData = {
  header: string;
  text: string;
  imageUrl?: string;
};

export const getNews = (params) => http.get("/api/news", {}, { params });

export const createNews = (data: TCreateNewsData, adminId?: string) =>
  http.post("/api/news", data, {}, { params: { adminId } });

export const deleteNews = (id: number) => http.remove(`/api/news/${id}`, {});

export const updateNews = (id: number, data) => http.patch(`/api/news/${id}`, data);
