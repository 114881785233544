import { TPaymentsStatistics } from "typings/subscription";
import { tableControlsType } from "typings/table-controls";

import styles from "./PaymentsStatistics.module.scss";
import HeaderLayout from "./HeaderLayout";
import PaymentsStatisticsTable from "./table/PaymentsStatisticsTable";

type SubscriptionsLayoutProps = {
  subscriptionsList: TPaymentsStatistics[];
  monthEnd: Date | null;
  isLoadingTable: boolean;
  setMonthEnd: React.Dispatch<React.SetStateAction<Date | null>>;
  totalRecords: number;
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  params: tableControlsType;
  totals?: TPaymentsStatistics;
  downloadSubscriptions: () => void;
};

const SubscriptionsLayout = (props: SubscriptionsLayoutProps) => {
  return (
    <div className={styles["content-card"]}>
      <HeaderLayout
        setParams={props.setParams}
        monthEnd={props.monthEnd}
        setMonthEnd={props.setMonthEnd}
        downloadSubscriptions={props.downloadSubscriptions}
      />
      <PaymentsStatisticsTable
        totals={props.totals}
        subscriptionsList={props.subscriptionsList}
        isLoadingTable={props.isLoadingTable}
        totalRecords={props.totalRecords}
        setParams={props.setParams}
        params={props.params}
      />
    </div>
  );
};

export default SubscriptionsLayout;
