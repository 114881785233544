import { Button } from "primereact/button";
import { phrases } from "constants/phrases";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { classNames } from "primereact/utils";
import { uploadFile } from "api/storage/uploadFile";
import { useGetRoles } from "utils/useGetRoles";
import { InputTextarea } from "primereact/inputtextarea";
import { useAppDispatch } from "utils/hooks";
import { addErrorMessage } from "slices/toastSlice";
import { FC, useEffect, useRef, useState } from "react";
import { TFile, TLessonInfo, TLink, TUpdateLessonData } from "typings/course";

import Files from "./Files";
import Links from "./Links";
import styled from "./Lesson.module.scss";
import EmptyImage from "components/common/empty-image/EmptyImage";
import { DEFAULT_FILE, DEFAULT_LINK } from "constants/course";

function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}

type LessonLayoutProps = {
  handleOpenRemoveLessonPopup: () => void;
  lessonInfo: TLessonInfo;
  updateLesson: (lessonData: TUpdateLessonData) => void;
};

const LessonLayout: FC<LessonLayoutProps> = (props) => {
  const [isOpenEditLessonTitle, setIsOpenEditLessonTitle] = useState<boolean>(false);
  const [lessonTitle, setLessonTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [videoLink, setVideoLink] = useState<string>(props.lessonInfo?.videoUrl || "");

  const { isCurator } = useGetRoles();

  const fileUploadReference = useRef(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.lessonInfo) {
      setLessonTitle(props.lessonInfo.name);
      setDescription(props.lessonInfo.description);
    }
  }, [props.lessonInfo]);

  const renameLesson = () => {
    if (isOpenEditLessonTitle && props.lessonInfo.name !== lessonTitle) {
      props.updateLesson({ name: lessonTitle });
    }
    setIsOpenEditLessonTitle(!isOpenEditLessonTitle);
  };

  const changeDesription = () => {
    if (props.lessonInfo.description === description) return;
    props.updateLesson({ description });
  };

  const deleteLessonImage = () => {
    props.updateLesson({ imageUrl: null });
  };

  const changeLessonImage = async (event) => {
    try {
      const file = event.files[0];

      if (file) {
        const fileFormData = new FormData();
        fileFormData.append("file", file);
        const {
          data: { url },
        } = await uploadFile(fileFormData);

        props.updateLesson({ imageUrl: url });
      }
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    } finally {
      //@ts-ignore
      fileUploadReference?.current?.clear();
    }
  };

  const [list, setList] = useState<TFile[]>([DEFAULT_FILE]);

  const [listLinks, setListLinks] = useState<TLink[]>([DEFAULT_LINK]);

  const disabledButton =
    (JSON.stringify(props.lessonInfo.files) === JSON.stringify(list) ||
      (props.lessonInfo.files.length === 0 && list.length === 1 && list[0] === DEFAULT_FILE)) &&
    (JSON.stringify(props.lessonInfo.links) === JSON.stringify(listLinks) ||
      (props.lessonInfo.links.length === 0 &&
        listLinks.length === 1 &&
        listLinks[0] === DEFAULT_LINK) ||
      (listLinks.some((el) => !el.link || !el.name) && listLinks.length > 1));

  const onSave = () => {
    props.updateLesson({
      files: list.length === 1 && list[0] === DEFAULT_FILE ? [] : list,
      links: listLinks.length === 1 && listLinks[0] === DEFAULT_LINK ? [] : listLinks,
    });
  };

  const onVideLinkSave = () => {
    if (videoLink !== props.lessonInfo?.videoUrl) {
      if (videoLink === "" || isValidUrl(videoLink)) {
        props.updateLesson({
          videoUrl: videoLink,
        });
      } else {
        dispatch(addErrorMessage(phrases.invalid_video_url));
      }
    }
  };

  return (
    <>
      <div className={styled["content"]}>
        <div className={styled["image-wrapper"]}>
          <div className={styled["image-container"]}>
            {props.lessonInfo.imageUrl ? (
              <img alt="lesson" src={props.lessonInfo.imageUrl} />
            ) : (
              <EmptyImage />
            )}
          </div>
          {!isCurator && (
            <div className={styled["image-buttons-settings-wrapper"]}>
              <button className={styled["image-settings-button"]} onClick={deleteLessonImage}>
                Удалить
              </button>
              <p>/</p>
              <FileUpload
                ref={fileUploadReference}
                mode="basic"
                accept="image/*"
                maxFileSize={1000000}
                customUpload
                uploadHandler={changeLessonImage}
                chooseLabel="Изменить"
                auto
                className={styled["image-uploader"]}
              />
            </div>
          )}
          <div className={styled["video-wrapper"]}>
            <div className={styled["video-link-container"]}>
              <p className={styled["video-input-label"]}>Ссылка на видео</p>
              <InputText
                maxLength={700}
                value={videoLink}
                onBlur={onVideLinkSave}
                onPaste={onVideLinkSave}
                onChange={(e) => setVideoLink(e.target.value)}
                placeholder="добавить ссылку"
                className={styled["video-input"]}
                disabled={isCurator}
              />
            </div>
            <div className={styled["video-container"]}>
              <iframe
                title="video"
                src={isValidUrl(videoLink) ? videoLink : ""}
                allow="fullscreen; picture-in-picture"
                allowFullScreen
                width="347"
                height="210"
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        </div>
        <div className={styled["card-content"]}>
          <div className={styled["card-content-header"]}>
            {isOpenEditLessonTitle ? (
              <InputText
                maxLength={700}
                value={lessonTitle}
                onChange={(e) => setLessonTitle(e.target.value)}
                placeholder="Название урока"
                className={styled["input-course-title"]}
                disabled={!isOpenEditLessonTitle}
              />
            ) : (
              <p className={styled["input-title"]}>{props.lessonInfo.name}</p>
            )}

            {!isCurator && (
              <button className={styled["edit-course-title-button"]} onClick={renameLesson}>
                {!isOpenEditLessonTitle ? (
                  <i className="pi pi-pencil" />
                ) : (
                  <i className="pi pi-check" />
                )}
              </button>
            )}
          </div>
          <div className={styled["work-card"]}>
            <div className={styled["row"]}>
              <p className={styled["textrea-label"]}>Описание</p>
              <InputTextarea
                maxLength={1300}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                cols={30}
                autoResize
                placeholder="Заполнитель"
                className={styled["text-area"]}
                onBlur={changeDesription}
                disabled={isCurator}
              />
            </div>

            <Files
              lessonInfo={props.lessonInfo}
              updateLesson={props.updateLesson}
              list={list}
              setList={setList}
            />

            <Links lessonInfo={props.lessonInfo} list={listLinks} setList={setListLinks} />

            {!isCurator && (
              <div className={styled["buttons-wrapper"]}>
                <Button
                  className={classNames(styled["button"], styled["remove-button"])}
                  onClick={props.handleOpenRemoveLessonPopup}
                >
                  Удалить урок
                </Button>
                <Button
                  className={classNames(styled["button"], styled["save-button"])}
                  onClick={onSave}
                  disabled={disabledButton}
                >
                  Сохранить изменения
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LessonLayout;
