import { ERRORS } from "constants/errors";
import { phrases } from "constants/phrases";
import { STATUSES } from "constants/filters";
import { getUsersAsync } from "api/users/getUsers";
import { DEFAULT_PARAMS } from "constants/table-params";
import { blockUserAsync } from "api/users/blockUser";
import { useAppDispatch } from "utils/hooks";
import { removeUserAsync } from "api/users/removeUser";
import { updateUserAsync } from "api/users/editUser";
import { tableControlsType } from "typings/table-controls";
import { createNewUserAsync } from "api/users/addUser";
import { editUserRefLinkAsync } from "api/users/editRefLink";
import { editUserPasswordAsync } from "api/users/editUserPassword";
import { saveCustomSubscriptionAsync } from "api/users/saveCustomSubscription";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { TCustomSubscriptionRequest, TNewUser, TUser } from "typings/user";

import styled from "./Users.module.scss";
import UsersLayout from "./Users.layout";
import AddUserPopup from "popups/add-user/AddUserPopup";
import EditUserPopup from "popups/edit-user/EditUserPopup";
import BlockUserPopup from "popups/block-user/BlockUserPopup";
import RemoveUserPopup from "popups/remove-user/RemoveUserPopup";
import UnblockUserPopup from "popups/unblock-user/UnblockUserPopup";
import UserOnboardingPopup from "popups/user-onboarding/UserOnboardingPopup";
import EditUserRefLinkPopup from "popups/edit-user-ref-link/EditUserRefLinkPopup";
import EditUserPasswordPopup from "popups/edit-user-password/EditUserPasswordPopup";
import CustomSubscriptionPopup from "popups/custom-subscription/CustomSubscriptionPopup";
import { RefferalStatisticsPopup } from "popups/refferal-statistics/RefferalStatisticsPopup";
import { getUsersCSV } from "api/users/getUsersCSV";

type UsersProps = {};

const Users: FC<UsersProps> = () => {
  const [usersList, setUsersList] = useState<TUser[]>([]);
  const [params, setParams] = useState<tableControlsType>(DEFAULT_PARAMS);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<TUser | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isOpenAddUserPopup, setIsOpenAddUserPopup] = useState<boolean>(false);
  const [isOpenRemoveUserPopup, setIsOpenRemoveUserPopup] = useState<boolean>(false);
  const [isOpenBlockUserPopup, setIsOpenBlockUserPopup] = useState<boolean>(false);
  const [isOpenUnblockUserPopup, setIsOpenUnblockUserPopup] = useState<boolean>(false);
  const [isOpenEditUserPopup, setIsOpenEditUserPopup] = useState<boolean>(false);
  const [isOpenEditUserPasswordPopup, setIsOpenEditUserPasswordPopup] = useState<boolean>(false);
  const [isOpenEditUserRefLinkPopup, setIsOpenEditUserRefLinkPopup] = useState<boolean>(false);
  const [isOpenCustomSubscriptionPopup, setIsOpenCustomSubscriptionPopup] =
    useState<boolean>(false);
  const [isOpenUserOnboardingPopup, setIsOpenUserOnboardingPopup] = useState<boolean>(false);
  const [isOpenRefferalStatisticsPopup, setIsOpenRefferalStatisticsPopup] =
    useState<boolean>(false);

  const [status, setStatus] = useState<string>(STATUSES[0].value);

  const dispatch = useAppDispatch();

  const changeSelectedUser = (data: TUser) => setSelectedUser(data);

  const changeStatus = (status: string) => setStatus(status);

  const handleOpenAddUserPopup = () => setIsOpenAddUserPopup(true);
  const handleCloseAddUserPopup = () => setIsOpenAddUserPopup(false);

  const handleOpenRemoveUserPopup = () => setIsOpenRemoveUserPopup(true);
  const handleCloseRemoveUserPopup = () => setIsOpenRemoveUserPopup(false);

  const handleOpenBlockUserPopup = () => setIsOpenBlockUserPopup(true);
  const handleCloseBlockUserPopup = () => setIsOpenBlockUserPopup(false);

  const handleOpenUnblockUserPopup = () => setIsOpenUnblockUserPopup(true);
  const handleCloseUnblockUserPopup = () => setIsOpenUnblockUserPopup(false);

  const handleOpenEditUserPopup = () => setIsOpenEditUserPopup(true);
  const handleCloseEditUserPopup = () => setIsOpenEditUserPopup(false);

  const handleOpenEditUserPasswordPopup = () => setIsOpenEditUserPasswordPopup(true);
  const handleCloseEditUserPasswordPopup = () => setIsOpenEditUserPasswordPopup(false);

  const handleOpenCustomSubscriptionPopup = () => setIsOpenCustomSubscriptionPopup(true);
  const handleCloseCustomSubscriptionPopup = () => setIsOpenCustomSubscriptionPopup(false);

  const handleOpenEditUserRefLinkPopup = () => setIsOpenEditUserRefLinkPopup(true);
  const handleCloseEditUserRefLinkPopup = () => setIsOpenEditUserRefLinkPopup(false);

  const handleOpenUserOnboardingPopup = () => setIsOpenUserOnboardingPopup(true);
  const handleCloseUserOnboardingPopup = () => setIsOpenUserOnboardingPopup(false);

  const handleOpenRefferalStatisticsPopup = () => setIsOpenRefferalStatisticsPopup(true);
  const handleCloseRefferalStatisticsPopup = () => setIsOpenRefferalStatisticsPopup(false);

  const getUsersList = useCallback(
    async (params) => {
      setIsLoadingTable(true);
      try {
        const response = await getUsersAsync(params);
        if (response.status === 200 || response.status === 201) {
          setUsersList(response.data.data);
          setTotalRecords(response.data.pagination.totalItems);
          setIsLoadingTable(false);
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getUsersList({
      page: params.page,
      limit: params.rows,
      search: params.search || undefined,
      status: status !== "all" ? status : undefined,
    });
  }, [params, getUsersList, status]);

  const createUser = useCallback(
    async (userData: TNewUser) => {
      setIsLoadingTable(true);
      try {
        const response = await createNewUserAsync(userData);
        if (response.status === 200 || response.status === 201) {
          getUsersList({
            page: params.page,
            limit: params.rows,
            search: params.search || undefined,
            status: status !== "all" ? status : undefined,
          });
          dispatch(addSuccessMessage(phrases.user_success_added));
        } else throw new Error();
      } catch (err: any) {
        if (err.response.data.message.includes(ERRORS.invalid_email.backend_value))
          return dispatch(addErrorMessage(ERRORS.invalid_email.alert));
        else if (err.response.data.message.includes(ERRORS.uniq_email_required.backend_value))
          return dispatch(addErrorMessage(ERRORS.uniq_email_required.alert));
        else return dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrong));
      } finally {
        handleCloseAddUserPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch]
  );

  const deleteUser = useCallback(async () => {
    setIsLoadingTable(true);
    try {
      if (selectedUser) {
        const response = await removeUserAsync(selectedUser.id);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.user_sucess_deleted));
          getUsersList({
            page: params.page,
            limit: params.rows,
            search: params.search || undefined,
            status: status !== "all" ? status : undefined,
          });
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    } finally {
      handleCloseRemoveUserPopup();
      setIsLoadingTable(false);
    }
  }, [dispatch, selectedUser]);

  const updateUser = useCallback(
    async (userData: TNewUser) => {
      setIsLoadingTable(true);
      try {
        if (selectedUser) {
          const response = await updateUserAsync(selectedUser?.id, userData);
          if (response.status === 200 || response.status === 201) {
            getUsersList({
              page: params.page,
              limit: params.rows,
              search: params.search || undefined,
              status: status !== "all" ? status : undefined,
            });
            dispatch(addSuccessMessage(phrases.user_success_updated));
          } else throw new Error();
        } else return;
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseEditUserPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch, selectedUser]
  );

  const updateUserPassword = useCallback(
    async (password: string) => {
      setIsLoadingTable(true);
      try {
        if (selectedUser) {
          const response = await editUserPasswordAsync(selectedUser?.id, password);
          if (response.status === 200 || response.status === 201) {
            getUsersList({
              page: params.page,
              limit: params.rows,
              search: params.search || undefined,
              status: status !== "all" ? status : undefined,
            });
            dispatch(addSuccessMessage(phrases.user_password_success_updated));
          } else throw new Error();
        } else return;
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseEditUserPasswordPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch, selectedUser]
  );

  const editStatusUser = useCallback(
    async (newStatus: number) => {
      setIsLoadingTable(true);
      try {
        if (selectedUser) {
          const response = await blockUserAsync(selectedUser?.id, newStatus);
          if (response.status === 200 || response.status === 201) {
            getUsersList({
              page: params.page,
              limit: params.rows,
              search: params.search || undefined,
              status: status !== "all" ? status : undefined,
            });
            dispatch(
              addSuccessMessage(
                newStatus === 1 ? phrases.user_success_unblocked : phrases.user_success_blocked
              )
            );
          } else throw new Error();
        } else return;
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseBlockUserPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch, selectedUser]
  );

  const saveCustomSubscription = useCallback(
    async (userId: number, data: TCustomSubscriptionRequest) => {
      try {
        await saveCustomSubscriptionAsync(userId, data);
        getUsersList({
          page: params.page,
          limit: params.rows,
          search: params.search || undefined,
          status: status !== "all" ? status : undefined,
        });
        dispatch(addSuccessMessage(phrases.user_success_updated));
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      }
    },
    [dispatch]
  );

  const formatParams = useMemo(
    () => ({
      search: params.search || undefined,
      status: status !== "all" ? status : undefined,
    }),
    [params.search, status]
  );

  const editUserRefLink = useCallback(
    async (userId: number, data: { isActiveRef?: boolean; isSecondLevelRef?: boolean }) => {
      try {
        await editUserRefLinkAsync(userId, data);
        getUsersList({
          page: params.page,
          limit: params.rows,
          ...formatParams,
        });
        if (typeof data.isActiveRef === "boolean") {
          dispatch(
            addSuccessMessage(
              data.isActiveRef ? phrases.user_link_turn_on : phrases.user_link_turn_off
            )
          );
        } else if (typeof data.isSecondLevelRef === "boolean") {
          dispatch(
            addSuccessMessage(
              data.isSecondLevelRef
                ? phrases.user_second_level_link_turn_on
                : phrases.user_second_level_link_turn_off
            )
          );
        }
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      }
    },
    [dispatch, formatParams, getUsersList, params.page, params.rows]
  );

  const dowloadUsers = () => {
    getUsersCSV(formatParams);
  };

  return (
    <div className={styled["page-container"]}>
      <UsersLayout
        params={params}
        setParams={setParams}
        usersList={usersList}
        totalRecords={totalRecords}
        isLoadingTable={isLoadingTable}
        handleOpenAddUserPopup={handleOpenAddUserPopup}
        handleOpenRemoveUserPopup={handleOpenRemoveUserPopup}
        changeSelectedUser={changeSelectedUser}
        handleOpenEditUserPopup={handleOpenEditUserPopup}
        handleOpenBlockUserPopup={handleOpenBlockUserPopup}
        status={status}
        changeStatus={changeStatus}
        handleOpenUnblockUserPopup={handleOpenUnblockUserPopup}
        handleOpenCustomSubscriptionPopup={handleOpenCustomSubscriptionPopup}
        handleOpenEditUserRefLinkPopup={handleOpenEditUserRefLinkPopup}
        handleOpenEditUserPasswordPopup={handleOpenEditUserPasswordPopup}
        handleOpenUserOnboardingPopup={handleOpenUserOnboardingPopup}
        handleOpenRefferalStatisticsPopup={handleOpenRefferalStatisticsPopup}
        downloadUsers={dowloadUsers}
      />

      <AddUserPopup
        isOpen={isOpenAddUserPopup}
        handleHide={handleCloseAddUserPopup}
        createUser={createUser}
      />

      <RemoveUserPopup
        isOpen={isOpenRemoveUserPopup}
        handleHide={handleCloseRemoveUserPopup}
        selectedUser={selectedUser}
        deleteUser={deleteUser}
      />
      <BlockUserPopup
        isOpen={isOpenBlockUserPopup}
        handleHide={handleCloseBlockUserPopup}
        selectedUser={selectedUser}
        editStatusUser={editStatusUser}
      />
      <UnblockUserPopup
        isOpen={isOpenUnblockUserPopup}
        handleHide={handleCloseUnblockUserPopup}
        selectedUser={selectedUser}
        editStatusUser={editStatusUser}
      />
      <EditUserPopup
        isOpen={isOpenEditUserPopup}
        handleHide={handleCloseEditUserPopup}
        selectedUser={selectedUser}
        updateUser={updateUser}
      />

      <EditUserRefLinkPopup
        isOpen={isOpenEditUserRefLinkPopup}
        handleHide={handleCloseEditUserRefLinkPopup}
        selectedUser={selectedUser}
        editUserRefLink={editUserRefLink}
      />

      <EditUserPasswordPopup
        isOpen={isOpenEditUserPasswordPopup}
        handleHide={handleCloseEditUserPasswordPopup}
        updateUserPassword={updateUserPassword}
      />

      {isOpenCustomSubscriptionPopup && (
        <CustomSubscriptionPopup
          isOpen={isOpenCustomSubscriptionPopup}
          handleHide={handleCloseCustomSubscriptionPopup}
          selectedUser={selectedUser}
          saveCustomSubscription={saveCustomSubscription}
        />
      )}

      <UserOnboardingPopup
        isOpen={isOpenUserOnboardingPopup}
        handleHide={handleCloseUserOnboardingPopup}
        selectedUser={selectedUser}
      />
      <RefferalStatisticsPopup
        isOpen={isOpenRefferalStatisticsPopup}
        handleHide={handleCloseRefferalStatisticsPopup}
        selectedUser={selectedUser}
      />
    </div>
  );
};

export default Users;
