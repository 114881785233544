import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";

import styles from "./Changes.module.scss";
import { useProfileData } from "../useProfileData";
import { formatMoney } from "utils/numberFormat";

const dates = [
  { label: "Сегодня", key: "sumBalanceLastDay" },
  { label: "Месяц", key: "sumBalanceMonth" },
  { label: "Всего", key: "sumBalanceAllDays" },
];

const Changes = () => {
  const { balanceStatistics } = useProfileData();

  return (
    <Card>
      <div className={styles["changes"]}>
        {dates.map(({ label, key }) => (
          <div key={key} className="field">
            <label>{label}</label>
            <div className={styles["input-wrapper"]}>
              <InputText
                className="p-inputtext-light-bg p-input-display-value w-full"
                value={`+${formatMoney(balanceStatistics?.[key] ?? 0)}`}
                disabled
              />
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default Changes;
