import { Button } from "primereact/button";
import styled from "./Lesson.module.scss";
import { Link } from "react-router-dom";

const LessonErrorPage = () => {
  return (
    <div className={styled["page-container"]}>
      <div className={styled["empty-lesson"]}>
        <p>Урок не найден</p>
        <Link to={`..`}>
          <Button className={styled["back-course-btn"]}>Вернуться к курсу</Button>
        </Link>
      </div>
    </div>
  );
};

export default LessonErrorPage;
