import { useEffect, useRef } from "react";

import AppMenu from "./AppMenu";

import { useLoaderData } from "react-router";
import { TAdminProfile } from "typings/admin";
import user_placeholder from "assets/user_placeholder.svg";

const AppTopbar = (props) => {
  const { avatar } = useLoaderData() as TAdminProfile;

  const searchPanelDesktop = useRef(null);
  const searchPanelMobile = useRef(null);

  useEffect(() => {
    if (props.searchActive) {
      if (window.innerWidth >= 576)
        //@ts-ignore
        searchPanelDesktop.current.focus();
      //@ts-ignore
      else searchPanelMobile.current.focus();
    }
  }, [props.searchActive]);

  const menuWithImage = props.menu.map((el) => ({
    ...el,
    profileImageUrl: el.profileImage ? avatar ?? user_placeholder : undefined,
  }));

  return (
    <AppMenu
      menuMode={props.menuMode}
      sidebarStatic={props.sidebarStatic}
      model={menuWithImage}
      menuActive={props.menuActive}
      onRootMenuItemClick={props.onRootMenuItemClick}
      onMobileMenuActive={props.onMobileMenuActive}
      onMenuItemClick={props.onMenuItemClick}
      onSidebarMouseOver={props.onSidebarMouseOver}
      onSidebarMouseLeave={props.onSidebarMouseLeave}
      onToggleMenu={props.onToggleMenu}
      resetActiveIndex={props.resetActiveIndex}
      onMenuClick={props.onMenuClick}
    />
  );
};

export default AppTopbar;
