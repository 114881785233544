import { FC, useEffect, useState } from "react";
import { TUser } from "typings/user";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import styles from "./EditUserRefLinkPopup.module.scss";
import { Checkbox } from "primereact/checkbox";

type EditUserRefLinkPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedUser: TUser | null;
  editUserRefLink: (
    userId: number,
    data: { isActiveRef?: boolean; isSecondLevelRef?: boolean }
  ) => void;
};

const EditUserRefLinkPopup: FC<EditUserRefLinkPopupProps> = (props) => {
  const [isSecondLevelRef, setIsSecondLevelRef] = useState(props.selectedUser?.isSecondLevelRef);
  const [isActiveRef, setIsActiveRef] = useState(props.selectedUser?.isActiveRef);

  const closePopup = () => {
    props.handleHide();
  };

  useEffect(() => {
    if (props.selectedUser) {
      setIsSecondLevelRef(props.selectedUser.isSecondLevelRef);
    }
  }, [props.selectedUser]);

  const editUser = () => {
    if (props.selectedUser) {
      props.editUserRefLink(props.selectedUser?.id, {
        isActiveRef: !props.selectedUser?.isActiveRef,
      });
      setIsActiveRef(!isActiveRef);
    }
  };

  const onSecondLevelRefChange = () => {
    setIsSecondLevelRef(!isSecondLevelRef);
    if (props.selectedUser) {
      props.editUserRefLink(props.selectedUser?.id, {
        isSecondLevelRef: !isSecondLevelRef,
      });
    }
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <p className={styles["title"]}>Реферальная ссылка</p>
        <div className={styles["input-wrapper"]}>
          <InputText
            disabled
            value={props.selectedUser && isActiveRef ? props.selectedUser.refHash : "..."}
            className="p-input-display-value p-inputtext-light-bg w-full"
          />
        </div>
        {isActiveRef && (
          <div className={styles["input-wrapper"]}>
            <Checkbox
              onChange={onSecondLevelRefChange}
              checked={isSecondLevelRef}
              inputId="isSecondLevelRef"
            />
            <label htmlFor="isSecondLevelRef">Включить второй уровень</label>
          </div>
        )}
        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Закрыть
          </Button>
          <Button className={styles["edit-button"]} onClick={editUser}>
            {isActiveRef ? "Выключить ссылку" : "Включить ссылку"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditUserRefLinkPopup;
