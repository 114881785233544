import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { TCourseInfo } from "typings/course";
import { FC, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";

import styles from "./ReturnForRevisionCoursePopup.module.scss";

type ReturnForRevisionCoursePopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  returnForRevisionCourse: (comment: string) => void;
  courseInfo: TCourseInfo;
};

const ReturnForRevisionCoursePopup: FC<ReturnForRevisionCoursePopupProps> = (props) => {
  const [reason, setReason] = useState<string>("");

  const closePopup = () => {
    props.handleHide();
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <i className={classNames("pi pi-exclamation-circle", styles["icon"])} />

        <p className={styles["title"]}>Возврат на доработку?</p>

        <div className={styles["row"]}>
          <p className={styles["dropdown-label"]}>Опишите причину:</p>
          <InputTextarea
            maxLength={1300}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            rows={3}
            cols={30}
            autoResize
            placeholder="Заполнитель"
            className={styles["text-area"]}
          />
        </div>

        <p className={styles["warning"]}>
          Внимание! Материал вернется на доработку и будет скрыт от пользователей
        </p>

        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button
            className={styles["return-button"]}
            onClick={() => props.returnForRevisionCourse(reason)}
          >
            Вернуть
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ReturnForRevisionCoursePopup;
