import { FC } from "react";
import { TAdmin } from "typings/admin";
import { tableControlsType } from "typings/table-controls";

import styled from "./Admins.module.scss";
import AdminsTable from "./table/AdminsTable";
import HeaderLayout from "./HeaderLayout";

type AdminsLayoutProps = {
  params: tableControlsType;
  adminsList: TAdmin[];
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  totalRecords: number;
  isLoadingTable: boolean;
  handleOpenAddAdminPopup: () => void;
  handleOpenRemoveAdminPopup: () => void;
  changeSelectedAdmin: (data: TAdmin) => void;
  handleOpenEditAdminPopup: () => void;
  handleOpenEditAdminPasswordPopup: () => void;
  role: string;
  status: string;
  changeRole: (role: string) => void;
  changeStatus: (status: string) => void;
};

const AdminsLayout: FC<AdminsLayoutProps> = (props) => {
  return (
    <div className={styled["content-card"]}>
      <HeaderLayout
        handleOpenAddAdminPopup={props.handleOpenAddAdminPopup}
        role={props.role}
        status={props.status}
        changeRole={props.changeRole}
        changeStatus={props.changeStatus}
      />
      <AdminsTable
        params={props.params}
        setParams={props.setParams}
        adminsList={props.adminsList}
        totalRecords={props.totalRecords}
        isLoadingTable={props.isLoadingTable}
        handleOpenRemoveAdminPopup={props.handleOpenRemoveAdminPopup}
        changeSelectedAdmin={props.changeSelectedAdmin}
        handleOpenEditAdminPopup={props.handleOpenEditAdminPopup}
        handleOpenEditAdminPasswordPopup={props.handleOpenEditAdminPasswordPopup}
      />
    </div>
  );
};

export default AdminsLayout;
