import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";

import styled from "./RefferalListTable.module.scss";
import { tableControlsType } from "typings/table-controls";
import { TReferralsTotal, TRefferal } from "typings/refferal";

type SubscriptionsTableProps = {
  refferalListsList: TRefferal[];
  totals?: TReferralsTotal;
  isLoadingTable: boolean;
  totalRecords: number;
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  params: tableControlsType;
};

const RefferalListTable = (props: SubscriptionsTableProps) => {
  const { totals } = props;

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Итого" />
        <Column />
        <Column footer={totals?.countReferralAuth} />
        <Column footer={totals?.countReferralBuySubscription} />
        <Column footer={totals?.sumBalanceLastMonth} />
        <Column footer={totals?.sumBalanceAllTime} />
      </Row>
    </ColumnGroup>
  );

  const nameEmailPhoneTemplate = (rowData: TRefferal) => {
    return (
      <div>
        <div>{`${rowData.firstName} ${rowData.lastName}`}</div>
        <div>{rowData.email}</div>
        <div>{rowData.phone}</div>
      </div>
    );
  };
  const onPage = (event) => {
    props.setParams(event);
  };

  return (
    <div className={styled["table-wrapper"]}>
      <DataTable
        value={props.refferalListsList}
        responsiveLayout="scroll"
        dataKey="id"
        paginator
        lazy
        first={props.params.first}
        rows={15}
        totalRecords={props.totalRecords}
        onPage={onPage}
        loading={props.isLoadingTable}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        emptyMessage="Данных не найдено"
        footerColumnGroup={footerGroup}
      >
        <Column field="id" header="ID" />
        <Column body={nameEmailPhoneTemplate} header="Реферал" />
        <Column field="countReferralAuth" header="Количество зарегистрированных пользователей" />
        <Column
          field="countReferralBuySubscription"
          header="Количество пользователей, купивших подписку"
        />
        <Column field="sumBalanceLastMonth" header="Заработок за месяц" />
        <Column field="sumBalanceAllTime" header="Общий заработок" />
      </DataTable>
    </div>
  );
};

export default RefferalListTable;
