import { Toast } from "primereact/toast";
import { ToastState } from "typings/toast";
import { clearMessage } from "slices/toastSlice";
import { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { addLocale } from "primereact/api";

import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "./assets/layout/css/layout-light.scss";
import "./assets/theme/custom/combined-theme-light.scss";

addLocale("ru", {
  monthNamesShort: [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
  ],
});

const App = () => {
  const toastRef = useRef(null);
  const dispatch = useAppDispatch();
  const toastState: ToastState = useAppSelector((state) => state.toast);

  useEffect(() => {
    if (!toastRef) return;
    handleMessage(toastState);
  }, [toastState]);

  const handleMessage = (toastState: ToastState) => {
    if (toastState.summary.length > 1) {
      //@ts-ignore
      toastRef.current.show({
        severity: toastState.severity,
        summary: toastState.summary,
        detail: toastState.detail,
      });
    }
  };

  return (
    <>
      <Toast ref={toastRef} onHide={() => dispatch(clearMessage())} />
      <Outlet />
    </>
  );
};

export default App;
