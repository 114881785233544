import { phrases } from "constants/phrases";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { FileUpload } from "primereact/fileupload";
import { uploadFile } from "api/storage/uploadFile";
import { useGetRoles } from "utils/useGetRoles";
import { DEFAULT_FILE } from "constants/course";
import { useAppDispatch } from "utils/hooks";
import { addErrorMessage } from "slices/toastSlice";
import { Dispatch, FC, SetStateAction, useEffect, useRef } from "react";
import { TFile, TLessonInfo, TUpdateLessonData } from "typings/course";

import styled from "./Lesson.module.scss";
import { COURSE_FILE_FORMATS } from "constants/course-file-fomats";

type FilesProps = {
  lessonInfo: TLessonInfo;
  updateLesson: (lessonData: TUpdateLessonData) => void;
  list: TFile[];
  setList: Dispatch<SetStateAction<TFile[]>>;
};

const Files: FC<FilesProps> = (props) => {
  const { list, setList } = props;
  const { isCurator } = useGetRoles();

  const fileUploadReference = useRef(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.lessonInfo && props.lessonInfo.files.length === 0) {
      setList([DEFAULT_FILE]);
    } else setList(props.lessonInfo.files);
  }, [props.lessonInfo]);

  const removeLink = (removeIndex: number) => {
    if (list.length <= 1) setList([DEFAULT_FILE]);
    else setList((prev) => prev.filter((_, index) => index !== removeIndex));
  };

  const changeLink = (updateIndex: number, updatedField: string, value: string) => {
    const newArray = list.map((el, index) => {
      if (index === updateIndex) {
        return {
          ...el,
          [updatedField]: value,
        };
      } else return el;
    });
    setList(newArray);
  };

  const customUploader = async (event) => {
    try {
      const file = event.files[0];

      if (file) {
        const fileFormData = new FormData();
        fileFormData.append("file", file);
        const reponse = await uploadFile(fileFormData);

        props.updateLesson({
          files: [...props.lessonInfo.files, { name: "", file: reponse.data }],
        });

        setList((prev) => [...prev, { name: "", file: file }]);
      }
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    } finally {
      //@ts-ignore
      fileUploadReference?.current?.clear();
    }
  };

  return (
    <div className={styled["list-wrapper"]}>
      <p className={styled["list-title"]}>Файлы</p>
      <div className={styled["list"]}>
        {list.map((el, index) => (
          <div className={styled["list-row"]} key={index}>
            <i className={classNames("pi pi-file")} />
            <InputText
              maxLength={700}
              value={el.file?.name || ""}
              placeholder="Примерный файл.jpg"
              className={classNames(styled["list-input"], styled["list-input-file"])}
              disabled
            />
            <InputText
              maxLength={700}
              value={el.name}
              onChange={(e) => changeLink(index, "name", e.target.value)}
              placeholder="Название"
              className={styled["list-input"]}
              disabled={isCurator}
            />
            {!isCurator && (
              <button onClick={() => removeLink(index)} className={styled["list-remove-button"]}>
                <i className={"pi pi-trash"} />
              </button>
            )}
          </div>
        ))}
      </div>

      {!isCurator && (
        <div className={styled["button-wrapper"]}>
          <FileUpload
            ref={fileUploadReference}
            mode="basic"
            accept={COURSE_FILE_FORMATS.join(",")}
            maxFileSize={1000000}
            customUpload
            uploadHandler={customUploader}
            chooseLabel="Загрузить файл"
            auto
            className={styled["file-uploader"]}
          />
        </div>
      )}
    </div>
  );
};

export default Files;
