import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import styles from "./PersonalData.module.scss";
import { classNames } from "primereact/utils";
import { useForm, SubmitHandler } from "react-hook-form";
import { useProfileData } from "../useProfileData";
import { updateAdminInfo } from "api/admins/editAdminInfo";
import { useRevalidator } from "react-router";
import { phrases } from "constants/phrases";
import { useDispatch } from "react-redux";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { editMyInfoAsync } from "api/admins/editMyInfo";

export type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  website: string;
  telegram: string;
  instagram: string;
  youtube: string;
  biography: string;
};

const inputtextClass = "w-full p-inputtext-sm p-inputtext-light-bg";

const PersonalData = ({ myProfile }) => {
  const { profile } = useProfileData();
  const revalidator = useRevalidator();
  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<Inputs>({
    defaultValues: {
      firstName: profile?.firstName ?? "",
      lastName: profile?.lastName ?? "",
      email: profile?.email ?? "",
      website: profile?.website ?? "",
      telegram: profile?.telegram ?? "",
      instagram: profile?.instagram ?? "",
      youtube: profile?.youtube ?? "",
      biography: profile?.biography ?? "",
    },
  });

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      myProfile ? await editMyInfoAsync(data) : await updateAdminInfo(profile.id, data);
      dispatch(addSuccessMessage(phrases.personal_data_success_updated));
      revalidator.revalidate();
    } catch (error: any) {
      dispatch(addErrorMessage(error.response.data.message || phrases.smthWentWrongText));
    }
  };

  return (
    <form className={styles["personal-data-form"]} onSubmit={handleSubmit(onSubmit)}>
      <Card className={styles["personal-data-card"]}>
        <div className={styles["personal-data-fields"]}>
          <h5 className={styles["personal-data-title"]}>Личные данные</h5>
          <div className="field">
            <label>Имя</label>
            <InputText maxLength={600} className={inputtextClass} {...register("firstName")} />
          </div>
          <div className="field">
            <label>Фамилия</label>
            <InputText maxLength={600} className={inputtextClass} {...register("lastName")} />
          </div>
          <div className="field">
            <label>Почта</label>
            <InputText
              maxLength={256}
              type="email"
              className={inputtextClass}
              {...register("email")}
              disabled
            />
          </div>
          <div className="field">
            <label>Сайт</label>
            <InputText maxLength={700} className={inputtextClass} {...register("website")} />
          </div>
          <div className="field">
            <label>Telegram</label>
            <InputText maxLength={100} className={inputtextClass} {...register("telegram")} />
          </div>
          <div className="field">
            <label>Instagram</label>
            <InputText maxLength={100} className={inputtextClass} {...register("instagram")} />
          </div>
          <div className="field">
            <label>Youtube</label>
            <InputText maxLength={100} className={inputtextClass} {...register("youtube")} />
          </div>
          <div className={classNames(styles["bio-field"], "field")}>
            <label>Краткое био</label>
            <InputTextarea
              maxLength={1300}
              autoResize
              className="w-full p-inputtext-light-bg"
              {...register("biography")}
            />
          </div>
        </div>
      </Card>
      <Button
        label="Сохранить изменения"
        className="p-button-sm"
        onClick={handleSubmit(onSubmit)}
        disabled={!isDirty}
      />
    </form>
  );
};

export default PersonalData;
