import { phrases } from "constants/phrases";
import { TReferralsTotal, TRefferal } from "typings/refferal";
import { DEFAULT_PARAMS } from "constants/table-params";
import { useAppDispatch } from "utils/hooks";
import { addErrorMessage } from "slices/toastSlice";
import { useCallback, useEffect, useMemo, useState } from "react";

import styles from "./RefferalList.module.scss";

import RefferalListLayout from "./RefferalList.layout";
import { addMonths } from "date-fns";
import { tableControlsType } from "typings/table-controls";
import { getReferralsList } from "api/referrals/getReferralsList";

type RefferalListProps = {};

const RefferalList = (props: RefferalListProps) => {
  const [refferalListsList, setRefferalListList] = useState<TRefferal[]>([]);
  const [totals, setTotals] = useState<TReferralsTotal>();
  const [params, setParams] = useState<tableControlsType>(DEFAULT_PARAMS);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [monthEnd, setMonthEnd] = useState<Date | null>(null);

  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const getRefferalListData = useCallback(
    async (params) => {
      setIsLoadingTable(true);
      try {
        const response = await getReferralsList(params);
        if (response.status === 200 || response.status === 201) {
          setRefferalListList(response.data.data);
          setTotals(response.data.total);
          setTotalRecords(response.data.pagination.totalItems);
          setIsLoadingTable(false);
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getRefferalListData({
      limit: params.rows,
      page: params.page,
    });
  }, [getRefferalListData, monthEnd, params]);

  return (
    <div className={styles["page-container"]}>
      <RefferalListLayout
        monthEnd={monthEnd}
        setMonthEnd={setMonthEnd}
        refferalListsList={refferalListsList}
        totals={totals}
        isLoadingTable={isLoadingTable}
        totalRecords={totalRecords}
        setParams={setParams}
        params={params}
      />
    </div>
  );
};

export default RefferalList;
