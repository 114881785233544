import { FC, useCallback, useState } from "react";

import ReviewsLayout from "./Reviews.layout";
import { useLoaderData } from "react-router";
import { TReview, TReviewWithId } from "typings/reviews";
import { v4 as uuidv4 } from "uuid";
import { updateReviews } from "api/info/updateReviews";
import { phrases } from "constants/phrases";
import { addErrorMessage } from "slices/toastSlice";
import { useAppDispatch } from "utils/hooks";

type ReviewsProps = {};

const Reviews: FC<ReviewsProps> = () => {
  const { reviews } = useLoaderData() as { reviews: TReview[] };

  const [localReviews, setLocalReviews] = useState<TReviewWithId[]>(() =>
    reviews.map((el) => ({ ...el, id: uuidv4() }))
  );

  const addReview = () => {
    setLocalReviews((prev) => [...(prev || []), { id: uuidv4(), name: "", age: "", text: "" }]);
  };

  const dispatch = useAppDispatch();

  const onUpdateReviews = useCallback(
    async (newReviews) => {
      try {
        await updateReviews({
          reviews: newReviews.map((el) => {
            const { id, ...rest } = el;
            return rest;
          }),
        });
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      }
    },
    [dispatch]
  );

  const editReview = (reviewId: string, data: TReview) => {
    const index = localReviews.findIndex((el) => el.id === reviewId);
    const oldReview = localReviews[index];
    const changed =
      ["name", "age", "date", "text"].some((el) => oldReview[el] !== data[el]) ||
      oldReview?.file?.url !== data?.file?.url ||
      oldReview?.imageFile?.url !== data?.imageFile?.url;
    if (!changed) return;
    const newReviews = [
      ...localReviews.slice(0, index),
      { ...data, id: reviewId },
      ...localReviews.slice(index + 1),
    ];
    setLocalReviews(newReviews);
    onUpdateReviews(newReviews);
  };

  const deleteReview = (reviewId: string) => {
    const newReviews = localReviews.filter((el) => el.id !== reviewId);
    setLocalReviews(newReviews);
    onUpdateReviews(newReviews);
  };

  return (
    <ReviewsLayout
      reviews={localReviews}
      editReview={editReview}
      addReview={addReview}
      deleteReview={deleteReview}
    />
  );
};

export default Reviews;
