import { InputText } from "primereact/inputtext";
import styles from "./AddBroadcastForm.module.scss";
import { classNames } from "primereact/utils";
import PopupDateTimeCalendar from "popups/date-time/PopupDateTimeCalendar";
import { useForm, Controller } from "react-hook-form";
import { TNewBroadcast } from "typings/broadcast";
import { Button } from "primereact/button";
import SingleFileUpload from "components/common/single-file-upload/SingleFileUpload";
import { uploadFile } from "api/storage/uploadFile";
import { Checkbox } from "primereact/checkbox";

type BroadcastCardProps = {
  onCreateBroadcast: (data: TNewBroadcast) => Promise<void>;
};

type Inputs = {
  name: string;
  startAt: Date;
  description: string;
  link: string;
  file: File;
  isPublic: boolean;
};

const AddBroadcastForm = ({ onCreateBroadcast }: BroadcastCardProps) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting, isValid, isDirty },
  } = useForm<Inputs>({
    defaultValues: {
      name: "",
      description: "",
      link: "",
      isPublic: false,
    },
  });

  const onSubmit = async ({ name, description, link, startAt, file, isPublic }: Inputs) => {
    let imageUrl;
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      const {
        data: { url },
      } = await uploadFile(fileFormData);
      imageUrl = url;
    }
    await onCreateBroadcast({
      name,
      description,
      link,
      isPublic,
      startAt: startAt.toISOString(),
      imageUrl: imageUrl,
    });
    setValue("name", "");
    setValue("description", "");
    setValue("link", "");
  };

  return (
    <form className={styles["broadcast-card"]} onSubmit={handleSubmit(onSubmit)}>
      <InputText
        maxLength={700}
        placeholder="Название трансляции"
        className={classNames(styles["name"], "p-inputtext-light-bg")}
        {...register("name", { required: true })}
        required
      />
      <Controller
        name="startAt"
        control={control}
        rules={{ required: { value: true, message: "Необходимо указать дату" } }}
        render={({ field }) => (
          <PopupDateTimeCalendar
            showTime
            placeholder="Дата"
            inputClassName={classNames(styles["date"], "p-inputtext-light-bg")}
            date={field.value}
            setDate={(date) => {
              field.onChange(date);
              handleSubmit(onSubmit);
            }}
          />
        )}
      />
      <InputText
        maxLength={1300}
        required
        {...register("description", { required: true })}
        className={classNames(styles["description"], "p-inputtext-light-bg")}
        placeholder="Описание трансляции"
      />
      <InputText
        maxLength={700}
        required
        {...register("link", { required: true })}
        className={classNames(styles["link"], "p-inputtext-light-bg")}
        placeholder="Ссылка"
      />
      <div className={styles["checkbox-wrapper"]}>
        <label htmlFor="isPublic">Общая трансляция</label>
        <Controller
          name="isPublic"
          control={control}
          render={({ field }) => (
            <Checkbox
              onChange={(event) => field.onChange(event.target.checked)}
              checked={field.value}
              inputId="isPublic"
            />
          )}
        />
      </div>
      <div className={styles["image-uploader"]}>
        <Controller
          name="file"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <SingleFileUpload file={field.value} setFile={field.onChange} />}
        />
      </div>

      <Button
        label="Опубликовать"
        className="p-button-sm"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        disabled={!isDirty || !isValid}
      />
    </form>
  );
};

export default AddBroadcastForm;
