import { FC } from "react";

import AdminInfo from "features/AdminInfo/AdminInfo";

type AdminInfoPageProps = {};

const AdminInfoPage: FC<AdminInfoPageProps> = () => {
  return <AdminInfo />;
};

export default AdminInfoPage;
