import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ADMIN_ROLES } from "constants/admins";
import { TAdmin, TNewAdmin } from "typings/admin";
import { FC, useEffect, useState } from "react";

import styles from "./EditAdminPopup.module.scss";
import { Checkbox } from "primereact/checkbox";

type EditAdminPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedAdmin: TAdmin | null;
  updateAdmin: (adminData: TNewAdmin) => void;
};

const EditAdminPopup: FC<EditAdminPopupProps> = (props) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [isGuest, setIsGuest] = useState<boolean | undefined>(false);

  const closePopup = () => {
    props.handleHide();
  };

  useEffect(() => {
    if (props.isOpen && props.selectedAdmin) {
      setFirstName(props.selectedAdmin.firstName);
      setLastName(props.selectedAdmin.lastName);
      setRole(props.selectedAdmin.role);
      setIsGuest(props.selectedAdmin?.isGuest);
    }
  }, [props.isOpen, props.selectedAdmin]);

  const editAdmin = () => {
    props.updateAdmin({
      firstName,
      lastName,
      role,
      isGuest: role === "speaker" ? isGuest : undefined,
    });
    closePopup();
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <p className={styles["title"]}>Редактирование администратора</p>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Имя</p>
          <InputText
            maxLength={600}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Имя"
            className={styles["input"]}
          />
        </div>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Фамилия</p>
          <InputText
            maxLength={600}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Фамилия"
            className={styles["input"]}
          />
        </div>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Выберите роль</p>
          <Dropdown
            options={ADMIN_ROLES}
            onChange={(e) => setRole(e.value)}
            value={role}
            placeholder="Выберите роль"
            className={styles["dropdown"]}
          />
        </div>
        {role === "speaker" && (
          <div className={styles["checkbox-wrapper"]}>
            <p className={styles["input-label"]}>Гость</p>
            <Checkbox onChange={(e) => setIsGuest(e.checked)} checked={isGuest} inputId="isGuest" />
          </div>
        )}
        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button
            className={styles["edit-button"]}
            onClick={editAdmin}
            disabled={
              !firstName ||
              !lastName ||
              (props.selectedAdmin?.firstName === firstName &&
                props.selectedAdmin.lastName === lastName &&
                props.selectedAdmin.role === role)
            }
          >
            Изменить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditAdminPopup;
