import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useGetRoles } from "utils/useGetRoles";
import { FC, useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { TCourseInfo, TUpdateCourseData } from "typings/course";

import styled from "./PromoTab.module.scss";

type WhatWillYouLearnProps = {
  updateCourse: (courseData: TUpdateCourseData) => void;
  courseInfo: TCourseInfo;
};

const WhatWillYouLearn: FC<WhatWillYouLearnProps> = (props) => {
  const [list, setList] = useState<string[]>([""]);

  const { isAdmin, isSpeacker, isCurator } = useGetRoles();

  useEffect(() => {
    if (props.courseInfo && props.courseInfo.aboutCourse.learn.length === 0) {
      setList([""]);
    } else setList(props.courseInfo.aboutCourse.learn);
  }, [props.courseInfo]);

  const addDirection = () => setList((prev) => [...prev, ""]);

  const removeDirection = (removeIndex: number) => {
    if (list.length <= 1) setList([""]);
    else setList((prev) => prev.filter((_, index) => index !== removeIndex));
  };

  const changeDirection = (updateIndex: number, value: string) => {
    const newArray = list.map((el, index) => {
      if (index === updateIndex) {
        return value;
      } else return el;
    });
    setList(newArray);
  };

  const returnDisabledSaveButton = () =>
    JSON.stringify(props.courseInfo.aboutCourse.learn) === JSON.stringify(list) ||
    (list.length < 2 && list[0] === "") ||
    !list[list.length - 1] ||
    list.some((el) => !el);

  return (
    <div className={styled["accordion-container"]}>
      <Accordion className={styled["accordion"]}>
        <AccordionTab header="Чему вы научитесь">
          <div className={styled["accordion-list"]}>
            {list.map((el, index) => (
              <div className={styled["accordion-list-item"]} key={index}>
                <InputText
                  maxLength={700}
                  value={el}
                  onChange={(e) => changeDirection(index, e.target.value)}
                  placeholder="Описание"
                  className={styled["accordion-list-item-input"]}
                  disabled={!isAdmin && !isSpeacker}
                />
                {!isCurator && (
                  <Button
                    className="p-button-rounded p-button-secondary p-button-icon-only p-button-sm"
                    onClick={() => removeDirection(index)}
                    disabled={!isAdmin && !isSpeacker}
                    icon="pi pi-trash"
                  />
                )}
              </div>
            ))}
          </div>
          {!isCurator && (
            <div className={styled["button-wrapper"]}>
              <Button onClick={addDirection} className={styled["accordion-list-button"]}>
                Добавить панель
              </Button>
              <Button
                onClick={() =>
                  props.updateCourse({
                    aboutCourse: { ...props.courseInfo.aboutCourse, learn: list },
                  })
                }
                className={styled["accordion-list-button"]}
                disabled={returnDisabledSaveButton()}
              >
                Сохранить
              </Button>
            </div>
          )}
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default WhatWillYouLearn;
