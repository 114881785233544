import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TApplication } from "typings/applications";
import { FC, useEffect, useState } from "react";
import { AVAILABLE_APPLICATION_STATUSES } from "constants/applications";

import styles from "./ChangeStatusApplicationPopup.module.scss";

type ChangeStatusApplicationPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedApplication: TApplication | null;
  editStatusApplication: (newStatus: string) => void;
};

const ChangeStatusApplicationPopup: FC<ChangeStatusApplicationPopupProps> = (props) => {
  const [status, setStatus] = useState("new");

  const closePopup = () => props.handleHide();

  const editStatus = () => {
    props.editStatusApplication(status);
    closePopup();
  };

  useEffect(() => {
    if (props.selectedApplication) setStatus(props.selectedApplication.status);
  }, [props.selectedApplication]);

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <p className={styles["title"]}>Изменить статус услуги</p>
        <div className={styles["dropdown-wrapper"]}>
          <Dropdown
            options={AVAILABLE_APPLICATION_STATUSES}
            onChange={(e) => setStatus(e.value)}
            value={status}
            placeholder="Выберите статус"
            className={styles["dropdown"]}
          />
        </div>
        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button className={styles["edit-button"]} onClick={editStatus}>
            Изменить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ChangeStatusApplicationPopup;
