import { FC } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { STATUSES } from "constants/filters";

import styled from "./Users.module.scss";

type HeaderLayoutProps = {
  handleOpenAddUserPopup: () => void;
  status: string;
  changeStatus: (status: string) => void;
  downloadUsers: () => void;
};

const HeaderLayout: FC<HeaderLayoutProps> = (props) => {
  return (
    <div className={styled["header-layout"]}>
      <div className={styled["filters"]}>
        <Button
          label="Добавить пользователя"
          icon="pi pi-plus"
          type="button"
          className="p-button-sm"
          onClick={props.handleOpenAddUserPopup}
        />
        <div className={styled["dropdown-wrapper"]}>
          <Dropdown
            options={STATUSES}
            onChange={(e) => props.changeStatus(e.value)}
            value={props.status}
            placeholder="Выберите статус"
            className={styled["dropdown"]}
          />
        </div>
      </div>
      <Button label="Экспорт в csv" onClick={props.downloadUsers} className="p-button-sm" />
    </div>
  );
};

export default HeaderLayout;
