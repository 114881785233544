import { AxiosPromise } from "axios";
import { TReferralsTotal, TRefferal } from "typings/refferal";
import { TPagination } from "typings/table-controls";
import http from "../https";

type GetReferralsListParamsType = {
  limit?: number;
  page?: number;
};

type TReferralsListResponse = {
  pagination: TPagination;
  data: TRefferal[];
  total: TReferralsTotal;
};

export const getReferralsList = (
  params: GetReferralsListParamsType
): AxiosPromise<TReferralsListResponse> => http.get("/api/referrals/list", {}, { params });
