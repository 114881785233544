import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { CHAGE_POSITION } from "constants/course";
import { TModule, TShortLesson } from "typings/course";
import { FC, useCallback, useEffect, useState } from "react";

import styles from "./MovingLessonsPopup.module.scss";

type MovingLessonsPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedModule: TModule | null;
  changeLessonsPositions: (newLessonList: TShortLesson[]) => void;
};

const MovingLessonsPopup: FC<MovingLessonsPopupProps> = (props) => {
  const [itemList, setItemList] = useState<TShortLesson[]>([]);

  const closePopup = () => props.handleHide();

  useEffect(() => {
    if (props.isOpen && props.selectedModule) {
      const list = props.selectedModule.lessons.map((lesson) => ({
        id: lesson.id,
        index: lesson.index,
        name: lesson.name,
      }));
      setItemList(list);
    }
  }, [props.isOpen, props.selectedModule]);

  const changePosition = (type: string, indexItem: number) => {
    const swap = (index1: number, index2: number) => {
      const newArray = itemList.map((el) => {
        if (el.index === index1) {
          return { ...el, index: index2 };
        } else if (el.index === index2) return { ...el, index: index1 };
        else return el;
      });
      setItemList(newArray);
    };

    if (
      (indexItem === 0 && type === CHAGE_POSITION.UP) ||
      (indexItem === itemList.length - 1 && type === CHAGE_POSITION.DOWN)
    )
      return;
    if (type === CHAGE_POSITION.UP) swap(indexItem, indexItem - 1);
    else if (type === CHAGE_POSITION.DOWN) swap(indexItem, indexItem + 1);
    else return;
  };

  const changePositions = () => {
    const list = itemList.map((el) => ({ id: el.id, index: el.index }));
    props.changeLessonsPositions(list);
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <div className={styles["header"]}>
          <p className={styles["title"]}>Перемещение уроков</p>
          <Button
            icon="pi pi-times"
            className="p-button-sm p-button-text p-button-secondary p-button-icon-only"
            onClick={closePopup}
          />
        </div>
        <p className={styles["description"]}>
          Расположите уроки в необходимой последовательности, после нажмите кнопку сохранить.
        </p>

        {itemList && itemList.length > 0 ? (
          itemList
            .sort((a, b) => (a.index > b.index ? 1 : -1))
            .map((el, index) => (
              <div className={styles["ch-pl-item"]} key={el.id}>
                <span>
                  <p>{index + 1}.</p>
                  <p>{el.name}</p>
                </span>
                <div className={styles["ch-pl-item-settings"]}>
                  {index !== 0 && (
                    <button onClick={() => changePosition(CHAGE_POSITION.UP, el.index)}>
                      <i className="pi pi-arrow-up" />
                    </button>
                  )}
                  {index !== itemList.length - 1 && (
                    <button onClick={() => changePosition(CHAGE_POSITION.DOWN, el.index)}>
                      <i className="pi pi-arrow-down" />
                    </button>
                  )}
                </div>
              </div>
            ))
        ) : (
          <p className={styles["popup-wrapper-empty_list"]}>Вы не добавили ни одного урока</p>
        )}

        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button className={styles["move-button"]} onClick={changePositions}>
            Сохранить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default MovingLessonsPopup;
