import { phrases } from "constants/phrases";
import { useAppDispatch } from "utils/hooks";
import { TDatesPairType } from "typings/dates";
import { addErrorMessage } from "slices/toastSlice";
import { getRefferalSystemStatisticsAsync } from "api/refferal-system/getStatistics";
import { TDefaultRefferalSystemStatistics } from "typings/refferal-system";
import { DEFAULT_REFFERAL_SYSTEM_STATISTICS } from "constants/refferal-system";
import { FC, useCallback, useEffect, useState } from "react";

import styled from "./RefferalSystem.module.scss";
import RefferalSystemLayout from "./RefferalSystem.layout";

type RefferalSystemProps = {};

const RefferalSystem: FC<RefferalSystemProps> = () => {
    const [dates, setDates] = useState<TDatesPairType>();
    const [statistics, setStatistics] = useState<TDefaultRefferalSystemStatistics>(
        DEFAULT_REFFERAL_SYSTEM_STATISTICS
    );

    const dispatch = useAppDispatch();

    const getRefferalSystemStatistics = useCallback(async () => {
        try {
            const response = await getRefferalSystemStatisticsAsync({
                startDate: dates?.[0] || undefined,
                finishDate: dates?.[1] || undefined,
                isAllTime: !dates?.[0] && !dates?.[1] ? true : false,
            });
            if (response.status === 200 || response.status === 201) {
                setStatistics(response.data);
            } else throw new Error();
        } catch (err: any) {
            dispatch(addErrorMessage(err.response?.data?.message || phrases.smthWentWrongText));
        }
    }, [dispatch, dates]);

    useEffect(() => {
        getRefferalSystemStatistics();
    }, [getRefferalSystemStatistics]);

    return (
        <div className={styled["page-container"]}>
            <RefferalSystemLayout dates={dates} setDates={setDates} statistics={statistics} />
        </div>
    );
};

export default RefferalSystem;
