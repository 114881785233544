import { Menu } from "primereact/menu";
import { TUser } from "typings/user";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { tableControlsType } from "typings/table-controls";
import { FC, useEffect, useRef, useState } from "react";
import {
  renderColumn,
  renderDate,
  renderFIO,
  renderSubscriptionStatus,
  renderTelegramStatus,
} from "./actions";

import styled from "./UsersTable.module.scss";
import useDebounce from "utils/use-debounce";

type RenderActionProps = {
  rowData: TUser;
  changeSelectedUser: (data: TUser) => void;
  handleOpenEditUserRefLinkPopup: () => void;
  handleOpenCustomSubscriptionPopup: () => void;
  handleOpenBlockUserPopup: () => void;
  handleOpenUnblockUserPopup: () => void;
  handleOpenEditUserPopup: () => void;
  handleOpenEditUserPasswordPopup: () => void;
  handleOpenRemoveUserPopup: () => void;
  handleOpenUserOnboardingPopup: () => void;
  handleOpenRefferalStatisticsPopup: () => void;
};

const RenderAction = ({
  rowData,
  changeSelectedUser,
  handleOpenEditUserRefLinkPopup,
  handleOpenCustomSubscriptionPopup,
  handleOpenBlockUserPopup,
  handleOpenUnblockUserPopup,
  handleOpenEditUserPopup,
  handleOpenEditUserPasswordPopup,
  handleOpenRemoveUserPopup,
  handleOpenUserOnboardingPopup,
  handleOpenRefferalStatisticsPopup,
}: RenderActionProps) => {
  const noRealSub = !rowData.subscriptionInfo || rowData.subscriptionInfo.isCustom;
  const menu = useRef<Menu>(null);
  const items = [
    {
      label: "Реферальная ссылка",
      icon: "pi pi-sitemap",
      command: () => {
        changeSelectedUser(rowData);
        handleOpenEditUserRefLinkPopup();
      },
    },
    {
      label: "Подписка",
      icon: noRealSub ? "pi pi-user-edit" : "pi pi-thumbs-up",
      command: () => {
        changeSelectedUser(rowData);
        handleOpenCustomSubscriptionPopup();
      },
    },
    {
      label: rowData.status === 1 ? "Блокировать" : "Разблокировать",
      icon: rowData.status === 1 ? "pi pi-lock" : "pi pi-lock-open",
      command: () => {
        changeSelectedUser(rowData);
        if (rowData.status === 1) {
          handleOpenBlockUserPopup();
        } else handleOpenUnblockUserPopup();
      },
    },
    {
      label: "Редактировать пользователя",
      icon: "pi pi-pencil",
      command: () => {
        changeSelectedUser(rowData);
        handleOpenEditUserPopup();
      },
    },
    {
      label: "Редактировать пароль",
      icon: "pi pi-key",
      command: () => {
        changeSelectedUser(rowData);
        handleOpenEditUserPasswordPopup();
      },
    },
    {
      label: "Удалить пользователя",
      icon: "pi pi-trash",
      command: () => {
        changeSelectedUser(rowData);
        handleOpenRemoveUserPopup();
      },
    },
    {
      label: "Данные онбординга",
      icon: "pi pi-info-circle",
      command: () => {
        changeSelectedUser(rowData);
        handleOpenUserOnboardingPopup();
      },
    },
    {
      label: "Статистика реферальной системы",
      icon: "pi pi-chart-bar",
      command: () => {
        changeSelectedUser(rowData);
        handleOpenRefferalStatisticsPopup();
      },
    },
  ];
  return (
    <>
      <Button
        label="Действия"
        icon="pi pi-bars"
        className="p-button-sm p-button-secondary"
        onClick={(event) => menu?.current?.toggle(event)}
        aria-controls="popup_menu"
        aria-haspopup
      />
      <Menu model={items} popup ref={menu} id="popup_menu" />
    </>
  );
};

type UsersTableProps = {
  params: tableControlsType;
  usersList: TUser[];
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  totalRecords: number;
  isLoadingTable: boolean;
  handleOpenRemoveUserPopup: () => void;
  changeSelectedUser: (data: TUser) => void;
  handleOpenEditUserPopup: () => void;
  handleOpenBlockUserPopup: () => void;
  handleOpenUnblockUserPopup: () => void;
  handleOpenCustomSubscriptionPopup: () => void;
  handleOpenEditUserRefLinkPopup: () => void;
  handleOpenEditUserPasswordPopup: () => void;
  handleOpenUserOnboardingPopup: () => void;
  handleOpenRefferalStatisticsPopup: () => void;
};

const UsersTable: FC<UsersTableProps> = (props) => {
  const [searchLocalValue, setSearchLocalValue] = useState<string>("");

  const debouncedSearchValue = useDebounce(searchLocalValue, 500);

  useEffect(() => {
    if (debouncedSearchValue !== props.params?.search) {
      props.setParams((prev) => ({ ...prev, search: debouncedSearchValue }));
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (searchLocalValue !== props.params?.search && typeof props.params?.search === "string") {
      setSearchLocalValue(props.params?.search);
    }
  }, [props.params?.search]);

  const onPage = (event) => {
    props.setParams(event);
  };

  return (
    <div className={styled["table-wrapper"]}>
      <div className={styled["table-header"]}>
        <p className={styled["table-name"]}>Пользователи</p>
        <span
          className={classNames("p-input-icon-left p-main-input", styled["search-input-wrapper"])}
        >
          <i className="pi pi-search" />
          <InputText
            maxLength={700}
            value={searchLocalValue}
            type="text"
            placeholder="Поиск"
            onChange={(e) => setSearchLocalValue(e.target.value)}
            className={styled["search-input"]}
          />
        </span>
      </div>
      <DataTable
        value={props.usersList}
        responsiveLayout="scroll"
        dataKey="id"
        paginator
        first={props.params.first}
        lazy
        rows={15}
        totalRecords={props.totalRecords}
        onPage={onPage}
        loading={props.isLoadingTable}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        emptyMessage="Пользователей не найдено"
      >
        <Column field="id" header="ID" style={{ width: "10%" }} body={renderColumn("id")}></Column>
        <Column field="fio" header="ФИО" style={{ width: "20%" }} body={renderFIO}></Column>
        <Column
          field="createdAt"
          header="Создано"
          style={{ width: "10%" }}
          body={renderDate("createdAt")}
        ></Column>
        <Column
          field="email"
          header="Email"
          style={{ width: "10%" }}
          body={renderColumn("email")}
        ></Column>
        <Column
          field="phone"
          header="Телефон"
          style={{ width: "20%" }}
          body={renderColumn("phone")}
        ></Column>
        <Column
          field="subscriptionStatus"
          header="Статус подписки"
          style={{ width: "10%" }}
          body={renderSubscriptionStatus}
        ></Column>
        <Column
          field="isSubscribeTelegram"
          header="Статус телеграм"
          style={{ width: "10%" }}
          body={renderTelegramStatus}
        ></Column>
        <Column
          field="actions"
          header=""
          style={{ width: "10%" }}
          body={(rowData) => (
            <RenderAction
              rowData={rowData}
              changeSelectedUser={props.changeSelectedUser}
              handleOpenEditUserRefLinkPopup={props.handleOpenEditUserRefLinkPopup}
              handleOpenCustomSubscriptionPopup={props.handleOpenCustomSubscriptionPopup}
              handleOpenBlockUserPopup={props.handleOpenBlockUserPopup}
              handleOpenUnblockUserPopup={props.handleOpenUnblockUserPopup}
              handleOpenEditUserPopup={props.handleOpenEditUserPopup}
              handleOpenEditUserPasswordPopup={props.handleOpenEditUserPasswordPopup}
              handleOpenRemoveUserPopup={props.handleOpenRemoveUserPopup}
              handleOpenUserOnboardingPopup={props.handleOpenUserOnboardingPopup}
              handleOpenRefferalStatisticsPopup={props.handleOpenRefferalStatisticsPopup}
            />
          )}
        ></Column>
      </DataTable>
    </div>
  );
};

export default UsersTable;
