import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import styles from "./OutCourseFromMainPagePopup.module.scss";

type OutCourseFromMainPagePopupProps = {
    isOpen: boolean;
    handleHide: () => void;
    changeCoursePromo: (value: boolean) => void;
};

const OutCourseFromMainPagePopup: FC<OutCourseFromMainPagePopupProps> = (props) => {
    const closePopup = () => {
        props.handleHide();
    };

    return (
        <Dialog
            visible={props.isOpen}
            modal
            onHide={closePopup}
            className={styles["dialog"]}
            showHeader={false}
        >
            <div className={styles["content"]}>
                <p className={styles["title"]}>Убрать курс с Главной страницы?</p>

                <div className={styles["buttons-container"]}>
                    <Button className={styles["cancel-button"]} onClick={closePopup}>
                        Отмена
                    </Button>
                    <Button
                        className={styles["remove-button"]}
                        onClick={() => props.changeCoursePromo(false)}
                    >
                        Снять
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default OutCourseFromMainPagePopup;
