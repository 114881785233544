import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useGetRoles } from "utils/useGetRoles";
import { FC, useState } from "react";

import Tags from "components/common/tags/Tags";
import styled from "./AdminInfo.module.scss";
import PopularQuestions from "./PopularQuestions";
import NotAdminPopularQuestions from "./NotAdminPopularQuestions";

type AdminInfoLayoutProps = {};

const AdminInfoLayout: FC<AdminInfoLayoutProps> = () => {
  const [search, setSearch] = useState<string>("");
  const { isAdmin } = useGetRoles();
  return (
    <div className={styled["content-container"]}>
      <div className={styled["card-content"]}>
        <span
          className={classNames("p-input-icon-left p-main-input", styled["search-input-wrapper"])}
        >
          <i className="pi pi-search" />
          <InputText
            maxLength={700}
            value={search}
            type="text"
            placeholder="Поиск"
            onChange={(e) => setSearch(e.target.value)}
            className={styled["search-input"]}
          />
        </span>

        {isAdmin ? (
          <PopularQuestions search={search} />
        ) : (
          <NotAdminPopularQuestions search={search} />
        )}
      </div>

      <Tags type="admin" />
    </div>
  );
};

export default AdminInfoLayout;
