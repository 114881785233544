import { FC } from "react";
import { TTag } from "typings/tags";
import { useLoaderData } from "react-router";
import { TFaqLoaderResult } from "typings/loaders";
import { Accordion, AccordionTab } from "primereact/accordion";

import styled from "./AdminInfo.module.scss";
import opacity from "utils/opacity";

type NotAdminPopularQuestionsProps = {
  search: string;
};

const NotAdminPopularQuestions: FC<NotAdminPopularQuestionsProps> = ({ search }) => {
  const { faq } = useLoaderData() as TFaqLoaderResult;

  const localFaq = faq.filter((el) => el.question.toLowerCase().includes(search.toLowerCase()));

  return (
    <div className={styled["accordion-container"]}>
      {localFaq.map((el) => (
        <Accordion className={styled["accordion"]} activeIndex={0} key={el.id}>
          <AccordionTab header={accordionHeader(el.question, el.tags)}>
            <p className={styled["answer"]}>{el.answer}</p>
          </AccordionTab>
        </Accordion>
      ))}
    </div>
  );
};

const accordionHeader = (question: string, tags: TTag[]) => {
  return (
    <div className={styled["not-admin-accordion-header"]}>
      <p>{question || "---"}</p>
      <div>
        {tags.map((tag) => (
          <span
            className={styled["not-admin-accordion-header-label"]}
            key={tag.id}
            style={{ backgroundColor: opacity(tag.color, 0.3), color: tag.color }}
          >
            {tag.header}
          </span>
        ))}
      </div>
    </div>
  );
};

export default NotAdminPopularQuestions;
