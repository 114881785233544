import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { TCourseInfo } from "typings/course";

import styles from "./DeactivateCoursePopup.module.scss";

type DeactivateCoursePopupProps = {
    isOpen: boolean;
    handleHide: () => void;
    deactivateCourse: () => void;
    courseInfo: TCourseInfo;
};

const DeactivateCoursePopup: FC<DeactivateCoursePopupProps> = (props) => {
    const closePopup = () => {
        props.handleHide();
    };

    return (
        <Dialog
            visible={props.isOpen}
            modal
            onHide={closePopup}
            className={styles["dialog"]}
            showHeader={false}
        >
            <div className={styles["content"]}>
                <i className={classNames("pi pi-clock", styles["icon"])} />

                <p className={styles["title"]}>Деактивировать курс</p>

                <p className={styles["text"]}>“{props.courseInfo.name}”</p>

                <p className={styles["warning"]}>
                    Действие материала будет приостановлено. Курс будет скрыт от пользователей.
                </p>

                <div className={styles["buttons-container"]}>
                    <Button className={styles["cancel-button"]} onClick={closePopup}>
                        Отмена
                    </Button>
                    <Button className={styles["deactivate-button"]} onClick={props.deactivateCourse}>
                        Деактивировать
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DeactivateCoursePopup;
