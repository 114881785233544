import { FC } from "react";
import { Dropdown } from "primereact/dropdown";
import { APPLICATION_STATUSES } from "constants/applications";

import styled from "./Applications.module.scss";

type HeaderLayoutProps = {
  status: string | undefined;
  changeStatus: (status: string | undefined) => void;
};

const HeaderLayout: FC<HeaderLayoutProps> = (props) => {
  return (
    <div className={styled["header-layout"]}>
      <div className={styled["dropdown-wrapper"]}>
        <Dropdown
          options={APPLICATION_STATUSES}
          onChange={(e) => props.changeStatus(e.value)}
          value={props.status}
          placeholder="Выберите статус"
          className={styled["dropdown"]}
        />
      </div>
    </div>
  );
};

export default HeaderLayout;
