import styles from "./Post.module.scss";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TPost, TPostsUpdate } from "typings/post";
import SingleFileUpload from "components/common/single-file-upload/SingleFileUpload";
import formatDate from "utils/formatDate";
import { useParams } from "react-router-dom";
import formatTime from "utils/formatTime";
import { useGetRoles } from "utils/useGetRoles";
import { classNames } from "primereact/utils";

type PostProps = {
  openDeletePopup: (id: number) => void;
  onEditPost: (id: number, data: TPostsUpdate) => Promise<void>;
  post: TPost;
};

type Inputs = {
  text: string;
  header: string;
  file: File | null;
};

const Post = ({ openDeletePopup, onEditPost, post }: PostProps) => {
  const { id, imageUrl, header, updatedAt, text } = post;
  const { id: profileId } = useParams();
  const { isAdmin } = useGetRoles();
  const [isEditing, setIsEditing] = useState(false);
  const { register, handleSubmit, control, setValue } = useForm<Inputs>({
    defaultValues: {
      text,
      header,
    },
  });

  useEffect(() => {
    if (isEditing) {
      setValue("file", null);
      setValue("text", text || "");
      setValue("header", header);
      setLocalImage(imageUrl || null);
    }
  }, [header, imageUrl, isEditing, setValue, text]);

  const onSubmit = async (data: any) => {
    await onEditPost(id, data);
    setIsEditing(false);
  };

  const [localImage, setLocalImage] = useState<string | null>(imageUrl || null);

  return (
    <form className={styles["post"]} onSubmit={handleSubmit(onSubmit)}>
      {isEditing ? (
        <div className={styles["post-card"]}>
          <div className={classNames(styles["post-card-image"], styles["editing"])}>
            {localImage ? (
              <>
                <img
                  src={localImage}
                  alt="profile"
                  className={styles["user-profile-image"]}
                  width={50}
                  height={50}
                />

                <i
                  className="pi pi-trash"
                  onClick={() => {
                    setLocalImage(null);
                    setValue("file", null);
                  }}
                />
              </>
            ) : (
              <div className={styles["user-profile-image"]}></div>
            )}
          </div>
          <div className={styles["text-wrapper"]}>
            <div className={styles["title-wrapper-input"]}>
              <InputText maxLength={700} {...register("header")} className="w-full" />
              <p className={styles["date"]}>
                {formatTime(new Date(updatedAt))} - {formatDate(new Date(updatedAt))}
              </p>
            </div>
            <InputTextarea {...register("text")} autoResize className="w-full" />
            <div>{}</div>
            <Controller
              name="file"
              control={control}
              render={({ field }) => (
                <SingleFileUpload
                  file={field.value}
                  setFile={(file) => {
                    setLocalImage(file ? URL.createObjectURL(file) : null);
                    field.onChange(file);
                  }}
                />
              )}
            />
          </div>
        </div>
      ) : (
        <div className={styles["post-card"]}>
          <div className={styles["post-card-image"]}>
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="profile"
                className={styles["user-profile-image"]}
                width={50}
                height={50}
              />
            ) : (
              <div className={styles["user-profile-image"]}></div>
            )}
          </div>
          <div className={styles["text-wrapper"]}>
            <div className={styles["title-wrapper"]}>
              <h5 className={styles["title"]}>{header}</h5>
              <p className={styles["date"]}>
                {formatTime(new Date(updatedAt))} - {formatDate(new Date(updatedAt))}
              </p>
            </div>
            <p className={styles["text"]}>{text}</p>
          </div>
        </div>
      )}
      {(!profileId || isAdmin) && (
        <div className={styles["button-wrapper"]}>
          {isEditing ? (
            <Button
              icon="pi pi-save"
              className="p-button p-component p-button-rounded p-button-icon-only"
              type="submit"
            />
          ) : (
            <Button
              icon="pi pi-pencil"
              className="p-button p-component p-button-rounded p-button-icon-only"
              type="button"
              onClick={(event) => {
                event.preventDefault();
                setIsEditing(true);
              }}
            />
          )}
          <Button
            onClick={(event) => {
              event.preventDefault();
              openDeletePopup(id);
            }}
            className="p-button-rounded p-button-secondary"
            icon="pi pi-trash"
            type="button"
          />
        </div>
      )}
    </form>
  );
};

export default Post;
