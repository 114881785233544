import { InputText } from "primereact/inputtext";
import styles from "./BroadcastCard.module.scss";
import { Button } from "primereact/button";
import { useState } from "react";
import { classNames } from "primereact/utils";
import PopupDateTimeCalendar from "popups/date-time/PopupDateTimeCalendar";
import { useForm, Controller } from "react-hook-form";
import { TBroadcast, TBroadcastData } from "typings/broadcast";
import SingleFileUpload from "components/common/single-file-upload/SingleFileUpload";
import { uploadFile } from "api/storage/uploadFile";
import { Checkbox } from "primereact/checkbox";

type BroadcastCardProps = {
  broadcast: TBroadcast;
  onEditBroadcast: (id: number, data: TBroadcastData) => Promise<void>;
  openDeletePopup: (id: number) => void;
};

type Inputs = {
  name: string;
  startAt: Date;
  description: string;
  link: string;
  file: File | string | null;
  isPublic: boolean;
};

const BroadcastCard = ({ broadcast, onEditBroadcast, openDeletePopup }: BroadcastCardProps) => {
  const { id } = broadcast;
  const [isEditing, setIsEditing] = useState(false);

  const onDelete = (id: number) => {
    openDeletePopup(id);
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
  } = useForm<Inputs>({
    defaultValues: {
      name: broadcast.name,
      startAt: new Date(broadcast.startAt),
      description: broadcast.description,
      link: broadcast.link,
      file: broadcast.imageUrl,
      isPublic: broadcast.isPublic,
    },
  });
  const onSubmit = async (data: Inputs) => {
    let imageUrl: string | undefined;
    if (data.file && data.file instanceof File) {
      const fileFormData = new FormData();
      fileFormData.append("file", data.file);
      const {
        data: { url },
      } = await uploadFile(fileFormData);
      imageUrl = url;
    }

    await onEditBroadcast(broadcast.id, {
      name: data.name,
      description: data.description,
      link: data.link,
      imageUrl: imageUrl,
      startAt: data.startAt.toISOString(),
      isPublic: data.isPublic,
    });
    setIsEditing(false);
  };

  const [localImage, setLocalImage] = useState<string | null>(broadcast.imageUrl || "");

  return (
    <form className={styles["broadcast-card"]} onSubmit={handleSubmit(onSubmit)}>
      <div className={classNames(styles["post-card-image"], styles["editing"])}>
        {localImage ? (
          <>
            <img
              src={localImage}
              alt="profile"
              className={styles["user-profile-image"]}
              width={50}
              height={50}
            />

            {isEditing && (
              <i
                className="pi pi-trash"
                onClick={() => {
                  setLocalImage(null);
                  setValue("file", null, { shouldValidate: true });
                }}
              />
            )}
          </>
        ) : (
          <div className={styles["user-profile-image"]}></div>
        )}
      </div>
      <InputText
        maxLength={700}
        placeholder="Название трансляции"
        className="p-inputtext-light-bg"
        disabled={!isEditing}
        required
        {...register("name", { required: true })}
      />

      <Controller
        name="startAt"
        control={control}
        render={({ field }) => (
          <PopupDateTimeCalendar
            placeholder="Дата"
            showTime
            inputClassName={classNames(styles["date"], "p-inputtext-light-bg")}
            disabled={!isEditing}
            date={field.value}
            setDate={(date) => {
              field.onChange(date);
              handleSubmit(onSubmit);
            }}
          />
        )}
      />

      <div className={styles["button-wrapper"]}>
        {isEditing ? (
          <Button
            icon="pi pi-save"
            className="p-button p-component p-button-rounded p-button-icon-only"
            type="submit"
            disabled={isDirty && !isValid}
          />
        ) : (
          <Button
            icon="pi pi-pencil"
            className="p-button p-component p-button-rounded p-button-icon-only"
            type="button"
            onClick={(event) => {
              event.preventDefault();
              setIsEditing(true);
            }}
          />
        )}
        <Button
          onClick={(event) => {
            event.preventDefault();
            onDelete(id);
          }}
          className="p-button-rounded p-button-secondary"
          icon="pi pi-trash"
          type="button"
        />
      </div>

      <InputText
        maxLength={1300}
        required
        {...register("description", { required: true })}
        className={classNames(styles["description"], "p-inputtext-light-bg")}
        placeholder="Описание трансляции"
        disabled={!isEditing}
      />
      <InputText
        maxLength={700}
        required
        {...register("link", { required: true })}
        className={classNames(styles["link"], "p-inputtext-light-bg")}
        placeholder="Ссылка"
        disabled={!isEditing}
      />
      <div className={styles["checkbox-wrapper"]}>
        <label htmlFor={`isPublic-${broadcast.id}`}>Общая трансляция</label>
        <Controller
          name="isPublic"
          control={control}
          render={({ field }) => (
            <Checkbox
              disabled={!isEditing}
              onChange={(event) => field.onChange(event.target.checked)}
              checked={field.value}
              inputId={`isPublic-${broadcast.id}`}
            />
          )}
        />
      </div>
      <div style={{ display: isEditing ? "block" : "none" }} className={styles["image-uploader"]}>
        <Controller
          name="file"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SingleFileUpload
              file={field.value as File}
              setFile={(file) => {
                setLocalImage(file ? URL.createObjectURL(file) : null);
                field.onChange(file);
              }}
            />
          )}
        />
      </div>
    </form>
  );
};

export default BroadcastCard;
