import { FC } from "react";
import { TUser } from "typings/user";
import { gender } from "constants/gender";
import { Dialog } from "primereact/dialog";

import styles from "./UserOnboardingPopup.module.scss";

type UserOnboardingPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedUser: TUser | null;
};

const UserOnboardingPopup: FC<UserOnboardingPopupProps> = (props) => {
  const closePopup = () => {
    props.handleHide();
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <button className={styles["close-button"]} onClick={closePopup}>
          <i className="pi pi-times" />
        </button>
        <p className={styles["title"]}>Данные онбординга</p>

        <div className={styles["info-row"]}>
          <span>Возраст:</span>
          <p>{props.selectedUser?.onboarding?.age || "-"}</p>
        </div>
        <div className={styles["info-row"]}>
          <span>Пол:</span>
          <p>{gender[props.selectedUser?.onboarding?.gender || ""] || "-"}</p>
        </div>
        <div className={styles["info-row"]}>
          <span>Город:</span>
          <p>{props.selectedUser?.onboarding?.city || "-"}</p>
        </div>
        <div className={styles["info-row"]}>
          <span>Сфера деятельности:</span>
          <p>{props.selectedUser?.onboarding?.fieldActivity || "-"}</p>
        </div>
        <div className={styles["info-row"]}>
          <span>Доход:</span>
          <p>{props.selectedUser?.onboarding?.target || "-"}</p>
        </div>
        <div className={styles["info-row"]}>
          <span>Что бы вы хотели получить на платформе?</span>
          <p>{props.selectedUser?.onboarding?.profit || "-"}</p>
        </div>
        <div className={styles["info-row"]}>
          <span>Что вам больше интересно?</span>
          <p>{props.selectedUser?.onboarding?.interest || "-"}</p>
        </div>
      </div>
    </Dialog>
  );
};

export default UserOnboardingPopup;
