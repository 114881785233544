import { phrases } from "constants/phrases";
import { TSubsription } from "typings/subscription";
import { DEFAULT_PARAMS } from "constants/table-params";
import { useAppDispatch } from "utils/hooks";
import { addErrorMessage } from "slices/toastSlice";
import { tableControlsType } from "typings/table-controls";
import { getSubscriptionsListAsync } from "api/subscriptions/getSubscriptionsList";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import styles from "./Subscriptions.module.scss";
import SubscriptionsLayout from "./Subscriptions.layout";
import { getPaymentsCSV } from "api/subscriptions/getPaymentsCSV";

type SubscriptionsProps = {};

const Subscriptions: FC<SubscriptionsProps> = () => {
  const [subscriptionsList, setSubscriptionsList] = useState<TSubsription[]>([]);
  const [params, setParams] = useState<tableControlsType>(DEFAULT_PARAMS);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [status, setStatus] = useState<string>("ALL");

  const dispatch = useAppDispatch();

  const changeStatus = (status: string) => setStatus(status);

  const getSubscriptionsList = useCallback(
    async (params) => {
      setIsLoadingTable(true);
      try {
        const response = await getSubscriptionsListAsync(params);
        if (response.status === 200 || response.status === 201) {
          setSubscriptionsList(response.data.data);
          setTotalRecords(response.data.pagination.totalItems);
          setIsLoadingTable(false);
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      }
    },
    [dispatch, status]
  );

  const getSubscriptionStatus = (status) => {
    switch (status) {
      case "ALL":
        return undefined;
      case "confirmed":
        return "CONFIRMED";
      case "rejected":
        return "REJECTED";
      case "new":
        return "NEW";
      case "repeat_new":
        return "NEW";
      case "repeat_confirmed":
        return "CONFIRMED";
      case "repeat_rejected":
        return "REJECTED";
      case "refunded":
        return "REFUNDED";
      default:
        return undefined;
    }
  };

  const formatParams = useMemo(
    () => ({
      search: params.search || undefined,
      status: getSubscriptionStatus(status),
      isAutoPayment:
        status === "ALL"
          ? undefined
          : status === "repeat_confirmed" || status === "repeat_new" || status === "repeat_failed"
          ? true
          : false,
    }),
    [params.search, status]
  );

  useEffect(() => {
    getSubscriptionsList({
      page: params.page,
      limit: params.rows,
      ...formatParams,
    });
  }, [params, getSubscriptionsList, status, formatParams]);

  const downloadSubscriptions = () => {
    getPaymentsCSV(formatParams);
  };

  return (
    <div className={styles["page-container"]}>
      <SubscriptionsLayout
        subscriptionsList={subscriptionsList}
        params={params}
        isLoadingTable={isLoadingTable}
        totalRecords={totalRecords}
        setParams={setParams}
        status={status}
        changeStatus={changeStatus}
        downloadSubscriptions={downloadSubscriptions}
      />
    </div>
  );
};

export default Subscriptions;
