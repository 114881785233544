import { getMyInfoAsync } from "api/admins/getMyInfo";
import { phrases } from "constants/phrases";
import { addErrorMessage } from "slices/toastSlice";
import { store } from "store";

const { dispatch } = store;

const authLoader = async () => {
  try {
    const res = await getMyInfoAsync();
    return res.data;
  } catch (err: any) {
    dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
  }
};

export default authLoader;
