import { classNames } from "primereact/utils";
import styles from "./AddedImage.module.scss";

const AddedImage = ({ name = "Примерный файл.jpg", deleteFile = () => {} }) => {
  return (
    <div className={styles["added-image"]}>
      <i className={classNames("pi pi-download", styles["download-icon"])} />
      <p>{name}</p>
      <i className={classNames("pi pi-trash", styles["delete-icon"])} onClick={deleteFile} />
    </div>
  );
};

export default AddedImage;
