import { Card } from "primereact/card";
import styles from "./EarningsInformation.module.scss";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { useProfileData } from "../useProfileData";
import { formatMoney } from "utils/numberFormat";
import { editAdminView } from "api/admins/editAdminView";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { phrases } from "constants/phrases";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { useGetRoles } from "utils/useGetRoles";
import { classNames } from "primereact/utils";
import { USER_PLATFORM_URL } from "constants/config";
import { Checkbox } from "primereact/checkbox";

const EarningsInformation = () => {
  const { balanceStatistics, profile } = useProfileData();
  const [isLoading, setIsLoading] = useState(false);
  const [isActiveRef, setIsActiveRef] = useState(profile?.isActiveRef);
  const [isSecondLevelRef, setIsSecondLevelRef] = useState(profile?.isSecondLevelRef);

  const { isAdmin } = useGetRoles();

  const dispatch = useDispatch();

  const link = `${USER_PLATFORM_URL}/?r=${profile?.refHash}`;

  const changeLinkActive = async () => {
    if (!isAdmin) {
      navigator.clipboard.writeText(link);
      return dispatch(addSuccessMessage(phrases.link_success_copy));
    }
    setIsLoading(true);
    setIsActiveRef(!isActiveRef);
    try {
      await editAdminView(profile.id, {
        isActiveRef: !isActiveRef,
      });
      dispatch(
        addSuccessMessage(
          isActiveRef ? phrases.link_success_disabled : phrases.link_success_enabled
        )
      );
    } catch (error: any) {
      dispatch(addErrorMessage(error?.response?.data?.message || phrases.smthWentWrongText));
      setIsActiveRef(isActiveRef);
    }
    setIsLoading(false);
  };

  const onSelcondLevelRefChange = async () => {
    setIsSecondLevelRef(!isSecondLevelRef);
    try {
      await editAdminView(profile.id, {
        isSecondLevelRef: !isSecondLevelRef,
      });
      dispatch(
        addSuccessMessage(
          isActiveRef ? phrases.link_success_disabled : phrases.link_success_enabled
        )
      );
    } catch (error: any) {
      dispatch(addErrorMessage(error?.response?.data?.message || phrases.smthWentWrongText));
      setIsActiveRef(isActiveRef);
    }
  };

  const labelLinkButton = !isAdmin
    ? "Скопировать ссылку"
    : isActiveRef
    ? "Выключить ссылку"
    : "Включить ссылку";

  return (
    <Card className={styles["card"]}>
      <h5 className={styles["title"]}>Информация о заработке</h5>
      <div className={styles["info-bock"]}>
        <div className={styles["info"]}>
          <InputText
            disabled
            value={`+${formatMoney(balanceStatistics?.sumBalanceAllDays ?? 0)}`}
            className="p-input-display-value p-inputtext-light-bg w-full"
          />
          <Link to={isAdmin ? "earnings" : "/earnings"}>
            <Button
              label="Подробнее"
              icon="pi pi-angle-right"
              iconPos="right"
              className="p-button-sm w-full"
            />
          </Link>
        </div>
        <div className={styles["info"]}>
          <InputText
            disabled
            value={isActiveRef ? link : "..."}
            className="p-input-display-value p-inputtext-light-bg w-full"
          />
          <Button
            label={labelLinkButton}
            className={classNames(styles["link-btn"], "p-button-sm w-full")}
            onClick={changeLinkActive}
            loading={isLoading}
            disabled={!isAdmin && !isActiveRef}
          />
          {isActiveRef && (
            <div className={styles["second-level-ref-wrapper"]}>
              <Checkbox
                onChange={onSelcondLevelRefChange}
                checked={isSecondLevelRef}
                inputId="isSecondLevelRef"
              />
              <label htmlFor="isSecondLevelRef">Включить второй уровень</label>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default EarningsInformation;
