import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { FC, useState } from "react";

import Tags from "components/common/tags/Tags";
import styled from "./Promo.module.scss";
import PopularQuestions from "./PopularQuestions";

type PromoLayoutProps = {};

const PromoLayout: FC<PromoLayoutProps> = () => {
  const [search, setSearch] = useState<string>("");

  return (
    <div className={styled["content-container"]}>
      <div className={styled["card-content"]}>
        <span
          className={classNames("p-input-icon-left p-main-input", styled["search-input-wrapper"])}
        >
          <i className="pi pi-search" />
          <InputText
            maxLength={700}
            value={search}
            type="text"
            placeholder="Поиск"
            onChange={(e) => setSearch(e.target.value)}
            className={styled["search-input"]}
          />
        </span>

        <PopularQuestions search={search} />
      </div>

      <Tags type="all" />
    </div>
  );
};

export default PromoLayout;
