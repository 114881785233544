import { Link } from "react-router-dom";
import styles from "./error.module.scss";
import { Button } from "primereact/button";

const ProfilePageError = () => {
  return (
    <div className={styles["error-page"]}>
      <h2>Профиль не найден</h2>
      <Link to={`..`}>
        <Button>Вернуться к списку администраторов</Button>
      </Link>
    </div>
  );
};

export default ProfilePageError;
