import { FC } from "react";
import { TUser } from "typings/user";
import { tableControlsType } from "typings/table-controls";

import styled from "./Users.module.scss";
import UsersTable from "./table/UsersTable";
import HeaderLayout from "./HeaderLayout";

type UsersLayoutProps = {
  params: tableControlsType;
  usersList: TUser[];
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  totalRecords: number;
  isLoadingTable: boolean;
  handleOpenAddUserPopup: () => void;
  handleOpenRemoveUserPopup: () => void;
  changeSelectedUser: (data: TUser) => void;
  handleOpenEditUserPopup: () => void;
  handleOpenBlockUserPopup: () => void;
  status: string;
  changeStatus: (status: string) => void;
  handleOpenUnblockUserPopup: () => void;
  handleOpenCustomSubscriptionPopup: () => void;
  handleOpenEditUserRefLinkPopup: () => void;
  handleOpenEditUserPasswordPopup: () => void;
  handleOpenUserOnboardingPopup: () => void;
  handleOpenRefferalStatisticsPopup: () => void;
  downloadUsers: () => void;
};

const UsersLayout: FC<UsersLayoutProps> = (props) => {
  return (
    <div className={styled["content-card"]}>
      <HeaderLayout
        handleOpenAddUserPopup={props.handleOpenAddUserPopup}
        status={props.status}
        changeStatus={props.changeStatus}
        downloadUsers={props.downloadUsers}
      />
      <UsersTable
        handleOpenRefferalStatisticsPopup={props.handleOpenRefferalStatisticsPopup}
        params={props.params}
        setParams={props.setParams}
        usersList={props.usersList}
        totalRecords={props.totalRecords}
        isLoadingTable={props.isLoadingTable}
        handleOpenRemoveUserPopup={props.handleOpenRemoveUserPopup}
        changeSelectedUser={props.changeSelectedUser}
        handleOpenEditUserPopup={props.handleOpenEditUserPopup}
        handleOpenBlockUserPopup={props.handleOpenBlockUserPopup}
        handleOpenUnblockUserPopup={props.handleOpenUnblockUserPopup}
        handleOpenCustomSubscriptionPopup={props.handleOpenCustomSubscriptionPopup}
        handleOpenEditUserRefLinkPopup={props.handleOpenEditUserRefLinkPopup}
        handleOpenEditUserPasswordPopup={props.handleOpenEditUserPasswordPopup}
        handleOpenUserOnboardingPopup={props.handleOpenUserOnboardingPopup}
      />
    </div>
  );
};

export default UsersLayout;
