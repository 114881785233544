export const PLATFORMS = {
  tinkoff: "ТИНЬКОФФ",
  yookassa: "ЮKassa",
};

export const SUBSCRIPTIONS_STATUSES = {
  repeat_new: "Попытка автосписания",
  repeat_confirmed: "Успешное автосписание",
  repeat_rejected: "Ошибка автосписания",
  repeat_refunded: "Автосписание возвращёно",
  new: "Попытка купить",
  confirmed: "Успешная покупка",
  rejected: "Ошибка покупки",
  refunded: "Платёж возвращён",
};

export const SUBSCRIPTIONS_STATUSES_ARRAY = [
  {
    label: "Успешная покупка",
    value: "confirmed",
  },
  {
    label: "Ошибка покупки",
    value: "rejected",
  },
  {
    label: "Попытка купить",
    value: "new",
  },
  {
    label: "Попытка повторного списания",
    value: "repeat_new",
  },
  {
    label: "Успешное повторное списание",
    value: "repeat_confirmed",
  },
  {
    label: "Ошибка повторного списания",
    value: "repeat_rejected",
  },
  {
    label: "Платёж возвращён",
    value: "refunded ",
  },
  {
    label: "Повторный платёж возвращён",
    value: "repeat_refunded",
  },
  {
    label: "Все",
    value: "ALL",
  },
];
