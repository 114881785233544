import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { phrases } from "constants/phrases";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { FileUpload } from "primereact/fileupload";
import { uploadFile } from "api/storage/uploadFile";
import { useGetRoles } from "utils/useGetRoles";
import { useAppDispatch } from "utils/hooks";
import { addErrorMessage } from "slices/toastSlice";
import { COURSE_MENU_LIST } from "constants/course";
import { TCourseInfo, TUpdateCourseData } from "typings/course";
import { FC, useEffect, useRef, useState } from "react";

import styled from "./Course.module.scss";
import PromoTab from "./tab/promo/PromoTab";
import ContentTab from "./tab/content/ContentTab";
import ReviewsTab from "./tab/reviews/ReviewsTab";
import EmptyImage from "components/common/empty-image/EmptyImage";
import ReturnedMateriulWarning from "./ReturnedMateriulWarning";

type CourseLayoutProps = {
  activeTab: string;
  changeTab: (tab: string) => void;
  handleOpenDeactivateCoursePopup: () => void;
  handleCloseDeactivateCoursePopup: () => void;
  isOpenDeactivateCoursePopup: boolean;
  isOpenPublishCoursePopup: boolean;
  handleOpenPublishCoursePopup: () => void;
  handleClosePublishCoursePopup: () => void;
  isOpenReturnForRevisionCoursePopup: boolean;
  handleOpenReturnForRevisionCoursePopup: () => void;
  handleCloseReturnForRevisionCoursePopup: () => void;
  isOpenRemoveCoursePopup: boolean;
  handleOpenRemoveCoursePopup: () => void;
  handleCloseRemoveCoursePopup: () => void;
  isOpenMovingLessonsCoursePopup: boolean;
  handleOpenMovingLessonsPopup: () => void;
  handleCloseMovingLessonsPopup: () => void;
  isOpenMovingModulesPopup: boolean;
  handleOpenMovingModulesPopup: () => void;
  handleCloseMovingModulesPopup: () => void;
  courseInfo: TCourseInfo;
  updateCourse: (courseData: TUpdateCourseData) => void;
  changeCourseStatus: (status: number) => void;
};

const Tabs = (props: CourseLayoutProps) => {
  switch (props.activeTab) {
    case COURSE_MENU_LIST.promo:
      return (
        <PromoTab
          courseInfo={props.courseInfo}
          updateCourse={props.updateCourse}
          changeCourseStatus={props.changeCourseStatus}
        />
      );
    case COURSE_MENU_LIST.content:
      return (
        <ContentTab
          handleCloseDeactivateCoursePopup={props.handleCloseDeactivateCoursePopup}
          isOpenDeactivateCoursePopup={props.isOpenDeactivateCoursePopup}
          isOpenPublishCoursePopup={props.isOpenPublishCoursePopup}
          handleClosePublishCoursePopup={props.handleClosePublishCoursePopup}
          isOpenReturnForRevisionCoursePopup={props.isOpenReturnForRevisionCoursePopup}
          handleCloseReturnForRevisionCoursePopup={props.handleCloseReturnForRevisionCoursePopup}
          handleCloseRemoveCoursePopup={props.handleCloseRemoveCoursePopup}
          isOpenRemoveCoursePopup={props.isOpenRemoveCoursePopup}
          isOpenMovingLessonsCoursePopup={props.isOpenMovingLessonsCoursePopup}
          handleCloseMovingLessonsPopup={props.handleCloseMovingLessonsPopup}
          isOpenMovingModulesPopup={props.isOpenMovingModulesPopup}
          handleCloseMovingModulesPopup={props.handleCloseMovingModulesPopup}
          handleOpenMovingLessonsPopup={props.handleOpenMovingLessonsPopup}
          courseInfo={props.courseInfo}
        />
      );
    case COURSE_MENU_LIST.reviews:
      return <ReviewsTab courseInfo={props.courseInfo} updateCourse={props.updateCourse} />;
    default:
      return (
        <PromoTab
          courseInfo={props.courseInfo}
          updateCourse={props.updateCourse}
          changeCourseStatus={props.changeCourseStatus}
        />
      );
  }
};

const CourseLayout: FC<CourseLayoutProps> = (props) => {
  const [isOpenEditCourseTitle, setIsOpenEditCourseTitle] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");

  const { isAdmin, isSpeacker, isCurator } = useGetRoles();

  const menuLeft = useRef(null);
  const fileUploadReference = useRef(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.courseInfo) setCourseTitle(props.courseInfo.name);
  }, [props.courseInfo]);

  const ADMIN_ACTION_PAGE_BTN = [
    {
      label: "Изменить порядок модулей",
      icon: "pi pi-sort-alt",
      command: props.handleOpenMovingModulesPopup,
    },
    {
      label: "Вернуть на доработку",
      icon: "pi pi-undo",
      command: props.handleOpenReturnForRevisionCoursePopup,
    },
    {
      label: "Деактивировать",
      icon: "pi pi-ban",
      command: props.handleOpenDeactivateCoursePopup,
    },
    {
      label: "Опубликовать",
      icon: "pi pi-send",
      command: props.handleOpenPublishCoursePopup,
    },
    {
      label: "Удалить курс",
      icon: "pi pi-trash",
      command: props.handleOpenRemoveCoursePopup,
    },
  ];

  const SPEAKER_ACTION_PAGE_BTN = [
    {
      label: "Изменить порядок модулей",
      icon: "pi pi-sort-alt",
      command: props.handleOpenMovingModulesPopup,
    },
    {
      label: "Удалить курс",
      icon: "pi pi-trash",
      command: props.handleOpenRemoveCoursePopup,
    },
  ];

  const renameCourse = () => {
    if (isOpenEditCourseTitle && props.courseInfo.name !== courseTitle) {
      props.updateCourse({ name: courseTitle });
    }
    setIsOpenEditCourseTitle(!isOpenEditCourseTitle);
  };

  const deleteCourseImage = () => {
    props.updateCourse({ imageUrl: null });
  };

  const changeCourseImage = async (event) => {
    try {
      const file = event.files[0];

      if (file) {
        const fileFormData = new FormData();
        fileFormData.append("file", file);
        const {
          data: { url },
        } = await uploadFile(fileFormData);

        props.updateCourse({ imageUrl: url });
      }
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    } finally {
      //@ts-ignore
      fileUploadReference?.current?.clear();
    }
  };

  return (
    <div className={styled["content"]}>
      <div className={styled["image-wrapper"]}>
        <div className={styled["image-container"]}>
          {props.courseInfo.imageUrl ? (
            <img alt="course thumbnail" src={props.courseInfo.imageUrl} />
          ) : (
            <EmptyImage />
          )}
        </div>
        {!isCurator && (
          <div className={styled["image-buttons-settings-wrapper"]}>
            <button
              className={styled["image-settings-button"]}
              onClick={deleteCourseImage}
              disabled={!isAdmin && !isSpeacker}
            >
              Удалить
            </button>
            <p>/</p>
            <FileUpload
              ref={fileUploadReference}
              mode="basic"
              accept="image/*"
              maxFileSize={1000000}
              customUpload
              uploadHandler={changeCourseImage}
              chooseLabel="Изменить"
              auto
              className={styled["image-uploader"]}
              disabled={!isAdmin && !isSpeacker}
            />
          </div>
        )}
      </div>
      <div className={styled["card-content"]}>
        <div className={styled["card-content-header"]}>
          {isOpenEditCourseTitle ? (
            <InputText
              maxLength={700}
              value={courseTitle}
              onChange={(e) => setCourseTitle(e.target.value)}
              placeholder="Имя"
              className={styled["input-course-title"]}
              disabled={!isOpenEditCourseTitle}
            />
          ) : (
            <p className={styled["input-title"]}>{props.courseInfo?.name}</p>
          )}

          {!isCurator && (
            <button className={styled["edit-course-title-button"]} onClick={renameCourse}>
              {!isOpenEditCourseTitle ? (
                <i className="pi pi-pencil" />
              ) : (
                <i className="pi pi-check" />
              )}
            </button>
          )}
        </div>

        {props.courseInfo?.comment && (
          <ReturnedMateriulWarning comment={props.courseInfo?.comment} />
        )}

        <div className={styled["card-content-menu"]}>
          <div className={styled["menu-group"]}>
            <span
              className={classNames(
                styled["card-content-menu-item"],
                props.activeTab === COURSE_MENU_LIST.promo &&
                  styled["card-content-menu-item-active"]
              )}
              onClick={() => props.changeTab(COURSE_MENU_LIST.promo)}
            >
              Промо
            </span>
            <span
              className={classNames(
                styled["card-content-menu-item"],
                props.activeTab === COURSE_MENU_LIST.content &&
                  styled["card-content-menu-item-active"]
              )}
              onClick={() => props.changeTab(COURSE_MENU_LIST.content)}
            >
              Содержание
            </span>
            <span
              className={classNames(
                styled["card-content-menu-item"],
                props.activeTab === COURSE_MENU_LIST.reviews &&
                  styled["card-content-menu-item-active"]
              )}
              onClick={() => props.changeTab(COURSE_MENU_LIST.reviews)}
            >
              Отзывы
            </span>
          </div>
          {props.activeTab === COURSE_MENU_LIST.content && !isCurator && (
            <div className={styled["main-wrapper-header-actions"]}>
              <Menu
                model={isAdmin ? ADMIN_ACTION_PAGE_BTN : isSpeacker ? SPEAKER_ACTION_PAGE_BTN : []}
                popup
                ref={menuLeft}
                id="popup_menu_left"
              />
              <Button
                label="Действия"
                icon="pi pi-plus"
                //@ts-ignore
                onClick={(event) => menuLeft.current.toggle(event)}
                aria-controls="popup_menu_left"
                aria-haspopup
                className="p-button-sm"
              />
            </div>
          )}
        </div>
        <Tabs {...props} />
      </div>
    </div>
  );
};

export default CourseLayout;
