import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TNewAdmin } from "typings/admin";
import { ADMIN_ROLES } from "constants/admins";
import { FC, useState } from "react";

import styles from "./AddAdminPopup.module.scss";
import { Checkbox } from "primereact/checkbox";

type AddAdminPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  createAdmin: (adminData: TNewAdmin) => void;
};

const AddAdminPopup: FC<AddAdminPopupProps> = (props) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>(ADMIN_ROLES[0].value);
  const [isGuest, setIsGuest] = useState<boolean>(false);

  const closePopup = () => {
    props.handleHide();
    setEmail("");
    setLastName("");
    setFirstName("");
    setRole(ADMIN_ROLES[0].value);
    setIsGuest(false);
  };

  const createNewAdmin = (event) => {
    event.preventDefault();
    props.createAdmin({
      email,
      firstName,
      lastName,
      role,
      isGuest: role === "speaker" ? isGuest : undefined,
    });
    closePopup();
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <form className={styles["content"]} onSubmit={createNewAdmin}>
        <p className={styles["title"]}>Добавление нового администратора</p>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Имя</p>
          <InputText
            maxLength={600}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Имя"
            className={styles["input"]}
          />
        </div>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Фамилия</p>
          <InputText
            maxLength={600}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Фамилия"
            className={styles["input"]}
          />
        </div>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Email</p>
          <InputText
            type="email"
            name="email"
            maxLength={256}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className={styles["input"]}
          />
        </div>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Выберите роль</p>
          <Dropdown
            options={ADMIN_ROLES}
            onChange={(e) => setRole(e.value)}
            value={role}
            placeholder="Выберите роль"
            className={styles["dropdown"]}
          />
        </div>
        {role === "speaker" && (
          <div className={styles["checkbox-wrapper"]}>
            <p className={styles["input-label"]}>Гость</p>
            <Checkbox onChange={(e) => setIsGuest(e.checked)} checked={isGuest} inputId="isGuest" />
          </div>
        )}
        <div className={styles["buttons-container"]}>
          <Button type="button" className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button
            type="submit"
            className={styles["add-button"]}
            disabled={!firstName || !lastName || !email}
          >
            Добавить
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default AddAdminPopup;
