import { FileUpload, FileUploadProps } from "primereact/fileupload";
import AddedImage from "../added-image/AddedImage";

import styles from "./SingleFileUpload.module.scss";
import { useEffect, useRef } from "react";

type SingleFileUploadProps = {
  file: File | null;
  setFile: (file: File | null) => void;
};

type SingleFileUploadPropsWIthFileUpload = SingleFileUploadProps & FileUploadProps;

const Header = ({ options, isAdded }) => {
  if (isAdded) return null;
  return <div>{options.chooseButton}</div>;
};

const SingleFileUpload = ({ file, setFile, ...props }: SingleFileUploadPropsWIthFileUpload) => {
  const uploaderRef = useRef<FileUpload>(null);
  const itemTemplate = (file, props) => {
    return <AddedImage name={file.name} deleteFile={props.onRemove} />;
  };

  const customSelect = async () => {
    setTimeout(() => uploaderRef.current?.upload(), 0);
  };

  const customUploader = async (event) => {
    const file = event.files[0];
    setFile(file);
  };

  useEffect(() => {
    if (!file) {
      uploaderRef.current?.clear();
    }
  }, [file]);

  return (
    <FileUpload
      id="file-upload"
      name="file-upload"
      ref={uploaderRef}
      accept="image/*"
      maxFileSize={1000000}
      headerTemplate={(options) => <Header options={options} isAdded={file} />}
      itemTemplate={itemTemplate}
      onSelect={customSelect}
      customUpload
      uploadHandler={customUploader}
      onRemove={() => setFile(null)}
      progressBarTemplate={() => null}
      onError={() => {
        setTimeout(() => {
          setFile(null);
        }, 0);
      }}
      onValidationFail={() => {
        setTimeout(() => {
          setFile(null);
        }, 0);
      }}
      contentClassName={styles["file-upload-content"]}
      chooseLabel="Выберите изображение"
      chooseOptions={{ className: "p-button-sm" }}
      className={styles["file-upload"]}
      {...props}
    />
  );
};

export default SingleFileUpload;
