import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { TNewUser } from "typings/user";
import { InputText } from "primereact/inputtext";
import { FC, useState } from "react";

import styles from "./AddUserPopup.module.scss";

type AddUserPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  createUser: (userData: TNewUser) => void;
};

const AddUserPopup: FC<AddUserPopupProps> = (props) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const closePopup = () => {
    props.handleHide();
    setEmail("");
    setFirstName("");
    setLastName("");
  };

  const addUser = (event) => {
    event.preventDefault();
    props.createUser({
      firstName,
      lastName,
      email,
    });
    closePopup();
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <form className={styles["content"]} onSubmit={addUser}>
        <p className={styles["title"]}>Добавление нового пользователя</p>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Имя</p>
          <InputText
            maxLength={600}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Имя"
            className={styles["input"]}
          />
        </div>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Фамилия</p>
          <InputText
            maxLength={600}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Фамилия"
            className={styles["input"]}
          />
        </div>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Email</p>
          <InputText
            type="email"
            name="email"
            maxLength={256}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className={styles["input"]}
          />
        </div>
        <div className={styles["buttons-container"]}>
          <Button type="button" className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button
            type="submit"
            className={styles["add-button"]}
            disabled={!firstName || !lastName || !email}
          >
            Добавить
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default AddUserPopup;
