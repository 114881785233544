import { FC } from "react";

import styled from "./Course.module.scss";

type ReturnedMateriulWarningProps = {
    comment: string;
};

const ReturnedMateriulWarning: FC<ReturnedMateriulWarningProps> = (props) => {
    return (
        <div className={styled["returned-materiul-container"]}>
            <p className={styled["returned-materiul-container-title"]}>Материал возвращен на доработку</p>
            <p className={styled["returned-materiul-container-text"]}>
                <b>Комментарий администратора:</b>
            </p>
            <p className={styled["returned-materiul-container-text"]}>{props.comment}</p>
        </div>
    );
};

export default ReturnedMateriulWarning;
