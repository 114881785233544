import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import styles from "./BroadcastDeletePopup.module.scss";
import { TBroadcast } from "typings/broadcast";

type BroadcastDeletePopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  broadcast: TBroadcast | null;
  deleteBroadcast: (postId: number) => void;
};

const BroadcastDeletePopup: FC<BroadcastDeletePopupProps> = ({
  broadcast,
  deleteBroadcast,
  handleHide,
  isOpen,
}) => {
  const closePopup = () => handleHide();

  return (
    <Dialog
      visible={isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <i className={classNames("pi pi-exclamation-circle", styles["warning-icon"])} />
        <p className={styles["title"]}>Вы уверены, что хотите удалить трансляцию?</p>
        <p className={styles["text"]}>{broadcast?.name || ""}</p>
        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button
            className={styles["remove-button"]}
            onClick={() => {
              if (broadcast) deleteBroadcast(broadcast.id);
            }}
          >
            Удалить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default BroadcastDeletePopup;
