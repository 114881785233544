import { phrases } from "constants/phrases";
import { useAppDispatch } from "utils/hooks";
import { getDirectionsAsync } from "api/directions/getDirections";
import { createDirectionAsync } from "api/directions/createDirection";
import { removeDirectionAsync } from "api/directions/removeDirection";
import { updateDirectionAsync } from "api/directions/editDirection";
import { TDirection, TNewDirection } from "typings/direction";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, useCallback, useEffect, useState } from "react";

import styled from "./Directions.module.scss";
import DicrectionsLayout from "./Directions.layout";
import AddDirectionPopup from "popups/add-direction/AddDirectionPopup";
import EditDirectionPopup from "popups/edit-direction/EditDirectionPopup";
import RemoveDirectionPopup from "popups/remove-direction/RemoveDirectionPopup";

type DirectionsProps = {};

const Directions: FC<DirectionsProps> = () => {
    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
    const [directionsList, setDirectionsList] = useState<TDirection[]>([]);
    const [selectedDirection, setSelectedDirection] = useState<TDirection | null>(null);
    const [isOpenAddDirectionPopup, setIsOpenAddDirectionPopup] = useState<boolean>(false);
    const [isOpenEditDirectionPopup, setIsOpenEditDirectionPopup] = useState<boolean>(false);
    const [isOpenRemoveDirectionPopup, setIsOpenRemoveDirectionPopup] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const changeSelectedDirection = (direction: TDirection) => setSelectedDirection(direction);

    const handleOpenAddDirectionPopup = () => setIsOpenAddDirectionPopup(true);
    const handleCloseAddDirectionPopup = () => setIsOpenAddDirectionPopup(false);

    const handleOpenEditDirectionPopup = () => setIsOpenEditDirectionPopup(true);
    const handleCloseEditDirectionPopup = () => setIsOpenEditDirectionPopup(false);

    const handleOpenRemoveDirectionPopup = () => setIsOpenRemoveDirectionPopup(true);
    const handleCloseRemoveDirectionPopup = () => setIsOpenRemoveDirectionPopup(false);

    const getDirectionsList = useCallback(async () => {
        setIsLoadingTable(true);
        try {
            const response = await getDirectionsAsync();
            if (response.status === 200 || response.status === 201) {
                setDirectionsList(response.data);
                setIsLoadingTable(false);
            } else throw new Error();
        } catch (err: any) {
            dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
        }
    }, [dispatch]);

    useEffect(() => {
        getDirectionsList();
    }, [getDirectionsList]);

    const createDirection = useCallback(
        async (directionData: TNewDirection) => {
            setIsLoadingTable(true);
            try {
                const response = await createDirectionAsync(directionData);
                if (response.status === 200 || response.status === 201) {
                    getDirectionsList();
                    dispatch(addSuccessMessage(phrases.direction_success_added));
                } else throw new Error();
            } catch (err: any) {
                dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
            } finally {
                handleCloseAddDirectionPopup();
                setIsLoadingTable(false);
            }
        },
        [dispatch]
    );

    const deleteDirection = useCallback(async () => {
        setIsLoadingTable(true);
        try {
            if (selectedDirection) {
                const response = await removeDirectionAsync(selectedDirection.id);
                if (response.status === 200 || response.status === 201) {
                    dispatch(addSuccessMessage(phrases.direction_sucess_deleted));
                    getDirectionsList();
                } else throw new Error();
            } else throw new Error();
        } catch (err: any) {
            dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
        } finally {
            handleCloseRemoveDirectionPopup();
            setIsLoadingTable(false);
        }
    }, [dispatch, selectedDirection]);

    const updateDirection = useCallback(
        async (directionData: TNewDirection) => {
            setIsLoadingTable(true);
            try {
                if (selectedDirection) {
                    const response = await updateDirectionAsync(selectedDirection.id, directionData);
                    if (response.status === 200 || response.status === 201) {
                        setIsLoadingTable(false);
                        getDirectionsList();
                        dispatch(addSuccessMessage(phrases.direction_success_updated));
                    } else throw new Error();
                } else return;
            } catch (err: any) {
                dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
            } finally {
                handleCloseEditDirectionPopup();
                setIsLoadingTable(false);
            }
        },
        [dispatch, selectedDirection]
    );

    return (
        <div className={styled["page-container"]}>
            <DicrectionsLayout
                isLoadingTable={isLoadingTable}
                directionsList={directionsList}
                changeSelectedDirection={changeSelectedDirection}
                handleOpenAddDirectionPopup={handleOpenAddDirectionPopup}
                handleOpenEditDirectionPopup={handleOpenEditDirectionPopup}
                handleOpenRemoveDirectionPopup={handleOpenRemoveDirectionPopup}
            />

            <AddDirectionPopup
                isOpen={isOpenAddDirectionPopup}
                createDirection={createDirection}
                handleHide={handleCloseAddDirectionPopup}
            />

            <RemoveDirectionPopup
                deleteDirection={deleteDirection}
                isOpen={isOpenRemoveDirectionPopup}
                selectedDirection={selectedDirection}
                handleHide={handleCloseRemoveDirectionPopup}
            />

            <EditDirectionPopup
                isOpen={isOpenEditDirectionPopup}
                updateDirection={updateDirection}
                handleHide={handleCloseEditDirectionPopup}
                selectedDirection={selectedDirection}
            />
        </div>
    );
};

export default Directions;
