import { Dialog } from "primereact/dialog";
import { TUser } from "typings/user";
import styles from "./RefferalStatisticsPopup.module.scss";
import { useEffect, useState } from "react";
import { getBalancesHistory, getBalancesHistoryStatistics } from "api/balances-history";
import { getReferrals } from "api/referrals/getReferrals";
import { useAppDispatch } from "utils/hooks";
import { addErrorMessage } from "slices/toastSlice";
import { phrases } from "constants/phrases";

type RefferalStatisticsPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedUser: TUser | null;
};

export const RefferalStatisticsPopup = (props: RefferalStatisticsPopupProps) => {
  const [data, setData] = useState<{
    sumBalanceLastMonth: number;
    sumBalanceAllDays: number;
    numberInvitedUsers: number;
    numberOfPayments: number;
  }>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        const studentId = props.selectedUser?.id;
        const [stats, list, balanceHistory] = await Promise.all([
          getBalancesHistoryStatistics({ studentId }),
          getReferrals({
            studentId,
          }),
          getBalancesHistory({ studentId }),
        ]);
        setData({
          sumBalanceLastMonth: stats.data.sumBalanceLastMonth,
          sumBalanceAllDays: stats.data.sumBalanceAllDays,
          numberInvitedUsers: list.data.pagination.totalItems,
          numberOfPayments: balanceHistory.data.pagination.totalItems,
        });
      } catch (error: any) {
        dispatch(addErrorMessage(error.response.data.message || phrases.smthWentWrongText));
      }
    };
    if (props.selectedUser) {
      getData();
    }
  }, [dispatch, props.selectedUser]);

  const onClose = () => {
    props.handleHide();
    setData(undefined);
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={onClose}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <button className={styles["close-button"]} onClick={onClose}>
          <i className="pi pi-times" />
        </button>
        <p className={styles["title"]}>Статистика реферальной системы</p>

        <div className={styles["info-row"]}>
          <span>Количество зарегистрированных пользователей:</span>
          <p>{data?.numberInvitedUsers ?? "-"}</p>
        </div>
        <div className={styles["info-row"]}>
          <span>Количество пользователей, купивших подписку:</span>
          <p>{data?.numberOfPayments ?? "-"}</p>
        </div>
        <div className={styles["info-row"]}>
          <span>Заработок за месяц:</span>
          <p>{data?.sumBalanceLastMonth + " ₽" ?? "-"}</p>
        </div>
        <div className={styles["info-row"]}>
          <span>Общий заработок:</span>
          <p>{data?.sumBalanceAllDays + " ₽" ?? "-"}</p>
        </div>
      </div>
    </Dialog>
  );
};
