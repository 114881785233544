import { FC } from "react";
import { TAdmin } from "typings/admin";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import styles from "./RemoveAdminPopup.module.scss";

type RemoveAdminPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedAdmin: {
    firstName: string;
    lastName: string;
  } | null;
  deleteAdmin: () => void;
};

const RemoveAdminPopup: FC<RemoveAdminPopupProps> = (props) => {
  const closePopup = () => props.handleHide();

  const removeAdmin = () => {
    props.deleteAdmin();
    closePopup();
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <i className={classNames("pi pi-exclamation-circle", styles["warning-icon"])} />
        <p className={styles["title"]}>Вы уверены, что хотите удалить следующую запись?</p>
        <p className={styles["text"]}>
          {props.selectedAdmin
            ? props.selectedAdmin.firstName + " " + props.selectedAdmin.lastName
            : "Пользователь"}
        </p>
        <p className={styles["label"]}>Это ограничит доступ данного администратора к сервису.</p>
        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button className={styles["remove-button"]} onClick={removeAdmin}>
            Удалить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default RemoveAdminPopup;
