import { Link, useParams } from "react-router-dom";
import ChangePassword from "../ChangePassword/ChangePassword";
import PersonalData from "../PersonalData/PersonalData";
import styled from "../Profile.module.scss";
import { Button } from "primereact/button";
import RemoveAdmin from "../RemoveAdmin/RemoveAdmin";

const ProfileSettingsPage = () => {
  const { id } = useParams();
  return (
    <div className={styled["additional-information"]}>
      <Link to="../courses">
        <Button className="p-button-sm" icon="pi pi-angle-left" iconPos="left" label="Назад" />
      </Link>
      <PersonalData myProfile={!Boolean(id)} />
      {id ? <RemoveAdmin /> : <ChangePassword />}
    </div>
  );
};

export default ProfileSettingsPage;
