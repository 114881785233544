import { TReferralsTotal, TRefferal } from "typings/refferal";
import { tableControlsType } from "typings/table-controls";

import styles from "./RefferalList.module.scss";
import RefferalListTable from "./table/RefferalListTable";

type SubscriptionsLayoutProps = {
  refferalListsList: TRefferal[];
  monthEnd: Date | null;
  isLoadingTable: boolean;
  setMonthEnd: React.Dispatch<React.SetStateAction<Date | null>>;
  totalRecords: number;
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  params: tableControlsType;
  totals?: TReferralsTotal;
};

const SubscriptionsLayout = (props: SubscriptionsLayoutProps) => {
  return (
    <div className={styles["content-card"]}>
      <RefferalListTable
        totals={props.totals}
        refferalListsList={props.refferalListsList}
        isLoadingTable={props.isLoadingTable}
        totalRecords={props.totalRecords}
        setParams={props.setParams}
        params={props.params}
      />
    </div>
  );
};

export default SubscriptionsLayout;
