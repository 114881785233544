import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import styles from "./OutCourseToTopPopup.module.scss";

type OutCourseToTopPopupProps = {
    isOpen: boolean;
    handleHide: () => void;
    changeCourseViewTop: (value: boolean) => void;
};

const OutCourseToTopPopup: FC<OutCourseToTopPopupProps> = (props) => {
    const closePopup = () => {
        props.handleHide();
    };

    return (
        <Dialog
            visible={props.isOpen}
            modal
            onHide={closePopup}
            className={styles["dialog"]}
            showHeader={false}
        >
            <div className={styles["content"]}>
                <i className={classNames("pi pi-check-circle", styles["icon"])} />
                <p className={styles["title"]}>Вывести курс в ТОП?</p>
                <p className={styles["description"]}>
                    Внимание! Курс заменит на главной странице следующий курс:
                </p>
                <p className={styles["text"]}>“Курс успешного успеха”</p>
                <div className={styles["buttons-container"]}>
                    <Button className={styles["cancel-button"]} onClick={closePopup}>
                        Отмена
                    </Button>
                    <Button className={styles["out-button"]} onClick={() => props.changeCourseViewTop(true)}>
                        Вывести
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default OutCourseToTopPopup;
