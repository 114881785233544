import http from "./https";

type sendNewPasswordProps = {
  newPassword: string;
  oldPassword: string;
};

export const forgotPassword = (email: string) =>
  http.post("api/auth/reset-password?isAdmin=true", { email });

export const setNewPassword = (data: sendNewPasswordProps) =>
  http.post("api/account/password", data);
