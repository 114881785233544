import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useGetRoles } from "utils/useGetRoles";
import { FC, useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DEFAULT_POPULAR_QUESTION } from "constants/course";
import { TCourseInfo, TPopularQuestion, TUpdateCourseData } from "typings/course";

import styled from "./PromoTab.module.scss";

type PopularQuestionsProps = {
  updateCourse: (courseData: TUpdateCourseData) => void;
  courseInfo: TCourseInfo;
};

const PopularQuestions: FC<PopularQuestionsProps> = (props) => {
  const [list, setList] = useState<TPopularQuestion[]>([DEFAULT_POPULAR_QUESTION]);

  const { isAdmin, isSpeacker, isCurator } = useGetRoles();

  const addDirection = () => setList((prev) => [...prev, DEFAULT_POPULAR_QUESTION]);

  const removeDirection = (removeIndex: number) => {
    if (list.length <= 1) setList([DEFAULT_POPULAR_QUESTION]);
    else setList((prev) => prev.filter((_, index) => index !== removeIndex));
  };

  const changeDirection = (updateIndex: number, changeField: string, value: string) => {
    const newArray = list.map((el, index) => {
      if (index === updateIndex) {
        return {
          ...el,
          [changeField]: value,
        };
      } else return el;
    });
    setList(newArray);
  };

  useEffect(() => {
    if (props.courseInfo && props.courseInfo.aboutCourse.faq.length === 0) {
      setList([DEFAULT_POPULAR_QUESTION]);
    } else setList(props.courseInfo.aboutCourse.faq);
  }, [props.courseInfo]);

  const returnDisabledSaveButton = () =>
    JSON.stringify(props.courseInfo.aboutCourse.faq) === JSON.stringify(list) ||
    (list.length < 2 && list[0] === DEFAULT_POPULAR_QUESTION) ||
    !list[list.length - 1].question ||
    !list[list.length - 1].text ||
    list.some((el) => !el.question || !el.text);

  return (
    <div className={styled["accordion-container"]}>
      <Accordion className={styled["accordion"]}>
        <AccordionTab header="Популярные вопросы">
          <div className={styled["accordion-list"]}>
            {list.map((el, index) => (
              <div className={styled["accordion-list-item-mul"]} key={index}>
                <div className={styled["input-group"]}>
                  <InputText
                    maxLength={700}
                    value={el.question}
                    onChange={(e) => changeDirection(index, "question", e.target.value)}
                    placeholder="Текст вопроса"
                    className={styled["accordion-list-item-input"]}
                    disabled={!isAdmin && !isSpeacker}
                  />
                  <InputText
                    maxLength={1300}
                    value={el.text}
                    onChange={(e) => changeDirection(index, "text", e.target.value)}
                    placeholder="Текст ответа"
                    className={styled["accordion-list-item-input"]}
                    disabled={!isAdmin && !isSpeacker}
                  />
                </div>
                {!isCurator && (
                  <Button
                    className="p-button-rounded p-button-secondary p-button-icon-only p-button-sm"
                    onClick={() => removeDirection(index)}
                    disabled={!isAdmin && !isSpeacker}
                    icon="pi pi-trash"
                  />
                )}
              </div>
            ))}
          </div>
          {!isCurator && (
            <div className={styled["button-wrapper"]}>
              <Button onClick={addDirection} className={styled["accordion-list-button"]}>
                Добавить вопрос
              </Button>
              <Button
                onClick={() =>
                  props.updateCourse({
                    aboutCourse: { ...props.courseInfo.aboutCourse, faq: list },
                  })
                }
                className={styled["accordion-list-button"]}
                disabled={returnDisabledSaveButton()}
              >
                Сохранить
              </Button>
            </div>
          )}
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default PopularQuestions;
