import { getCourseInfoAsync } from "api/courses/getCourseInfo";
import { phrases } from "constants/phrases";
import { addErrorMessage } from "slices/toastSlice";
import { store } from "store";
import { TCourseInfo } from "typings/course";

const { dispatch } = store;

const courseLoader = async (id?: string): Promise<TCourseInfo | void> => {
  try {
    if (id) {
      const response = await getCourseInfoAsync(id);
      if (response.status === 200 || response.status === 201) {
        return response.data as TCourseInfo;
      } else throw new Error();
    } else throw new Error();
  } catch (err: any) {
    dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
  }
};

export default courseLoader;
