import { Button } from "primereact/button";
import { DEFAULT_COURSE_REVIEW } from "constants/course";
import { FC, useEffect, useState } from "react";
import { TCourseInfo, TCourseReview, TUpdateCourseData } from "typings/course";

import Review from "./review/Review";
import styles from "./ReviewsTab.module.scss";

type ReviewsTabProps = {
  courseInfo: TCourseInfo;
  updateCourse: (courseData: TUpdateCourseData) => void;
};

const ReviewsTab: FC<ReviewsTabProps> = (props) => {
  const [list, setList] = useState<TCourseReview[]>([DEFAULT_COURSE_REVIEW]);

  const changeReview = (field: string, value: any, index: number) => {
    const newList = list.map((review, indexReview) => {
      if (index === indexReview) {
        return { ...review, [field]: value };
      }
      return review;
    });
    setList(newList);
  };

  useEffect(() => {
    if (props.courseInfo && props.courseInfo.reviews?.length === 0) {
      setList([DEFAULT_COURSE_REVIEW]);
    } else setList(props.courseInfo.reviews);
  }, [props.courseInfo]);

  const addReview = () => setList((prev) => [...prev, DEFAULT_COURSE_REVIEW]);

  const removeReview = (removeIndex: number) => {
    if (list.length <= 1) setList([DEFAULT_COURSE_REVIEW]);
    else setList((prev) => prev.filter((_, index) => index !== removeIndex));
  };

  const saveChanges = () => {
    if (list.length === 1 && list[0] === DEFAULT_COURSE_REVIEW)
      props.updateCourse({
        reviews: [],
      });
    else
      props.updateCourse({
        reviews: list,
      });
  };

  const someNotFilled = () => list.some((el) => !el.age || !el.name || !el.text || !el.date);

  const returnEnabledSaveButton = () =>
    JSON.stringify(props.courseInfo.reviews) !== JSON.stringify(list) &&
    (list.length === 1
      ? (list[0] === DEFAULT_COURSE_REVIEW && props.courseInfo.reviews.length) || !someNotFilled()
      : !someNotFilled());

  const returnDisableAddButton = () =>
    !list[list.length - 1].age ||
    !list[list.length - 1].name ||
    !list[list.length - 1].text ||
    !list[list.length - 1].date;
  return (
    <div className={styles["reviews-tab"]}>
      {list.map((review, index) => (
        <Review
          key={index}
          review={review}
          changeReview={changeReview}
          index={index}
          removeReview={removeReview}
        />
      ))}

      <div className={styles["button-wrapper"]}>
        <Button
          className={styles["list-add-button"]}
          onClick={addReview}
          disabled={returnDisableAddButton()}
        >
          Добавить отзыв
        </Button>
        <Button
          onClick={saveChanges}
          className={styles["list-add-button"]}
          disabled={!returnEnabledSaveButton()}
        >
          Сохранить
        </Button>
      </div>
    </div>
  );
};

export default ReviewsTab;
