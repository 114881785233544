import { TPost, TPostsUpdate } from "typings/post";
import Post from "./Post";
import styles from "./PostsList.module.scss";

type PostsListProps = {
  posts: TPost[];
  openDeletePopup: (id: number) => void;
  onEditPost: (id: number, data: TPostsUpdate) => Promise<void>;
};

const PostsList = ({ posts, openDeletePopup, onEditPost }: PostsListProps) => {
  return (
    <div className={styles["posts-list"]}>
      {posts.map((post) => (
        <Post key={post.id} openDeletePopup={openDeletePopup} onEditPost={onEditPost} post={post} />
      ))}
    </div>
  );
};

export default PostsList;
