import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import defaultProfileImage from "assets/profile-image.png";

import styles from "./PaymentsTable.module.scss";
import { TPayment } from "typings/payment";
import formatDate from "utils/formatDate";
import { formatMoney } from "utils/numberFormat";

const PaymentsTable = ({ paymentsList, params, totalRecords, isLoadingTable, setParams }) => {
  const onPage = (event) => {
    setParams(event);
  };

  const renderProfileImage = (rowData: TPayment) => {
    const {
      student: { avatar },
    } = rowData;
    return (
      <img
        src={avatar ? avatar : defaultProfileImage}
        alt="profile"
        className={styles["profile-image"]}
      />
    );
  };

  const renderProfileName = (rowData: TPayment) => {
    const {
      student: { firstName, lastName },
    } = rowData;
    return `${lastName || ""} ${firstName || ""}`;
  };

  const renderStatus = (rowData: TPayment) => {
    const {
      student: { status },
    } = rowData;
    return status === 0 ? "Активен" : "Заблокирован";
  };

  const renderDate = (rowData: TPayment, { field }) => {
    return formatDate(new Date(rowData[field]));
  };

  const renderAmount = (rowData: TPayment, { field }) => {
    return `+${formatMoney(rowData[field])}`;
  };

  return (
    <DataTable
      value={paymentsList}
      responsiveLayout="scroll"
      dataKey="id"
      lazy
      paginator
      first={params.first}
      rows={15}
      totalRecords={totalRecords}
      onPage={onPage}
      loading={isLoadingTable}
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      emptyMessage="Выплат не найдено"
    >
      <Column field="profileImage" style={{ width: "10%" }} body={renderProfileImage}></Column>
      <Column
        field="student"
        header="Пользователь"
        style={{ width: "21%" }}
        sortable
        body={renderProfileName}
      ></Column>
      <Column field="status" header="Статус" style={{ width: "25%" }} body={renderStatus}></Column>
      <Column field="createdAt" header="Дата" style={{ width: "21%" }} body={renderDate}></Column>
      <Column field="amount" header="Оплата" style={{ width: "22%" }} body={renderAmount}></Column>
    </DataTable>
  );
};

export default PaymentsTable;
