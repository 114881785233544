import { TDirection } from "typings/direction";

import styled from "./DirectionsTable.module.scss";
import formattingDate from "utils/formattingDate";

export const renderColumn = (value: string) => (rowData: TDirection) => {
    return (
        <div className="col-wrapper" onClick={() => { }}>
            <p className="table-text">{rowData[value]}</p>
        </div>
    );
};

export const renderColor = (rowData: TDirection) => {
    return (
        <div className="col-wrapper" onClick={() => { }}>
            <span className={styled["table-color"]} style={{ backgroundColor: rowData.color }}></span>
        </div>
    );
};

export const renderDate = (field: string) => (rowData: TDirection) => {
    return (
        <div className="col-wrapper">
            <p className="table-text">{formattingDate(rowData[field], "dd.mm.yyyy")}</p>
        </div>
    );
};
