import BroadcastCard from "./BroadcastCard/BroadcastCard";
import styles from "./Broadcasts.module.scss";
import { useProfileData } from "../useProfileData";
import { phrases } from "constants/phrases";
import { useState, useCallback, useEffect } from "react";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { TBroadcast, TBroadcastData, TNewBroadcast } from "typings/broadcast";
import { getBroadcasts } from "api/broadcasts/getBroadcasts";
import { useAppDispatch } from "utils/hooks";
import createBroadcast from "api/broadcasts/createBroadcast";
import { updateBroadcast } from "api/broadcasts/updateBroadcast";
import { deleteBroadcast } from "api/broadcasts/deleteBroadcast";
import BroadcastDeletePopup from "popups/broadcast-delete/BroadcastDeletePopup";
import { useParams } from "react-router-dom";
import AddBroadcastForm from "./AddBroadcastForm/AddBroadcastForm";

const Broadcasts = () => {
  const {
    profile: { id },
  } = useProfileData();

  const { id: idFromParams } = useParams();

  const [broadcasts, setBroadcasts] = useState<TBroadcast[]>([]);

  const dispatch = useAppDispatch();

  const getBroadcastsData = useCallback(async () => {
    try {
      const response = await getBroadcasts({ adminId: id });
      if (response.status === 200 || response.status === 201) {
        setBroadcasts(response.data);
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
  }, [dispatch, id]);

  useEffect(() => {
    getBroadcastsData();
  }, [getBroadcastsData]);

  const onCreateBroadcast = async (data: TNewBroadcast) => {
    try {
      await createBroadcast(data, idFromParams);
      getBroadcastsData();
      dispatch(addSuccessMessage(phrases.broadcast_success_added));
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
  };

  const onEditBroadcast = async (id: number, data: TBroadcastData) => {
    try {
      await updateBroadcast(id, data);
      getBroadcastsData();
      dispatch(addSuccessMessage(phrases.broadcast_success_updated));
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
  };

  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState<boolean>(false);
  const [selectedBroadcast, setSelectedBroadcast] = useState<TBroadcast | null>(null);

  const openDeletePopup = (id: number) => {
    setIsOpenDeletePopup(true);
    const broadcast = broadcasts.find((el) => el.id === id);
    if (broadcast) setSelectedBroadcast(broadcast);
  };

  const onDeleteBroadcast = async (id: number) => {
    try {
      await deleteBroadcast(id);
      getBroadcastsData();
      dispatch(addSuccessMessage(phrases.broadcast_success_deleted));
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
    setIsOpenDeletePopup(false);
    setSelectedBroadcast(null);
  };

  return (
    <div className={styles["broadcasts-wrapper"]}>
      <AddBroadcastForm onCreateBroadcast={onCreateBroadcast} />
      <ul>
        {broadcasts.map((el) => (
          <li key={el.id}>
            <BroadcastCard
              broadcast={el}
              onEditBroadcast={onEditBroadcast}
              openDeletePopup={openDeletePopup}
            />
          </li>
        ))}
      </ul>
      <BroadcastDeletePopup
        isOpen={isOpenDeletePopup}
        handleHide={() => setIsOpenDeletePopup(false)}
        broadcast={selectedBroadcast}
        deleteBroadcast={onDeleteBroadcast}
      />
    </div>
  );
};

export default Broadcasts;
