import { Navigate, RouteObject, createBrowserRouter } from "react-router-dom";
import LoginPage from "pages/LoginPage";
import PrivateRoute from "./PrivateRoute";
import UsersPage from "pages/UsersPage";
import AdminsPage from "pages/AdminsPage";
import CoursesPage from "pages/CoursesPage";
import RefferalSystemPage from "pages/RefferalSystemPage";
import PromoPage from "pages/PromoPage";
import ProfilePage from "pages/ProfilePage";
import App from "App";
import Course from "features/Course/Course";
import LessonPage from "pages/LessonPage";
import AdminInfoPage from "pages/AdminInfoPage";

import ProfileSettingsPage from "features/Profile/Pages/ProfileSettingsPage";
import ProfileCoursesPage from "features/Profile/Pages/ProfileCoursesPage";
import ProfileQuestionsPage from "features/Profile/Pages/ProfileQuestionsPage";
import ProfileDataPage from "features/Profile/Pages/ProfileDataPage";
import ProfileFeedPage from "features/Profile/Pages/ProfileFeedPage";
import ProfileEarningsAdditionalPage from "features/Profile/Pages/ProfileEarningsAdditionalPage";
import { TAdmin } from "typings/admin";
import ProfileServicesPage from "features/Profile/Pages/ProfileServicesPage";
import ReviewsPage from "pages/ReviewsPage";

import profileLoader from "loaders/profileLoader";
import reviewsLoader from "loaders/reviewsLoader";
import faqLoader from "loaders/faqLoader";
import courseLoader from "loaders/courseLoader";
import { TCourseInfo, TLessonInfo } from "typings/course";
import lessonLoader from "loaders/lessonLoader";
import CourseErrorPage from "features/Course/ErrorPage";
import LessonErrorPage from "features/Lesson/LessonErrorPage";
import ErrorPage from "pages/ErrorPages/ErrorPage";
import AdminProfileError from "pages/ErrorPages/AdminProfilePageError";
import settingsAdminInfoLoader from "loaders/settingsAdminInfoLoader";
import DirectionsPage from "pages/DirectionsPage";
import AdminPrivateRoute from "AdminPrivateRoute";
import ProfileBroadcastsPage from "features/Profile/Pages/ProfileBroadcastsPage";
import authLoader from "loaders/authLoader";
import ApplicationsPage from "pages/Applications";
import SunscriptionPage from "pages/SunscriptionPage";
import PaymentsStatisticsPage from "pages/PaymentsStatisticsPage";
import PromoEarinigsPage from "pages/PromoEarinigsPage";
import settingsEarningsInfoLoader from "loaders/settingsEarningsInfoLoader";
import RefferalListPage from "pages/RefferalListPage";
import CancelReasonsPage from "pages/CancelReasonsPage";
import OnboardingStatPage from "pages/OnboardingStatPage";

const profileRoutes = [
  {
    path: "",
    element: <ProfileDataPage />,
    children: [
      {
        path: "",
        element: <Navigate to="courses" />,
      },
      {
        path: "courses",
        element: <ProfileCoursesPage />,
      },
      {
        path: "questions",
        element: <ProfileQuestionsPage />,
      },
      {
        path: "feed",
        element: <ProfileFeedPage />,
      },
      {
        path: "services",
        element: <ProfileServicesPage />,
      },
      {
        path: "broadcasts",
        element: <ProfileBroadcastsPage />,
      },
    ],
  },
  {
    path: "settings",
    element: <ProfileSettingsPage />,
    handle: {
      crumb: () => ({ label: "Настройки", to: "settings" }),
    },
  },
  {
    path: "earnings",
    element: <ProfileEarningsAdditionalPage />,
    handle: {
      crumb: () => ({ label: "Информация про заработок", to: "earnings" }),
    },
  },
];

const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      { path: "login", element: <LoginPage /> },
      {
        path: "",
        element: <PrivateRoute />,
        loader: authLoader,
        errorElement: <ErrorPage />,
        children: [
          { path: "", element: <Navigate to={"/profile/courses"} /> },
          {
            path: "",
            element: <AdminPrivateRoute />,
            children: [
              { path: "", element: <Navigate to={"/users"} /> },
              {
                path: "users",
                element: <UsersPage />,
                handle: {
                  crumb: () => ({ label: "Пользователи", to: "users" }),
                },
              },
              {
                path: "applications",
                element: <ApplicationsPage />,
                handle: {
                  crumb: () => ({ label: "Услуги", to: "applications" }),
                },
              },
              {
                path: "subscriptions",
                element: <SunscriptionPage />,
                handle: {
                  crumb: () => ({ label: "Подписки", to: "subscriptions" }),
                },
              },
              {
                path: "payments-statistics",
                element: <PaymentsStatisticsPage />,
                handle: {
                  crumb: () => ({ label: "Статистика", to: "payments-statistics" }),
                },
              },
              {
                path: "cancel-reasons",
                element: <CancelReasonsPage />,
                handle: {
                  crumb: () => ({ label: "Причины отмены", to: "cancel-reasons" }),
                },
              },
              {
                path: "admins",
                handle: {
                  crumb: () => ({ label: "Администраторы", to: "admins" }),
                },
                children: [
                  {
                    path: "",
                    element: <AdminsPage />,
                  },
                  {
                    path: ":id",
                    id: "admin-profile",
                    element: <ProfilePage />,
                    errorElement: <AdminProfileError />,
                    loader: ({ params }) => profileLoader(params.id),
                    handle: {
                      crumb: (data: TAdmin) => {
                        if (data?.id) {
                          const { firstName, lastName, id } = data;
                          return { label: `${lastName} ${firstName}`, to: `${id}` };
                        } else return undefined;
                      },
                    },
                    children: profileRoutes,
                  },
                ],
              },
              {
                path: "courses",
                handle: {
                  crumb: () => ({ label: "Курсы", to: "courses" }),
                },
                children: [
                  {
                    path: "",
                    element: <CoursesPage />,
                  },
                ],
              },
              {
                path: "directions",
                element: <DirectionsPage />,
                handle: {
                  crumb: () => ({ label: "Направления", to: "directions" }),
                },
              },
              {
                path: "referral-system",
                element: <RefferalSystemPage />,
                handle: {
                  crumb: () => ({ label: "Прибыль", to: "referral-system" }),
                },
              },
              {
                path: "settings-reviews",
                loader: reviewsLoader,
                element: <ReviewsPage />,
                errorElement: <ErrorPage />,
                handle: {
                  crumb: () => ({ label: "Настройки - Отзывы", to: "settings-reviews" }),
                },
              },
            ],
          },
          {
            path: "settings-promo",
            element: <PromoPage />,
            loader: faqLoader,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => ({ label: "Настройки - Промо", to: "settings-promo" }),
            },
          },
          {
            path: "earnings",
            element: <ProfilePage />,
            errorElement: <ErrorPage />,
            loader: () => profileLoader(),
            children: [
              {
                path: "",
                element: <ProfileEarningsAdditionalPage />,
                handle: {
                  crumb: () => ({ label: "Профиль", to: "../profile" }),
                },
              },
            ],
          },
          {
            path: "profile",
            id: "profile",
            element: <ProfilePage />,
            errorElement: <ErrorPage />,
            loader: () => profileLoader(),
            handle: {
              crumb: () => ({ label: "Профиль", to: "profile" }),
            },
            children: profileRoutes,
          },
          {
            path: "courses",
            handle: {
              crumb: () => ({ label: "Курсы", to: "courses" }),
            },
            children: [
              {
                path: ":id",
                id: "course",
                loader: ({ params }) => courseLoader(params.id),
                shouldRevalidate: ({ nextParams }) => {
                  return !nextParams.lessonId;
                },

                handle: {
                  crumb: (data: TCourseInfo) => ({ label: data?.name, to: `${data?.id}` }),
                },
                errorElement: <CourseErrorPage />,
                children: [
                  {
                    path: "",
                    element: <Course />,
                  },
                  {
                    path: ":lessonId",
                    element: <LessonPage />,
                    errorElement: <LessonErrorPage />,
                    loader: ({ params }) => lessonLoader(params.lessonId),
                    handle: {
                      crumb: (data: TLessonInfo) => ({ label: data?.name, to: `${data?.id}` }),
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "settings-admin-info",
            element: <AdminInfoPage />,
            loader: settingsAdminInfoLoader,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => ({
                label: "Настройки - Информация для администраторов",
                to: "settings-admin-info",
              }),
            },
          },
          {
            path: "settings-earnings",
            element: <PromoEarinigsPage />,
            loader: settingsEarningsInfoLoader,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => ({
                label: "Настройки - Заработок",
                to: "settings-earnings",
              }),
            },
          },
          {
            path: "refferal-list",
            element: <RefferalListPage />,
            handle: {
              crumb: () => ({ label: "Рефералы", to: "refferal-list" }),
            },
          },
          {
            path: "onboarding-statistics",
            element: <OnboardingStatPage />,
            handle: {
              crumb: () => ({ label: "Статистика по онбордингу", to: "onboarding-statistics" }),
            },
          },
        ],
      },
    ],
  },
];

export default createBrowserRouter(routes);
