import { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { uploadFile } from "api/storage/uploadFile";
import { FileUpload } from "primereact/fileupload";
import { TCourseReview } from "typings/course";
import { InputTextarea } from "primereact/inputtextarea";

import styles from "./Review.module.scss";
import AddedImage from "components/common/added-image/AddedImage";
import PopupCalendar from "popups/popup-calendar/PopupCalendar";
import PopupDateTimeCalendar from "popups/date-time/PopupDateTimeCalendar";

type ReviewProps = {
  review: TCourseReview;
  changeReview: (field: string, value: any, index: number) => void;
  index: number;
  removeReview: (removeIndex: number) => void;
};

const Review = ({ review, changeReview, index, removeReview }: ReviewProps) => {
  const imageFileReference = useRef(null);
  const file1Reference = useRef(null);
  const file2Reference = useRef(null);
  const file3Reference = useRef(null);

  const customUploader = async (e, fileType: string) => {
    if (e.files[0]) {
      const formData = new FormData();
      formData.append("file", e.files[0]);
      const response = await uploadFile(formData);
      if (response.status === 200 || response.status === 201) {
        if (fileType === "imageFile") changeReview("imageFile", response.data, index);
        else if (fileType === "file1")
          changeReview(
            "files",
            [response.data, review.files[1], review.files[2]].filter(Boolean),
            index
          );
        else if (fileType === "file2")
          changeReview(
            "files",
            [review.files[0], response.data, review.files[2]].filter(Boolean),
            index
          );
        else if (fileType === "file3")
          changeReview(
            "files",
            [review.files[0], review.files[1], response.data].filter(Boolean),
            index
          );
      }
    }
  };

  const Header = ({ options, isAdded, onRemove }) => {
    if (isAdded) return <AddedImage name={isAdded.name} deleteFile={onRemove} />;
    return <div>{options.chooseButton}</div>;
  };

  const onRemoveImageFile = () => {
    changeReview("imageFile", null, index);
    //@ts-ignore
    imageFileReference?.current?.clear();
  };

  const onRemoveFile1 = () => {
    changeReview("files", [null, review.files[1], review.files[2]].filter(Boolean), index);
    //@ts-ignore
    file1Reference?.current?.clear();
  };

  const onRemoveFile2 = () => {
    changeReview("files", [review.files[0], null, review.files[2]].filter(Boolean), index);
    //@ts-ignore
    file2Reference?.current?.clear();
  };

  const onRemoveFile3 = () => {
    changeReview("files", [review.files[0], review.files[1], null].filter(Boolean), index);
    //@ts-ignore
    file3Reference?.current?.clear();
  };

  return (
    <div className={styles["review-body"]}>
      <div className={styles["first-row"]}>
        <InputText
          maxLength={600}
          value={review.name}
          className="flex-1 w-full p-inputtext-light-bg"
          placeholder="Имя"
          onChange={(e) => changeReview("name", e.target.value, index)}
        />
        <InputText
          maxLength={20}
          value={review.age}
          className="flex-1 w-full p-inputtext-light-bg"
          placeholder="Возраст"
          onChange={(e) => changeReview("age", e.target.value, index)}
        />
        <button className={styles["delete-item-button"]} onClick={() => removeReview(index)}>
          <i className={"pi pi-trash"} />
        </button>
      </div>
      <div className={styles["added-image-wrapper"]}>
        <p>Аватар</p>
        <FileUpload
          ref={imageFileReference}
          name="file-uploader"
          accept="image/*"
          maxFileSize={1000000}
          customUpload
          uploadHandler={(e) => customUploader(e, "imageFile")}
          auto
          itemTemplate={() => null}
          chooseLabel={review.imageFile?.name || "Примерный файл.jpg"}
          className={styles["file-uploader"]}
          onRemove={() => changeReview("imageFile", null, index)}
          contentClassName={styles["file-upload-content"]}
          headerTemplate={(options) => (
            <Header options={options} isAdded={review.imageFile} onRemove={onRemoveImageFile} />
          )}
          progressBarTemplate={() => null}
        />
        <PopupDateTimeCalendar
          placeholder="Дата отзыва"
          inputClassName="w-full p-inputtext-sx p-inputtext-light-bg"
          date={review.date ? new Date(review.date) : null}
          setDate={(date) => changeReview("date", date, index)}
        />
      </div>
      <InputTextarea
        maxLength={1300}
        value={review.text}
        rows={3}
        cols={30}
        autoResize
        placeholder="Текст отзыва"
        onChange={(e) => changeReview("text", e.target.value, index)}
        className={styles["text-area"]}
      />
      <FileUpload
        ref={file1Reference}
        name="file-uploader1"
        accept="image/*"
        maxFileSize={1000000}
        customUpload
        uploadHandler={(e) => customUploader(e, "file1")}
        auto
        itemTemplate={() => null}
        chooseLabel={review.files[0]?.name || "Примерный файл.jpg"}
        className={styles["file-uploader"]}
        onRemove={() => changeReview("files", [null, review.files[1], review.files[2]], index)}
        contentClassName={styles["file-upload-content"]}
        headerTemplate={(options) => (
          <Header options={options} isAdded={review.files[0]} onRemove={onRemoveFile1} />
        )}
        progressBarTemplate={() => null}
      />
      <FileUpload
        ref={file2Reference}
        name="file-uploader2"
        accept="image/*"
        maxFileSize={1000000}
        customUpload
        uploadHandler={(e) => customUploader(e, "file2")}
        auto
        itemTemplate={() => null}
        chooseLabel={review.files[1]?.name || "Примерный файл.jpg"}
        className={styles["file-uploader"]}
        onRemove={() => changeReview("files", [review.files[0], null, review.files[2]], index)}
        contentClassName={styles["file-upload-content"]}
        headerTemplate={(options) => (
          <Header options={options} isAdded={review.files[1]} onRemove={onRemoveFile2} />
        )}
        progressBarTemplate={() => null}
      />
      <FileUpload
        ref={file3Reference}
        name="file-uploader3"
        accept="image/*"
        maxFileSize={1000000}
        customUpload
        uploadHandler={(e) => customUploader(e, "file3")}
        auto
        itemTemplate={() => null}
        chooseLabel={review.files[2]?.name || "Примерный файл.jpg"}
        className={styles["file-uploader"]}
        onRemove={() => changeReview("files", [review.files[0], review.files[1], null], index)}
        contentClassName={styles["file-upload-content"]}
        headerTemplate={(options) => (
          <Header options={options} isAdded={review.files[2]} onRemove={onRemoveFile3} />
        )}
        progressBarTemplate={() => null}
      />
    </div>
  );
};

export default Review;
