import { TCourse } from "typings/course";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FC } from "react";
import { tableControlsType } from "typings/table-controls";

import styles from "./CoursesTable.module.scss";
import { Button } from "primereact/button";

import formatDate from "utils/formatDate";
import { COURSE_STATUSES_LABELS } from "constants/course";
import { Link, useParams } from "react-router-dom";
import Tag from "components/common/tags/Tag";
type CoursesTableProps = {
  params: tableControlsType;
  coursesList: TCourse[];
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  totalRecords: number;
  isLoadingTable: boolean;
  handleOpenTakeOffCoursePopup: () => void;
  handleOpenRemoveCoursePopup: () => void;
  setSelectedCourse: (data: TCourse) => void;
};

const CoursesTable: FC<CoursesTableProps> = (props) => {
  const { id } = useParams();
  const onPage = (event) => {
    props.setParams(event);
  };

  const renderAction = (rowData: TCourse) => {
    return (
      <div className={styles["col-action-wrapper"]} onClick={() => {}}>
        <Link to={`/courses/${rowData.id}`}>
          <Button className="p-button-rounded" icon="pi pi-pencil" />
        </Link>
        <Button
          className="p-button-rounded p-button-secondary"
          icon={id ? "pi pi-ban" : "pi pi-trash"}
          onClick={() => {
            props.setSelectedCourse(rowData);
            if (id) {
              props.handleOpenTakeOffCoursePopup();
            } else {
              props.handleOpenRemoveCoursePopup();
            }
          }}
        />
      </div>
    );
  };

  const renderDirection = (rowData: TCourse, { field }) => {
    const tag = rowData[field];
    return tag ? <Tag name={tag.header.toUpperCase()} color={tag.color} /> : null;
  };

  const renderDate = (rowData: TCourse, { field }) => {
    const value = rowData[field];
    return formatDate(new Date(value));
  };

  const renderStatus = (rowData: TCourse) => {
    return COURSE_STATUSES_LABELS[rowData.status];
  };

  const onColumnSort = (params) => {
    props.setParams((prev) => ({
      ...prev,
      OrderBy: params.sortField,
      sortOrder: params.sortOrder,
    }));
  };

  return (
    <DataTable
      value={props.coursesList}
      responsiveLayout="scroll"
      dataKey="id"
      lazy
      paginator
      first={props.params.first}
      rows={15}
      totalRecords={props.totalRecords}
      onPage={onPage}
      loading={props.isLoadingTable}
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      emptyMessage="Курсов не найдено"
      sortField={props.params.OrderBy}
      sortOrder={props.params.sortOrder}
      onSort={onColumnSort}
      removableSort
    >
      <Column field="id" header="ID" style={{ width: "9%" }} sortable></Column>
      <Column field="name" header="Название" style={{ width: "21%" }} sortable></Column>
      <Column
        field="direction"
        header="Направление"
        style={{ width: "15%" }}
        body={renderDirection}
      ></Column>
      <Column
        field="createdAt"
        header="Создано"
        style={{ width: "14%" }}
        body={renderDate}
      ></Column>
      <Column
        field="updatedAt"
        header="Обновлено"
        style={{ width: "14%" }}
        body={renderDate}
      ></Column>
      <Column field="status" header="Статус" style={{ width: "10%" }} body={renderStatus}></Column>
      <Column field="actions" header="" style={{ width: "16%" }} body={renderAction}></Column>
    </DataTable>
  );
};

export default CoursesTable;
