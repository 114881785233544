import styles from "./error.module.scss";
import { phrases } from "constants/phrases";

const ProfilePageError = () => {
  return (
    <div className={styles["error-page"]}>
      <h2>{phrases.smthWentWrongText}</h2>
    </div>
  );
};

export default ProfilePageError;
