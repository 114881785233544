import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { TCourseInfo } from "typings/course";

import styles from "./PublishCoursePopup.module.scss";

type PublishCoursePopupProps = {
    isOpen: boolean;
    handleHide: () => void;
    publishCourse: () => void;
    courseInfo: TCourseInfo;
};

const PublishCoursePopup: FC<PublishCoursePopupProps> = (props) => {
    const closePopup = () => {
        props.handleHide();
    };

    return (
        <Dialog
            visible={props.isOpen}
            modal
            onHide={closePopup}
            className={styles["dialog"]}
            showHeader={false}
        >
            <div className={styles["content"]}>
                <i className={classNames("pi pi-check-circle", styles["icon"])} />

                <p className={styles["title"]}>Принять и опубликовать курс</p>

                <p className={styles["text"]}>“{props.courseInfo.name}”</p>

                <p className={styles["warning"]}>
                    Внимание! Материал будет опубликован для всех пользователей
                </p>

                <div className={styles["buttons-container"]}>
                    <Button className={styles["cancel-button"]} onClick={closePopup}>
                        Отмена
                    </Button>
                    <Button className={styles["publish-button"]} onClick={props.publishCourse}>
                        Опубликовать
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default PublishCoursePopup;
