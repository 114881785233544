import { FC } from "react";

import Directions from "features/Directions/Directions";

type DirectionsPageProps = {};

const DirectionsPage: FC<DirectionsPageProps> = () => {
    return <Directions />;
};

export default DirectionsPage;
