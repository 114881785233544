import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "./AddCourseModal.module.scss";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { getCourses } from "api/courses/getCourses";
import { DEFAULT_PARAMS } from "constants/table-params";
import { useAppDispatch } from "utils/hooks";
import { phrases } from "constants/phrases";
import { addErrorMessage } from "slices/toastSlice";

type AddCourseModalProps = {
  isOpen: boolean;
  handleHide: () => void;
  addCourse: (courseId: number) => Promise<void>;
};

type TOption = { label: string; value: number };

const AddCourse: FC<AddCourseModalProps> = ({ handleHide, isOpen, addCourse }) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [items, setItems] = useState<TOption[]>([]);
  const loadedPages = useRef<Set<number>>(new Set());
  const [loading, setLoading] = useState(false);

  const [initialLoading, setInitialLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);

  const dispatch = useAppDispatch();

  const getInitialCourses = useCallback(async () => {
    try {
      const res = await getCourses({ page: 0, limit: DEFAULT_PARAMS.rows });
      const {
        data: {
          data,
          pagination: { totalItems, totalPages },
        },
      } = res;
      const _items: TOption[] = [];
      for (let i = 0; i < totalItems; i++) {
        _items[i] = { label: data[i]?.name, value: data[i]?.id };
      }
      setItems(_items);
      setTotalPages(totalPages);
      loadedPages.current.add(0);
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
    setInitialLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (isOpen) {
      loadedPages.current.clear();
      getInitialCourses();
    }
  }, [getInitialCourses, isOpen]);

  const onLazyLoad = (event) => {
    const loadData = async () => {
      try {
        const res = await getCourses({ page, limit: DEFAULT_PARAMS.rows });
        const {
          data: { data },
        } = res;
        const _items = [...items];

        for (let i = 0; i < data.length; i++) {
          _items[page * DEFAULT_PARAMS.rows + i] = { label: data[i]?.name, value: data[i]?.id };
        }

        setItems(_items);
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      }
    };

    setLoading(true);

    const { last } = event;

    const page = Math.round(last / DEFAULT_PARAMS.rows);

    if (page > totalPages || loadedPages.current.has(page)) {
    } else {
      loadData();
    }
    loadedPages.current.add(page);
    setLoading(false);
  };

  if (initialLoading) return null;

  const onSubmit = async () => {
    if (selectedItem) {
      await addCourse(selectedItem);
    }
    handleHide();
  };

  return (
    <Dialog visible={isOpen} modal onHide={handleHide} showHeader={false}>
      <div className={styles["content"]}>
        <h5 className={styles["title"]}>Добавление нового курса</h5>
        <div className={styles["form"]}>
          <p className={styles["label"]}>Выберите курс</p>
          <Dropdown
            value={selectedItem}
            onChange={(e) => setSelectedItem(e.value)}
            options={items}
            placeholder="Выберите курс"
            className="w-full md:w-14rem"
            optionLabel="label"
            virtualScrollerOptions={{
              lazy: true,
              onLazyLoad: onLazyLoad,
              itemSize: 31,
              showLoader: true,
              loading: loading,
            }}
          />
        </div>
        <div className={styles["footer"]}>
          <Button className="p-button-danger p-button-outlined" onClick={handleHide}>
            Отмена
          </Button>
          <Button onClick={onSubmit}>Назначить</Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AddCourse;
