import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import styles from "./TakeOffCourseModal.module.scss";
import { TAdminProfile } from "typings/admin";

type TakeOffCourseModalProps = {
  isOpen: boolean;
  handleHide: () => void;
  profile: TAdminProfile;
  removeFromCourse: () => void;
};

const TakeOffCourseModal: FC<TakeOffCourseModalProps> = ({
  handleHide,
  isOpen,
  profile,
  removeFromCourse,
}) => {
  const closePopup = () => handleHide();

  return (
    <Dialog
      visible={isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <i className={classNames("pi pi-exclamation-circle", styles["warning-icon"])} />
        <p className={styles["title"]}>
          Вы уверены, что хотите снять данного пользователя с курса?
        </p>
        <p className={styles["text"]}>
          {profile ? profile.firstName + " " + profile.lastName : "Пользователь"}
          <br />
          {profile.role === "curator" ? "Куратор" : "Спикер"}
        </p>
        <p className={styles["label"]}>
          Это ограничит доступ данного пользователя к управлению курсом.
        </p>
        <div className={styles["buttons-container"]}>
          <Button className="p-button-danger p-button-outlined" onClick={closePopup}>
            Отмена
          </Button>
          <Button className="p-button-danger" onClick={removeFromCourse}>
            Удалить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default TakeOffCourseModal;
