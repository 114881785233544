import { FC } from "react";

import Profile from "features/Profile/Profile";
import { useParams } from "react-router-dom";

type ProfilePageProps = {};

const ProfilePage: FC<ProfilePageProps> = () => {
  const { id } = useParams();
  return <Profile id={id} />;
};

export default ProfilePage;
