import { getLessonInfoAsync } from "api/courses/getLessonInfo";
import { phrases } from "constants/phrases";
import { addErrorMessage } from "slices/toastSlice";
import { store } from "store";
import { TCourseInfo } from "typings/course";

const { dispatch } = store;

const lessonLoader = async (lessonId?: string): Promise<TCourseInfo | void> => {
  try {
    if (lessonId) {
      const response = await getLessonInfoAsync(lessonId);
      if (response.status === 200 || response.status === 201) {
        return response.data as TCourseInfo;
      } else throw new Error();
    } else throw new Error();
  } catch (err: any) {
    dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
  }
};

export default lessonLoader;
