import { TAdmin } from "typings/admin";
import { USER_STATUSES } from "constants/users";
import { ADMIN_ROLES_LABELS, ADMIN_ROLES_VALUES } from "constants/admins";
import formattingDate from "utils/formattingDate";

export const renderColumn = (value: string) => (rowData: TAdmin) => {
    return (
        <div className="col-wrapper" onClick={() => { }}>
            <p className="table-text">{rowData[value]}</p>
        </div>
    );
};

export const renderStatus = (rowData: TAdmin) => {
    return (
        <div className="col-wrapper" onClick={() => { }}>
            <p className="table-text">
                {rowData.status === USER_STATUSES.active ? "Активен" : "Заблокирован"}
            </p>
        </div>
    );
};

export const renderFIO = (rowData: TAdmin) => {
    return (
        <div className="col-wrapper" onClick={() => { }}>
            <p className="table-text">{rowData.firstName + " " + rowData.lastName}</p>
        </div>
    );
};

export const renderType = (rowData: TAdmin) => {
    return (
        <div className="col-wrapper" onClick={() => { }}>
            <p className="table-text">
                {rowData.role === ADMIN_ROLES_VALUES.admin
                    ? ADMIN_ROLES_LABELS.admin
                    : rowData.role === ADMIN_ROLES_VALUES.curator
                        ? ADMIN_ROLES_LABELS.curator
                        : ADMIN_ROLES_LABELS.speaker}
            </p>
        </div>
    );
};

export const renderDate = (field: string) => (rowData: TAdmin) => {
    return (
        <div className="col-wrapper">
            <p className="table-text">{formattingDate(rowData[field], "dd.mm.yyyy")}</p>
        </div>
    );
};
