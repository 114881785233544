import { FC } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FILTER_ADMIN_ROLES, STATUSES } from "constants/filters";

import styled from "./Admins.module.scss";

type HeaderLayoutProps = {
  handleOpenAddAdminPopup: () => void;
  role: string;
  status: string;
  changeRole: (role: string) => void;
  changeStatus: (status: string) => void;
};

const HeaderLayout: FC<HeaderLayoutProps> = (props) => {
  return (
    <div className={styled["header-layout"]}>
      <Button
        type="button"
        className="p-button-sm"
        icon="pi pi-plus"
        label="Добавить администратора"
        onClick={props.handleOpenAddAdminPopup}
      />
      <div className={styled["dropdown-wrapper"]}>
        <Dropdown
          options={FILTER_ADMIN_ROLES}
          onChange={(e) => props.changeRole(e.value)}
          value={props.role}
          placeholder="Выберите роль"
          className={styled["dropdown"]}
        />
      </div>
      <div className={styled["dropdown-wrapper"]}>
        <Dropdown
          options={STATUSES}
          onChange={(e) => props.changeStatus(e.value)}
          value={props.status}
          placeholder="Выберите статус"
          className={styled["dropdown"]}
        />
      </div>
    </div>
  );
};

export default HeaderLayout;
