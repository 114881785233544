import { getRefundReasons } from "api/subscriptions/getRefundReasons";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { TRefundReason } from "typings/subscription";
import styles from "./CancelReasons.module.scss";

export default function CancelReasons() {
  const [data, setData] = useState<TRefundReason[]>([]);

  useEffect(() => {
    const getData = async () => {
      const response = await getRefundReasons();
      setData(response);
    };
    getData();
  }, []);

  return (
    <div className={styles["page-container"]}>
      <div className={styles["content-card"]}>
        <DataTable value={data}>
          <Column field="reasonOff" header="Причина отмены"></Column>
          <Column
            field="percent"
            header="Процент пользователей"
            body={(data) => `${Math.round(data.percent)}%`}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
}
