import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import styles from "./DeleteServicePopup.module.scss";
import { TService } from "typings/service";

type DeleteServicePopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  service: TService;
  deleteService: (serviceId: number) => void;
};

const DeleteServicePopup: FC<DeleteServicePopupProps> = ({
  service,
  deleteService,
  handleHide,
  isOpen,
}) => {
  const closePopup = () => handleHide();

  return (
    <Dialog
      visible={isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <i className={classNames("pi pi-exclamation-circle", styles["warning-icon"])} />
        <p className={styles["title"]}>Вы уверены, что хотите удалить услугу?</p>
        <p className={styles["text"]}>{service.name || ""}</p>
        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button className={styles["remove-button"]} onClick={() => deleteService(service.id)}>
            Удалить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteServicePopup;
