import { FC } from "react";

import Lesson from "features/Lesson/Lesson";

type LessonPageProps = {};

const LessonPage: FC<LessonPageProps> = () => {
    return <Lesson />;
};

export default LessonPage;
