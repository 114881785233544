import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";

import styled from "./PaymentsStatisticsTable.module.scss";
import { Tooltip } from "primereact/tooltip";
import { formatDay } from "utils/formatDay";
import { tableControlsType } from "typings/table-controls";
import { TPaymentsStatistics } from "typings/subscription";

const Header = ({ title, shortTitle }: { title: string; shortTitle: string }) => {
  return (
    <>
      <Tooltip content={title} position="top" target={`.${shortTitle}`} />
      <span className={shortTitle}>{shortTitle}</span>
    </>
  );
};

type SubscriptionsTableProps = {
  subscriptionsList: TPaymentsStatistics[];
  totals?: TPaymentsStatistics;
  isLoadingTable: boolean;
  totalRecords: number;
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  params: tableControlsType;
};

const PaymentsStatisticsTable = (props: SubscriptionsTableProps) => {
  const { totals } = props;
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Итого"></Column>
        <Column footer={totals?.countLeads}></Column>
        <Column footer={totals?.countSuccessPayments}></Column>
        <Column footer={totals?.sumSuccessPayments}></Column>
        <Column footer={totals?.countAutoPayments}></Column>
        <Column footer={totals?.countUniqueAutoPayments}></Column>
        <Column footer={totals?.countSuccessAutoPayments}></Column>
        <Column footer={totals?.sumSuccessAutoPayments}></Column>
        <Column footer={totals?.countOffSubscriptions}></Column>
        <Column footer={totals?.countFinishedSubscriptions}></Column>
        <Column footer={totals?.countRefundedPayments}></Column>
        <Column footer={totals?.countActiveSubscriptions}></Column>
        <Column footer={totals?.totalSumPayments}></Column>
      </Row>
    </ColumnGroup>
  );

  const onPage = (event) => {
    props.setParams(event);
  };

  return (
    <div className={styled["table-wrapper"]}>
      <DataTable
        value={props.subscriptionsList}
        responsiveLayout="scroll"
        dataKey="id"
        paginator
        lazy
        first={props.params.first}
        rows={15}
        totalRecords={props.totalRecords}
        onPage={onPage}
        loading={props.isLoadingTable}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        emptyMessage="Данных не найдено"
        footerColumnGroup={footerGroup}
      >
        <Column field="date" header="День" body={(data) => formatDay(new Date(data.date))}></Column>
        <Column field="countLeads" header={<Header title="Лиды" shortTitle="Л" />}></Column>
        <Column
          field="countSuccessPayments"
          header={<Header title="Успешные покупки" shortTitle="УП" />}
        ></Column>
        <Column
          field="sumSuccessPayments"
          header={<Header title="Сумма успешных покупок" shortTitle="СУ" />}
        ></Column>
        <Column
          field="countAutoPayments"
          header={<Header title="Попытки продлений (попытка автосписания)" shortTitle="ПП" />}
        ></Column>
        <Column
          field="countUniqueAutoPayments"
          header={
            <Header title="Уникальные попытки продлений (попытка автосписания)" shortTitle="УПП" />
          }
        ></Column>
        <Column
          field="countSuccessAutoPayments"
          header={<Header title="Успешные продления" shortTitle="УПР" />}
        ></Column>
        <Column
          field="sumSuccessAutoPayments"
          header={<Header title="Сумма успешных продлений" shortTitle="СУП" />}
        ></Column>
        <Column
          field="countOffSubscriptions"
          header={<Header title="Отмены подписок" shortTitle="ОП" />}
        ></Column>
        <Column
          field="countFinishedSubscriptions"
          header={<Header title="Истекшие подписки" shortTitle="ИП" />}
        ></Column>
        <Column
          field="countRefundedPayments"
          header={<Header title="Возвраты" shortTitle="В" />}
        ></Column>
        <Column
          field="countActiveSubscriptions"
          header={<Header title="Активные подписки" shortTitle="АП" />}
        ></Column>
        <Column
          field="totalSumPayments"
          header={<Header title="Общая сумма продаж" shortTitle="ОСП" />}
        ></Column>
      </DataTable>
    </div>
  );
};

export default PaymentsStatisticsTable;
