import NoAccessPage from "pages/ErrorPages/NoAccess";

import { Outlet } from "react-router-dom";
import { useGetRoles } from "utils/useGetRoles";

const AdminPrivateRoute = () => {
  const { isAdmin } = useGetRoles();

  if (!isAdmin) {
    return <NoAccessPage />;
  }

  return <Outlet />;
};

export default AdminPrivateRoute;
