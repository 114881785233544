import { Button } from "primereact/button";
import { phrases } from "constants/phrases";
import { useGetRoles } from "utils/useGetRoles";
import { useAppDispatch } from "utils/hooks";
import { removeModuleAsync } from "api/courses/removeModule";
import { removeCourseAsync } from "api/courses/removeCourse";
import { updateModuleAsync } from "api/courses/editModule";
import { createNewLessonAsync } from "api/courses/addLesson";
import { createNewModuleAsync } from "api/courses/addModule";
import { COURSE_STATUSES } from "constants/course";
import { Accordion, AccordionTab } from "primereact/accordion";
import { changeCourseStatusAsync } from "api/courses/changeCourseStatus";
import { FC, useCallback, useState } from "react";
import { changeModulesPositionsAsync } from "api/courses/changeModulePositions";
import { useNavigate, useRevalidator } from "react-router";
import { changeLessonsPositionsAsync } from "api/courses/changeLessonPositions";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import {
  TCourseInfo,
  TModule,
  TNewLesson,
  TNewModule,
  TShortLesson,
  TShortModule,
} from "typings/course";

import styled from "./ContentTab.module.scss";
import ModuleHeader from "./ModuleHeader";
import LessonHeader from "./LessonHeader";
import AddModulePopup from "popups/add-module/AddModulePopup";
import AddLessonPopup from "popups/add-lesson/AddLessonPopup";
import EditModulePopup from "popups/edit-module/EditModulePopup";
import RemoveModulePopup from "popups/remove-module/RemoveModulePopup";
import RemoveCoursePopup from "popups/remove-course/RemoveCoursePopup";
import PublishCoursePopup from "popups/publish-course/PublishCoursePopup";
import MovingLessonsPopup from "popups/moving-lessons/MovingLessonsPopup";
import MovingModulesPopup from "popups/moving-modules/MovingModulesPopup";
import DeactivateCoursePopup from "popups/deactivate-course/DeactivateCoursePopup";
import ReturnForRevisionCoursePopup from "popups/return-for-revision/ReturnForRevisionCoursePopup";

type ContentTabProps = {
  handleCloseDeactivateCoursePopup: () => void;
  isOpenDeactivateCoursePopup: boolean;
  isOpenPublishCoursePopup: boolean;
  handleClosePublishCoursePopup: () => void;
  isOpenReturnForRevisionCoursePopup: boolean;
  handleCloseReturnForRevisionCoursePopup: () => void;
  handleCloseRemoveCoursePopup: () => void;
  isOpenRemoveCoursePopup: boolean;
  isOpenMovingLessonsCoursePopup: boolean;
  handleCloseMovingLessonsPopup: () => void;
  isOpenMovingModulesPopup: boolean;
  handleCloseMovingModulesPopup: () => void;
  handleOpenMovingLessonsPopup: () => void;
  courseInfo: TCourseInfo;
};

const ContentTab: FC<ContentTabProps> = ({
  handleCloseDeactivateCoursePopup,
  isOpenDeactivateCoursePopup,
  isOpenPublishCoursePopup,
  handleClosePublishCoursePopup,
  isOpenReturnForRevisionCoursePopup,
  handleCloseReturnForRevisionCoursePopup,
  handleCloseRemoveCoursePopup,
  isOpenRemoveCoursePopup,
  isOpenMovingLessonsCoursePopup,
  handleCloseMovingLessonsPopup,
  isOpenMovingModulesPopup,
  handleCloseMovingModulesPopup,
  handleOpenMovingLessonsPopup,
  courseInfo,
}) => {
  const [isOpenAddModulePopup, setIsOpenAddModulePopup] = useState<boolean>(false);
  const [isOpenEditModulePopup, setIsOpenEditModulePopup] = useState<boolean>(false);
  const [isOpenAddLessonPopup, setIsOpenAddLessonPopup] = useState<boolean>(false);
  const [isOpenRemoveModulePopup, setIsOpenRemoveModulePopup] = useState<boolean>(false);
  const [selectedModule, setSelectedModule] = useState<TModule | null>(null);

  const { isCurator } = useGetRoles();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const changeSelectedModule = (module: TModule) => setSelectedModule(module);

  const handleOpenAddModulePopup = () => setIsOpenAddModulePopup(true);
  const handleCloseAddModulePopup = () => setIsOpenAddModulePopup(false);

  const handleOpenEditModulePopup = () => setIsOpenEditModulePopup(true);
  const handleCloseEditModulePopup = () => setIsOpenEditModulePopup(false);

  const handleOpenAddLessonPopup = () => setIsOpenAddLessonPopup(true);
  const handleCloseAddLessonPopup = () => setIsOpenAddLessonPopup(false);

  const handleOpenRemoveModulePopup = () => setIsOpenRemoveModulePopup(true);
  const handleCloseRemoveModulePopup = () => setIsOpenRemoveModulePopup(false);

  const revalidator = useRevalidator();

  const createModule = useCallback(
    async (moduleData: TNewModule) => {
      try {
        const response = await createNewModuleAsync(courseInfo.id, moduleData);
        if (response.status === 200 || response.status === 201) {
          revalidator.revalidate();
          dispatch(addSuccessMessage(phrases.module_success_added));
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      } finally {
        handleCloseAddModulePopup();
      }
    },
    [dispatch, courseInfo.id, revalidator]
  );

  const deleteModule = useCallback(async () => {
    try {
      if (selectedModule) {
        const response = await removeModuleAsync(selectedModule.id);
        if (response.status === 200 || response.status === 201) {
          revalidator.revalidate();
          dispatch(addSuccessMessage(phrases.module_sucess_deleted));
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    } finally {
      handleCloseRemoveModulePopup();
    }
  }, [dispatch, revalidator, selectedModule]);

  const editModule = useCallback(
    async (moduleData: TNewModule) => {
      try {
        if (selectedModule) {
          const response = await updateModuleAsync(selectedModule.id, moduleData);
          if (response.status === 200 || response.status === 201) {
            revalidator.revalidate();
            dispatch(addSuccessMessage(phrases.module_success_updated));
          } else throw new Error();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      } finally {
        handleCloseEditModulePopup();
      }
    },
    [dispatch, revalidator, selectedModule]
  );

  const deleteCourse = useCallback(async () => {
    try {
      if (courseInfo) {
        const response = await removeCourseAsync(courseInfo.id);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.course_sucess_deleted));
          navigate("/courses");
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    } finally {
      handleCloseRemoveCoursePopup();
    }
  }, [dispatch, navigate, courseInfo, handleCloseRemoveCoursePopup]);

  const publishCourse = useCallback(async () => {
    try {
      if (courseInfo.id) {
        const response = await changeCourseStatusAsync(courseInfo.id, COURSE_STATUSES.publish);
        if (response.status === 200 || response.status === 201) {
          revalidator.revalidate();
          dispatch(addSuccessMessage(phrases.course_sucess_published));
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    } finally {
      handleClosePublishCoursePopup();
    }
  }, [dispatch, courseInfo.id, revalidator, handleClosePublishCoursePopup]);

  const deactivateCourse = useCallback(async () => {
    try {
      if (courseInfo) {
        const response = await changeCourseStatusAsync(courseInfo.id, COURSE_STATUSES.blocked);
        if (response.status === 200 || response.status === 201) {
          revalidator.revalidate();
          dispatch(addSuccessMessage(phrases.course_sucess_deactivated));
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    } finally {
      handleCloseDeactivateCoursePopup();
    }
  }, [dispatch, courseInfo, revalidator, handleCloseDeactivateCoursePopup]);

  const returnForRevisionCourse = useCallback(
    async (comment?: string) => {
      try {
        if (courseInfo) {
          const response = await changeCourseStatusAsync(
            courseInfo.id,
            COURSE_STATUSES.returnRevision,
            comment
          );
          if (response.status === 200 || response.status === 201) {
            revalidator.revalidate();
            dispatch(addSuccessMessage(phrases.course_sucess_return_for_revision));
          } else throw new Error();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      } finally {
        handleCloseReturnForRevisionCoursePopup();
      }
    },
    [dispatch, courseInfo, revalidator, handleCloseReturnForRevisionCoursePopup]
  );

  const createLesson = useCallback(
    async (lessonData: TNewLesson) => {
      try {
        if (selectedModule) {
          const response = await createNewLessonAsync(selectedModule.id, lessonData);
          if (response.status === 200 || response.status === 201) {
            dispatch(addSuccessMessage(phrases.lesson_success_added));
            navigate(`/courses/${courseInfo.id}/${response.data.id}`);
          } else throw new Error();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      } finally {
        handleCloseAddLessonPopup();
      }
    },
    [dispatch, selectedModule, navigate, courseInfo.id]
  );

  const changeModulesPositions = useCallback(
    async (newModuleList: TShortModule[]) => {
      try {
        const response = await changeModulesPositionsAsync(courseInfo.id, newModuleList);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.course_sucess_updated));
          revalidator.revalidate();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      } finally {
        handleCloseMovingModulesPopup();
      }
    },
    [dispatch, courseInfo.id, revalidator, handleCloseMovingModulesPopup]
  );

  const changeLessonsPositions = useCallback(
    async (newLessonList: TShortLesson[]) => {
      try {
        if (selectedModule) {
          const response = await changeLessonsPositionsAsync(selectedModule.id, newLessonList);
          if (response.status === 200 || response.status === 201) {
            dispatch(addSuccessMessage(phrases.course_sucess_updated));
            revalidator.revalidate();
          } else throw new Error();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      } finally {
        handleCloseMovingLessonsPopup();
      }
    },
    [dispatch, handleCloseMovingLessonsPopup, revalidator, selectedModule]
  );

  return (
    <div className={styled["container"]}>
      <div className={styled["card"]}>
        {courseInfo.modules.length > 0 ? (
          courseInfo.modules.map((module) => (
            <Accordion className={styled["accordion"]} key={module.id}>
              <AccordionTab
                header={
                  <ModuleHeader
                    handleOpenRemoveModulePopup={handleOpenRemoveModulePopup}
                    handleOpenMovingLessonsPopup={handleOpenMovingLessonsPopup}
                    handleOpenEditModulePopup={handleOpenEditModulePopup}
                    module={module}
                    changeSelectedModule={changeSelectedModule}
                  />
                }
              >
                <p className={styled["module-description"]}>
                  {module.description || "Описание отсутствует"}
                </p>

                {module.lessons.length > 0 ? (
                  module.lessons.map((lesson) => (
                    <Accordion className={styled["accordion"]} key={lesson.id}>
                      <AccordionTab
                        header={<LessonHeader lesson={lesson} courseId={courseInfo.id} />}
                      >
                        <div className={styled["description-wrapper"]}>
                          <p>{lesson.description || "Описание отсутствует"}</p>
                          {lesson.imageUrl && <img src={lesson.imageUrl} alt="lesson" />}
                        </div>
                      </AccordionTab>
                    </Accordion>
                  ))
                ) : (
                  <div className={styled["empty-list"]}>
                    <p>В этом модуле нет ни одного урока</p>
                  </div>
                )}
                {!isCurator && (
                  <Button
                    className={styled["add-button"]}
                    onClick={() => {
                      setSelectedModule(module);
                      handleOpenAddLessonPopup();
                    }}
                  >
                    <i className="pi pi-plus" /> Добавить урок
                  </Button>
                )}
              </AccordionTab>
            </Accordion>
          ))
        ) : (
          <div className={styled["empty-list"]}>
            <p>В этом курсе нет ни одного модуля</p>
          </div>
        )}
        {!isCurator && (
          <Button className={styled["add-button"]} onClick={handleOpenAddModulePopup}>
            <i className="pi pi-plus" /> Добавить модуль
          </Button>
        )}
      </div>

      <DeactivateCoursePopup
        isOpen={isOpenDeactivateCoursePopup}
        handleHide={handleCloseDeactivateCoursePopup}
        deactivateCourse={deactivateCourse}
        courseInfo={courseInfo}
      />

      <PublishCoursePopup
        isOpen={isOpenPublishCoursePopup}
        handleHide={handleClosePublishCoursePopup}
        publishCourse={publishCourse}
        courseInfo={courseInfo}
      />

      <ReturnForRevisionCoursePopup
        isOpen={isOpenReturnForRevisionCoursePopup}
        handleHide={handleCloseReturnForRevisionCoursePopup}
        returnForRevisionCourse={returnForRevisionCourse}
        courseInfo={courseInfo}
      />

      <RemoveCoursePopup
        isOpen={isOpenRemoveCoursePopup}
        handleHide={handleCloseRemoveCoursePopup}
        deleteCourse={deleteCourse}
      />

      <MovingLessonsPopup
        isOpen={isOpenMovingLessonsCoursePopup}
        handleHide={handleCloseMovingLessonsPopup}
        selectedModule={selectedModule}
        changeLessonsPositions={changeLessonsPositions}
      />

      <MovingModulesPopup
        isOpen={isOpenMovingModulesPopup}
        handleHide={handleCloseMovingModulesPopup}
        courseInfo={courseInfo}
        changeModulesPositions={changeModulesPositions}
      />

      <AddModulePopup
        isOpen={isOpenAddModulePopup}
        handleHide={handleCloseAddModulePopup}
        createModule={createModule}
      />

      <AddLessonPopup
        isOpen={isOpenAddLessonPopup}
        handleHide={handleCloseAddLessonPopup}
        createLesson={createLesson}
      />

      <RemoveModulePopup
        isOpen={isOpenRemoveModulePopup}
        handleHide={handleCloseRemoveModulePopup}
        deleteModule={deleteModule}
      />

      <EditModulePopup
        isOpen={isOpenEditModulePopup}
        handleHide={handleCloseEditModulePopup}
        selectedModule={selectedModule}
        editModule={editModule}
      />
    </div>
  );
};

export default ContentTab;
