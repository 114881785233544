import NewPost from "./NewPost";
import PostsList from "./PostsList";

import styles from "./Feed.module.scss";
import { useEffect, useState } from "react";
import { deleteNews, getNews, updateNews } from "api/news";
import { TPostsUpdate } from "typings/post";
import { DEFAULT_PARAMS } from "constants/table-params";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { useAppDispatch } from "utils/hooks";
import { phrases } from "constants/phrases";
import { useProfileData } from "../useProfileData";
import DeletePostPopup from "popups/detete-news/DeletePostPopup";
import { uploadFile } from "api/storage/uploadFile";
import { useParams } from "react-router-dom";
import { useGetRoles } from "utils/useGetRoles";
import useIntersection from "utils/useIntersection";
import useSWRInfinite from "swr/infinite";

const getKey = (adminId: number | undefined) => (pageIndex: number, previousPageData) => {
  if (previousPageData && previousPageData.pagination.totalPages - 1 < pageIndex) return null;
  return [String(pageIndex), adminId];
};

const fetcher = async ([key, adminId]) => {
  const page = Number(key);
  try {
    const response = await getNews({
      page: page,
      limit: DEFAULT_PARAMS.rows,
      adminId,
    });
    return response.data;
  } catch (err: any) {
    // @ts-ignore
    dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
  }
};

const Feed = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { profile } = useProfileData();

  const { isAdmin } = useGetRoles();

  const { data, size, setSize, isLoading, isValidating, mutate } = useSWRInfinite(
    getKey(isAdmin ? profile?.id : undefined),
    fetcher,
    {
      revalidateFirstPage: false,
    }
  );

  useEffect(() => {
    mutate();
  }, [mutate, profile.id]);

  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState<boolean>(false);
  const [deletePopupId, setDeletePopupId] = useState<number>(0);

  const openDeletePopup = (id: number) => {
    setDeletePopupId(id);
    setIsOpenDeletePopup(true);
  };

  const onDeletePost = async (id: number) => {
    try {
      const response = await deleteNews(id);
      if (response.status === 200 || response.status === 201) {
        dispatch(addSuccessMessage(phrases.post_success_deleted));
        mutate();
        setIsOpenDeletePopup(false);
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
  };

  const onEditPost = async (id: number, data: TPostsUpdate) => {
    try {
      let imageUrl;
      if (data.file) {
        const fileFormData = new FormData();
        fileFormData.append("file", data.file);
        const {
          data: { url },
        } = await uploadFile(fileFormData);
        imageUrl = url;
      }
      await updateNews(id, { ...data, imageUrl });
      mutate();
    } catch (error: any) {
      dispatch(addErrorMessage(error?.response?.data?.message || phrases.smthWentWrongText));
    }
  };

  const totalPages = data?.[data.length - 1]?.pagination?.totalPages;
  const [intersecting, ref] = useIntersection<HTMLDivElement>();

  const reachedEnd = size > (totalPages || 1);

  useEffect(() => {
    if (!reachedEnd && intersecting && !isLoading && !isValidating) {
      setSize(size + 1);
    }
  }, [intersecting, isLoading, isValidating, reachedEnd, ref, setSize, size]);

  const posts = (data || []).flatMap((el) => el.data);

  return (
    <div className={styles["feed-wrapper"]}>
      {!id || isAdmin ? <NewPost revalidateData={mutate} /> : null}
      <PostsList posts={posts} openDeletePopup={openDeletePopup} onEditPost={onEditPost} />
      <div className={styles.interjector} ref={ref}></div>
      <DeletePostPopup
        isOpen={isOpenDeletePopup}
        handleHide={() => setIsOpenDeletePopup(false)}
        deletePost={onDeletePost}
        post={posts.find((el) => el.id === deletePopupId)}
      />
    </div>
  );
};

export default Feed;
