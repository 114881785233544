import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { TDirection } from "typings/direction";

import styles from "./RemoveDirectionPopup.module.scss";

type RemoveDirectionPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  deleteDirection: () => void;
  selectedDirection: TDirection | null;
};

const RemoveDirectionPopup: FC<RemoveDirectionPopupProps> = (props) => {
  const closePopup = () => props.handleHide();

  const removeDirection = () => {
    props.deleteDirection();
    closePopup();
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <i className={classNames("pi pi-exclamation-circle", styles["warning-icon"])} />
        <p className={styles["title"]}>Вы уверены, что хотите удалить следующую запись?</p>
        <p className={styles["text"]}>{props.selectedDirection?.header}</p>
        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button className={styles["remove-button"]} onClick={removeDirection}>
            Удалить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default RemoveDirectionPopup;
