import { FC } from "react";
import { Login } from "features/Login/Login";

type LoginPageProps = {};

const LoginPage: FC<LoginPageProps> = () => {
  return <Login />;
};

export default LoginPage;
