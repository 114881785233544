import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { TDatesPairType } from "typings/dates";
import { FC, useState, useEffect } from "react";
import { getDateMetaObject, checkIfDateButtonActive, getUniqDates } from "utils/dates";
import {
  Calendar,
  CalendarProps,
  CalendarDateTemplateParams,
  CalendarChangeParams,
} from "primereact/calendar";
import {
  startOfToday,
  endOfToday,
  startOfWeek,
  endOfYesterday,
  startOfYesterday,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfDay,
  endOfDay,
  endOfWeek,
} from "date-fns";

import styles from "./PopupCalendar.module.scss";
import InputDate from "./InputDate";

type PopupCalendarProps = {
  dates?: TDatesPairType;
  setDates?: (dates: TDatesPairType) => void;
  notSearch?: boolean;
} & CalendarProps;

type buttonType = {
  name: string;
  nick: string;
  getDateRange?: () => TDatesPairType;
};

const PopupCalendar: FC<PopupCalendarProps> = ({
  dates: externalDates,
  setDates: externalSetDates,
  disabled,
  children,
  notSearch,
  inputClassName,
  ...rest
}) => {
  const { i18n } = useTranslation();
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const [dates, setDates] = useState<TDatesPairType>();

  const buttons: buttonType[] = [
    {
      name: "Сегодня",
      nick: "today",
      getDateRange: () => [startOfToday(), endOfToday()],
    },
    {
      name: "Вчера",
      nick: "yesterday",
      getDateRange: () => [startOfYesterday(), endOfYesterday()],
    },
    {
      name: "За неделю",
      nick: "week",
      getDateRange: () => [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
    },
    {
      name: "За месяц",
      nick: "month",
      getDateRange: () => [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      name: "За год",
      nick: "year",
      getDateRange: () => [startOfYear(new Date()), endOfYear(new Date())],
    },
    {
      name: "За все время",
      nick: "all",
      getDateRange: () => undefined,
    },
  ];

  const findActiveButton = (dates: TDatesPairType) =>
    buttons.find((button) =>
      checkIfDateButtonActive(button.getDateRange && button.getDateRange(), dates)
    );

  const activeButton = findActiveButton(dates);
  const externalActiveButton = findActiveButton(externalDates);

  const inputValue =
    externalActiveButton && !notSearch
      ? externalActiveButton.name
      : externalDates
        ? getUniqDates(externalDates)
          ?.map((date) =>
            date.toLocaleDateString("ru", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })
          )
          ?.join(" - ")
        : "";

  const metaDates = dates?.map((date) => date && getDateMetaObject(date));

  const dateTemplate = (date: CalendarDateTemplateParams) => {
    return (metaDates || []).some((metaDate) => {
      return (
        metaDate &&
        metaDate.day === date.day &&
        metaDate.month === date.month &&
        metaDate.year === date.year
      );
    }) ? (
      <div
        style={{
          backgroundColor: "#000A35",
          color: "#ffffff",
          fontWeight: "700",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          lineHeight: "40px",
          textAlign: "center",
          padding: 0,
        }}
      >
        {date.day}
      </div>
    ) : (
      date.day
    );
  };

  const onDateSelect = (dates: TDatesPairType) => {
    setDates(
      dates && [
        dates[0] ? startOfDay(dates[0]) : dates[0],
        dates[1] ? endOfDay(dates[1]) : dates[1],
      ]
    );
  };

  useEffect(() => {
    if (
      (!externalDates && dates) ||
      (externalDates &&
        (externalDates[0] !== (dates || [])[0] || externalDates[1] !== (dates || [])[1]))
    )
      setDates(externalDates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalDates]);

  const openCalendar = (event) => {
    event.preventDefault();

    if (!disabled) setPopupIsOpen(true);
  };
  const closeCalendar = () => {
    setDates(externalDates);
    setPopupIsOpen(false);
  };

  const onSubmit = () => {
    externalSetDates &&
      externalSetDates(dates && (dates[0] && !dates[1] ? [dates[0], endOfDay(dates[0])] : dates));
    setPopupIsOpen(false);
  };

  const onChange = (e: CalendarChangeParams) => {
    const checkIfCorrect = (value: Date | (Date | null)[] | undefined): value is TDatesPairType =>
      !!(Array.isArray(value) && value.length === 2);
    checkIfCorrect(e.value) && onDateSelect(e.value);
  };

  return (
    <>
      {children ? (
        <div onClick={openCalendar}>{children}</div>
      ) : (
        <span className="p-input-icon-left p-input-calendar">
          <i className={classNames("pi pi-calendar", styles["calendar-icon"])} />
          <InputText
            onFocus={(event) => event.target.blur()}
            onClick={openCalendar}
            placeholder={inputValue}
            className={classNames(
              {
                "p-input-calendar-open": popupIsOpen,
              },
              inputClassName
            )}
            disabled={disabled}
          />
        </span>
      )}
      <Dialog
        visible={popupIsOpen}
        modal
        onHide={closeCalendar}
        className={styles["dialog"]}
        showHeader={false}
      >
        <div className={styles["calendar-popup-wrapper"]}>
          {!notSearch && (
            <div className={styles["button-wrapper"]}>
              {buttons.map((button) => (
                <Button
                  key={button.nick}
                  className={classNames({
                    [styles["active"]]: button.nick === activeButton?.nick,
                    "p-button-outlined": button.nick !== activeButton?.nick,
                  })}
                  onClick={() => setDates(button.getDateRange ? button.getDateRange() : undefined)}
                >
                  {button.name}
                </Button>
              ))}
            </div>
          )}
          <div className={styles["calendar-controls-wrapper"]}>
            <div className={styles["calendar-wrapper"]}>
              <Calendar
                inline
                selectionMode="range"
                value={dates as [Date, Date]}
                onChange={onChange}
                dateTemplate={dateTemplate}
                {...rest}
                locale={i18n.language}
              />
            </div>
            <InputDate dates={dates} setDates={setDates} />
            <div className={styles["popup-buttons-wrapper"]}>
              <Button className={styles["cancel-button"]} onClick={closeCalendar}>
                Отмена
              </Button>
              <Button className={styles["add-button"]} onClick={onSubmit}>
                Искать
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PopupCalendar;
