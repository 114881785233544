import { FC } from "react";
import { TSubsription } from "typings/subscription";
import { tableControlsType } from "typings/table-controls";

import styles from "./Subscriptions.module.scss";
import HeaderLayout from "./HeaderLayout";
import SubscriptionsTable from "./table/SubscriptionsTable";

type SubscriptionsLayoutProps = {
  subscriptionsList: TSubsription[];
  params: tableControlsType;
  isLoadingTable: boolean;
  totalRecords: number;
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  status: string;
  changeStatus: (status: string) => void;
  downloadSubscriptions: () => void;
};

const SubscriptionsLayout: FC<SubscriptionsLayoutProps> = (props) => {
  return (
    <div className={styles["content-card"]}>
      <HeaderLayout
        status={props.status}
        changeStatus={props.changeStatus}
        downloadSubscriptions={props.downloadSubscriptions}
      />
      <SubscriptionsTable
        subscriptionsList={props.subscriptionsList}
        params={props.params}
        isLoadingTable={props.isLoadingTable}
        totalRecords={props.totalRecords}
        setParams={props.setParams}
      />
    </div>
  );
};

export default SubscriptionsLayout;
