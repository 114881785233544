import { FC } from "react";

import Users from "features/Users/Users";

type UsersPageProps = {};

const UsersPage: FC<UsersPageProps> = () => {
    return <Users />;
};

export default UsersPage;
