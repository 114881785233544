import { Button } from "primereact/button";
import { Card } from "primereact/card";
import styles from "./ChangePassword.module.scss";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Password } from "primereact/password";
import { changePassword } from "api/auth";
import { useDispatch } from "react-redux";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { phrases } from "constants/phrases";
import { removeAdminAsync } from "api/admins/removeAdmin";
import { useNavigate, useParams } from "react-router-dom";
import { useProfileData } from "../useProfileData";
import { logout } from "slices/authSlice";
import RemoveAdminPopup from "popups/remove-admin/RemoveAdminPopup";
import { useState } from "react";

type Inputs = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ChangePassword = () => {
  const {
    handleSubmit,
    control,
    formState: { isLoading, errors, isDirty },
    reset,
  } = useForm<Inputs>({
    shouldFocusError: false,
  });

  const dispatch = useDispatch();
  const { profile } = useProfileData();
  const { id } = useParams();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (data.newPassword !== data.confirmPassword) {
      dispatch(addErrorMessage(phrases.passwords_not_same));
      return;
    }
    try {
      await changePassword(data.oldPassword, data.newPassword);
      reset();
      dispatch(addSuccessMessage(phrases.password_success_updated));
    } catch (error: any) {
      const status = error?.response?.status;
      if (status === 400) {
        dispatch(addErrorMessage(phrases.wrong_password));
      } else {
        dispatch(addErrorMessage(error.response.data.message || phrases.smthWentWrongText));
      }
    }
  };

  const navigate = useNavigate();

  const deleteAccount = async () => {
    try {
      await removeAdminAsync(profile?.id);
      dispatch(addSuccessMessage(phrases.admin_sucess_deleted));
      if (id) {
        navigate("/admins");
      } else {
        logout();
      }
    } catch (error: any) {
      dispatch(addErrorMessage(error.response.data.message || phrases.smthWentWrongText));
    }
  };

  const [isOpenRemoveAdminPopup, setIsOpenRemoveAdminPopup] = useState<boolean>(false);

  return (
    <div className={styles["personal-data-form"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <div className={styles["personal-data-fields"]}>
            <h5 className={styles["personal-data-title"]}>Сменить пароль</h5>
            <div className="field">
              <label>Введите старый пароль</label>
              <Controller
                name="oldPassword"
                control={control}
                rules={{ required: { value: true, message: phrases.enter_password } }}
                render={({ field }) => (
                  <Password
                    id={field.name}
                    {...field}
                    feedback={false}
                    toggleMask
                    className="w-full p-inputtext-light-bg"
                  />
                )}
              />
              {errors.oldPassword && (
                <p className={styles["error-message"]}>{errors.oldPassword.message}</p>
              )}
            </div>
            <div className="field">
              <label>Введите новый пароль</label>
              <Controller
                name="newPassword"
                control={control}
                rules={{ required: { value: true, message: phrases.enter_new_password } }}
                render={({ field }) => (
                  <Password
                    id={field.name}
                    {...field}
                    feedback={false}
                    toggleMask
                    className="w-full p-inputtext-light-bg"
                  />
                )}
              />
              {errors.newPassword && (
                <p className={styles["error-message"]}>{errors.newPassword.message}</p>
              )}
            </div>
            <div className="field">
              <label>Повторите новый пароль</label>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{ required: { value: true, message: phrases.enter_confirm_password } }}
                render={({ field }) => {
                  return (
                    <Password
                      id={field.name}
                      {...field}
                      feedback={false}
                      toggleMask
                      className="w-full p-inputtext-light-bg"
                    />
                  );
                }}
              />
              {errors.confirmPassword && (
                <p className={styles["error-message"]}>{errors.confirmPassword.message}</p>
              )}
            </div>
          </div>
        </Card>
      </form>

      <div className={styles["footer"]}>
        <Button
          type="button"
          label="Удалить аккаунт"
          className="p-button-sm p-button-danger p-button-text"
          onClick={() => setIsOpenRemoveAdminPopup(true)}
          icon="pi pi-trash"
          iconPos="left"
        />
        <Button
          type="submit"
          label="Сохранить изменения"
          className="p-button-sm"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          disabled={!isDirty}
        />
      </div>
      <RemoveAdminPopup
        isOpen={isOpenRemoveAdminPopup}
        handleHide={() => setIsOpenRemoveAdminPopup(false)}
        selectedAdmin={profile}
        deleteAdmin={deleteAccount}
      />
    </div>
  );
};

export default ChangePassword;
