import { Button } from "primereact/button";
import styles from "./RemoveAdmin.module.scss";
import { useDispatch } from "react-redux";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { phrases } from "constants/phrases";
import { removeAdminAsync } from "api/admins/removeAdmin";
import { useNavigate } from "react-router-dom";
import { useProfileData } from "../useProfileData";
import RemoveAdminPopup from "popups/remove-admin/RemoveAdminPopup";
import { useState } from "react";

const RemoveAdmin = () => {
  const dispatch = useDispatch();
  const { profile } = useProfileData();

  const navigate = useNavigate();

  const deleteAccount = async () => {
    try {
      await removeAdminAsync(profile?.id);
      dispatch(addSuccessMessage(phrases.admin_sucess_deleted));
      navigate("/admins");
    } catch (error: any) {
      dispatch(addErrorMessage(error.response.data.message || phrases.smthWentWrongText));
    }
  };

  const [isOpenRemoveAdminPopup, setIsOpenRemoveAdminPopup] = useState<boolean>(false);

  return (
    <div className={styles["personal-data-form"]}>
      <div className={styles["footer"]}>
        <Button
          type="button"
          label="Удалить аккаунт"
          className="p-button-sm p-button-danger p-button-text"
          onClick={() => setIsOpenRemoveAdminPopup(true)}
          icon="pi pi-trash"
          iconPos="left"
        />
      </div>
      <RemoveAdminPopup
        isOpen={isOpenRemoveAdminPopup}
        handleHide={() => setIsOpenRemoveAdminPopup(false)}
        selectedAdmin={profile}
        deleteAdmin={deleteAccount}
      />
    </div>
  );
};

export default RemoveAdmin;
