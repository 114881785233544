import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useGetRoles } from "utils/useGetRoles";
import { DEFAULT_LINK } from "constants/course";
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { TLessonInfo, TLink } from "typings/course";

import styled from "./Lesson.module.scss";

type LinksProps = {
  lessonInfo: TLessonInfo;
  list: TLink[];
  setList: Dispatch<SetStateAction<TLink[]>>;
};

const Links: FC<LinksProps> = (props) => {
  const { list, setList } = props;

  const { isCurator } = useGetRoles();

  const addLink = () => setList((prev) => [...prev, DEFAULT_LINK]);

  const removeLink = (removeIndex: number) => {
    if (list.length <= 1) setList([DEFAULT_LINK]);
    else setList((prev) => prev.filter((_, index) => index !== removeIndex));
  };

  const changeLink = (updateIndex: number, updatedField: string, value: string) => {
    const newArray = list.map((el, index) => {
      if (index === updateIndex) {
        return {
          ...el,
          [updatedField]: value,
        };
      } else return el;
    });
    setList(newArray);
  };

  useEffect(() => {
    if (props.lessonInfo && props.lessonInfo.links.length === 0) {
      setList([DEFAULT_LINK]);
    } else setList(props.lessonInfo.links);
  }, [props.lessonInfo]);

  const returnDisableAddButton = () => !list[list.length - 1].link || !list[list.length - 1].name;

  return (
    <div className={styled["list-wrapper"]}>
      <p className={styled["list-title"]}>Ссылки</p>
      <div className={styled["list"]}>
        {list.map((el, index) => (
          <div className={styled["list-row"]} key={index}>
            <i className={classNames("pi pi-link")} />
            <InputText
              maxLength={700}
              value={el.link}
              onChange={(e) => changeLink(index, "link", e.target.value)}
              placeholder="test.com/test"
              className={classNames(styled["list-input"], styled["list-input-link"])}
              disabled={isCurator}
            />
            <InputText
              maxLength={700}
              value={el.name}
              onChange={(e) => changeLink(index, "name", e.target.value)}
              placeholder="Название"
              className={styled["list-input"]}
              disabled={isCurator}
            />
            {!isCurator && (
              <button onClick={() => removeLink(index)} className={styled["list-remove-button"]}>
                <i className={"pi pi-trash"} />
              </button>
            )}
          </div>
        ))}
      </div>
      {!isCurator && (
        <div className={styled["button-wrapper"]}>
          <Button
            className={styled["list-add-button"]}
            onClick={addLink}
            disabled={returnDisableAddButton()}
          >
            <i className="pi pi-external-link" />
            Добавить ссылку
          </Button>
        </div>
      )}
    </div>
  );
};

export default Links;
