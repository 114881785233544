import { InputText } from "primereact/inputtext";
import styles from "./NewPost.module.scss";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { createNews } from "api/news";
import { addErrorMessage } from "slices/toastSlice";
import { useDispatch } from "react-redux";
import { phrases } from "constants/phrases";
import SingleFileUpload from "components/common/single-file-upload/SingleFileUpload";
import { uploadFile } from "api/storage/uploadFile";
import { useParams } from "react-router";

type Inputs = {
  header: string;
  text: string;
  file: File;
};

type NewPostProps = {
  revalidateData: () => void;
};

const NewPost = ({ revalidateData }: NewPostProps) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    control,
    setValue,
  } = useForm<Inputs>();
  const { id } = useParams();

  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      let imageUrl;
      if (data.file) {
        const fileFormData = new FormData();
        fileFormData.append("file", data.file);
        const {
          data: { url },
        } = await uploadFile(fileFormData);
        imageUrl = url;
      }

      await createNews({ ...data, imageUrl }, id);
      setValue("header", "");
      setValue("text", "");
      revalidateData();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
  };

  return (
    <form className={styles["new-post"]}>
      <InputText
        maxLength={700}
        {...register("header", {
          required: {
            value: true,
            message: phrases.required_field,
          },
        })}
        placeholder="Заголовок новости"
        className={classNames(styles["title-input"], "p-inputtext-light-bg")}
      />
      {errors.header && <p className={styles["error"]}>{errors.header.message}</p>}
      <InputText
        {...register("text", {
          required: {
            value: true,
            message: phrases.required_field,
          },
        })}
        placeholder="Что нового?"
        className={classNames(styles["text-input"], "p-inputtext-light-bg")}
      />
      {errors.text && <p className={styles["error"]}>{errors.text.message}</p>}
      <div className={styles["footer"]}>
        <Controller
          name="file"
          control={control}
          render={({ field }) => <SingleFileUpload file={field.value} setFile={field.onChange} />}
        />
        <Button
          label="Опубликовать"
          className="p-button-sm"
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitting}
          disabled={!isDirty}
        />
      </div>
    </form>
  );
};

export default NewPost;
