import { login } from "api/auth";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {};

export const loginThunk = createAsyncThunk(
  "login",
  async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const { data } = await login(email, password);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state = initialState;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginThunk.fulfilled, (_, action) => {
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("role", action.payload.role);
    });
  },
});

export const { logout } = authSlice.actions;
