import { phrases } from "constants/phrases";
import { TPaymentsStatistics } from "typings/subscription";
import { DEFAULT_PARAMS } from "constants/table-params";
import { useAppDispatch } from "utils/hooks";
import { addErrorMessage } from "slices/toastSlice";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import styles from "./PaymentsStatistics.module.scss";

import PaymentsStatisticsLayout from "./PaymentsStatistics.layout";
import { getPaymentsStatistics } from "api/subscriptions/getPaymentsStatistics";
import { addMonths } from "date-fns";
import { tableControlsType } from "typings/table-controls";
import { getPaymentStatisticsCSV } from "api/subscriptions/getPaymentStatisticsCSV";

type PaymentsStatisticsProps = {};

const PaymentsStatistics: FC<PaymentsStatisticsProps> = () => {
  const [subscriptionsList, setPaymentsStatisticsList] = useState<TPaymentsStatistics[]>([]);
  const [totals, setTotals] = useState<TPaymentsStatistics>();
  const [params, setParams] = useState<tableControlsType>(DEFAULT_PARAMS);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [monthEnd, setMonthEnd] = useState<Date | null>(null);

  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const getPaymentsStatisticsList = useCallback(
    async (params) => {
      setIsLoadingTable(true);
      try {
        const response = await getPaymentsStatistics(params);
        if (response.status === 200 || response.status === 201) {
          setPaymentsStatisticsList(response.data.data);
          setTotals(response.data.totalDate);
          setTotalRecords(response.data.pagination.totalItems);
          setIsLoadingTable(false);
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      }
    },
    [dispatch]
  );

  const formatParams = useMemo(
    () => ({
      ...(monthEnd
        ? {
            startAt: addMonths(monthEnd, -1),
            finishAt: monthEnd,
            isAllTime: false,
          }
        : {
            isAllTime: true,
          }),
    }),
    [monthEnd]
  );

  useEffect(() => {
    getPaymentsStatisticsList({
      ...formatParams,
      limit: params.rows,
      page: params.page,
    });
  }, [formatParams, getPaymentsStatisticsList, monthEnd, params]);

  const downloadSubscriptions = () => {
    getPaymentStatisticsCSV(formatParams);
  };

  return (
    <div className={styles["page-container"]}>
      <PaymentsStatisticsLayout
        monthEnd={monthEnd}
        setMonthEnd={setMonthEnd}
        subscriptionsList={subscriptionsList}
        totals={totals}
        isLoadingTable={isLoadingTable}
        totalRecords={totalRecords}
        setParams={setParams}
        params={params}
        downloadSubscriptions={downloadSubscriptions}
      />
    </div>
  );
};

export default PaymentsStatistics;
