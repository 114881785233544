import { FC } from "react";
import { TUser } from "typings/user";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import styles from "./RemoveUserPopup.module.scss";

type RemoveUserPopupProps = {
    isOpen: boolean;
    handleHide: () => void;
    selectedUser: TUser | null;
    deleteUser: () => void;
};

const RemoveUserPopup: FC<RemoveUserPopupProps> = (props) => {
    const closePopup = () => props.handleHide();

    const removeUser = () => {
        props.deleteUser();
        closePopup();
    };

    return (
        <Dialog
            visible={props.isOpen}
            modal
            onHide={closePopup}
            className={styles["dialog"]}
            showHeader={false}
        >
            <div className={styles["content"]}>
                <i className={classNames("pi pi-exclamation-circle", styles["warning-icon"])} />
                <p className={styles["title"]}>Вы уверены, что хотите удалить следующую запись?</p>
                <p className={styles["text"]}>
                    {props.selectedUser
                        ? props.selectedUser.firstName + " " + props.selectedUser.lastName
                        : "Пользователь"}
                </p>
                <p className={styles["label"]}>Это ограничит доступ данного пользователя к сервису.</p>
                <div className={styles["buttons-container"]}>
                    <Button className={styles["cancel-button"]} onClick={closePopup}>
                        Отмена
                    </Button>
                    <Button className={styles["remove-button"]} onClick={removeUser}>
                        Удалить
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default RemoveUserPopup;
