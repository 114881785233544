import { FC } from "react";

import styled from "./AdminInfo.module.scss";
import AdminInfoLayout from "./AdminInfo.layout";

type AdminInfoProps = {};

const AdminInfo: FC<AdminInfoProps> = () => {
  return (
    <div className={styled["page-container"]}>
      <AdminInfoLayout />
    </div>
  );
};

export default AdminInfo;
