import ApplicationsIcon from "components/common/icons/Applications";

const ADMIN_MENU = [
  {
    label: "Пользователи",
    icon: "pi pi-users",
    to: "/users",
  },
  {
    label: "Администраторы",
    icon: "pi pi-user-plus",
    to: "/admins",
  },
  {
    label: "Курсы",
    icon: "pi pi-book",
    to: "/courses",
  },
  {
    label: "Направления",
    icon: "pi pi-compass",
    to: "/directions",
  },
  {
    label: "Услуги",
    customIcon: <ApplicationsIcon />,
    to: "/applications",
  },
  {
    label: "Продажи",
    icon: "pi pi-wallet",
    items: [
      {
        label: "Подписки",
        icon: "pi pi-share-alt",
        to: "/subscriptions",
      },
      {
        label: "Статистика",
        icon: "pi pi-chart-line",
        to: "/payments-statistics",
      },
      {
        label: "Причины отмены",
        icon: "pi pi-times",
        to: "/cancel-reasons",
      },
      {
        label: "Прибыль",
        icon: "pi pi-sitemap",
        to: "/referral-system",
      },
    ],
  },

  {
    label: "Настройки",
    icon: "pi pi-cog",
    items: [
      { label: "Промо", to: "settings-promo", icon: "pi pi-hashtag", noOnlyOnHide: true },
      { label: "Отзывы", to: "settings-reviews", icon: "pi pi-comment", noOnlyOnHide: true },
      {
        label: "Информация для администраторов",
        to: "/settings-admin-info",
        icon: "pi pi-ellipsis-h",
        noOnlyOnHide: true,
      },
      {
        label: "Заработок",
        to: "/settings-earnings",
        icon: "pi pi-hashtag",
        noOnlyOnHide: true,
      },
    ],
  },
  {
    label: "Рефералы",
    icon: "pi pi-bookmark",
    to: "/refferal-list",
  },
  {
    label: "Статистика по онбордингу",
    icon: "pi pi-chart-bar",
    to: "/onboarding-statistics",
  },
  {
    label: "Профиль",
    profileImage: true,
    to: "/profile",
    bottom: true,
  },
  {
    label: "Выход",
    icon: "pi pi-arrow-left",
    bottom: true,
    logout: true,
  },
];

const CURATOR_SPEAKER_MENU = [
  {
    label: "Профиль",
    profileImage: true,
    to: "/profile",
  },
  {
    label: "Поддержка",
    icon: "pi pi-tags",
    to: "/settings-admin-info",
  },
  {
    label: "Заработок",
    icon: "pi pi-wallet",
    to: "/earnings",
  },
  {
    label: "Выход",
    icon: "pi pi-arrow-left",
    bottom: true,
    logout: true,
  },
];

export const menu = () => {
  const role = localStorage.getItem("role");
  const IS_ADMIN = role === "admin";

  if (IS_ADMIN) return ADMIN_MENU;
  else return CURATOR_SPEAKER_MENU;
};
