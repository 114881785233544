import { TQuestion } from "typings/question";
import { Column } from "primereact/column";
import { DataTable, DataTableRowClickEventParams } from "primereact/datatable";
import { FC } from "react";
import { tableControlsType } from "typings/table-controls";

import formatDate from "utils/formatDate";

type QuestionsTableProps = {
  params: tableControlsType;
  questionsList: TQuestion[];
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  totalRecords: number;
  isLoadingTable: boolean;
  openAnswerPopup: (questionId: number) => void;
};

const QuestionsTable: FC<QuestionsTableProps> = (props) => {
  const onPage = (event) => {
    props.setParams(event);
  };

  const renderStatus = (rowData: TQuestion, { field }) => {
    const status = rowData[field];
    return status === 1 ? <i className="pi pi-check-square" /> : null;
  };

  const onRowClick = (event: DataTableRowClickEventParams) => {
    const data = event.data as TQuestion;
    props.openAnswerPopup(data.id);
  };

  const renderStudent = (rowData: TQuestion, { field }) => {
    const student = rowData[field];
    return `${student.firstName || ""} ${student.lastName || ""}`;
  };

  const renderCourse = (rowData: TQuestion, { field }) => {
    const course = rowData[field];
    return course.name;
  };

  const renderDate = (rowData: TQuestion, { field }) => {
    const date = rowData[field];
    return formatDate(new Date(date));
  };

  return (
    <DataTable
      value={props.questionsList}
      responsiveLayout="scroll"
      dataKey="id"
      lazy
      paginator
      first={props.params.first}
      rows={15}
      totalRecords={props.totalRecords}
      onPage={onPage}
      loading={props.isLoadingTable}
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      emptyMessage="Вопросы не найдены"
      onRowClick={onRowClick}
    >
      <Column
        field="student"
        header="Пользователь"
        style={{ width: "21%" }}
        body={renderStudent}
      ></Column>
      <Column field="question" header="Вопрос" style={{ width: "24%" }}></Column>
      <Column field="course" header="Курс" style={{ width: "24%" }} body={renderCourse}></Column>
      <Column field="createdAt" header="Дата" style={{ width: "14%" }} body={renderDate}></Column>
      <Column field="status" header="" style={{ width: "16%" }} body={renderStatus}></Column>
    </DataTable>
  );
};

export default QuestionsTable;
