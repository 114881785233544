import { phrases } from "constants/phrases";
import { TApplication } from "typings/applications";
import { DEFAULT_PARAMS } from "constants/table-params";
import { useAppDispatch } from "utils/hooks";
import { getApplications } from "api/applications/getApplicationsList";
import { tableControlsType } from "typings/table-controls";
import { updateStatusApplicationAsync } from "api/applications/updateStatusApplication";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, useCallback, useEffect, useState } from "react";

import ApplicationsLayout from "./Applications.layout";
import ChangeStatusApplicationPopup from "popups/change-status-application/ChangeStatusApplicationPopup";

type ApplicationsProps = {};

const Applications: FC<ApplicationsProps> = () => {
  const [applicationsList, setApplicationsList] = useState<TApplication[]>([]);
  const [params, setParams] = useState<tableControlsType>(DEFAULT_PARAMS);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [selectedApplication, setSelectedApplication] = useState<TApplication | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [status, setStatus] = useState<string | undefined>("new");
  const [isOpenChangeStatusApplicationPopup, setIsOpenChangeStatusApplicationPopup] =
    useState(false);

  const dispatch = useAppDispatch();

  const changeSelectedApplication = (application: TApplication) =>
    setSelectedApplication(application);

  const handleOpenChangeStatusApplicationPopup = () => setIsOpenChangeStatusApplicationPopup(true);
  const handleCloseChangeStatusApplicationPopup = () =>
    setIsOpenChangeStatusApplicationPopup(false);

  const getApplicationsList = useCallback(
    async (params) => {
      setIsLoadingTable(true);
      try {
        const response = await getApplications(params);
        if (response.status === 200 || response.status === 201) {
          setApplicationsList(response.data.data);
          setTotalRecords(response.data.pagination.totalItems);
          setIsLoadingTable(false);
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      }
    },
    [dispatch, status]
  );

  const changeStatus = (status: string | undefined) => setStatus(status);

  useEffect(() => {
    getApplicationsList({
      page: params.page,
      limit: params.rows,
      search: params.search || undefined,
      status: status !== "all" ? status : undefined,
    });
  }, [params, getApplicationsList]);

  const editStatusApplication = useCallback(
    async (newStatus: string) => {
      try {
        if (selectedApplication) {
          const response = await updateStatusApplicationAsync(selectedApplication?.id, newStatus);
          if (response.status === 200 || response.status === 201) {
            dispatch(addSuccessMessage(phrases.application_status_updated));
            getApplicationsList({
              page: params.page,
              limit: params.rows,
              search: params.search || undefined,
              status: status !== "all" ? status : undefined,
            });
          } else throw new Error();
        }
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      } finally {
        handleCloseChangeStatusApplicationPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch, selectedApplication, params, status]
  );

  return (
    <>
      <ApplicationsLayout
        applicationsList={applicationsList}
        params={params}
        setParams={setParams}
        isLoadingTable={isLoadingTable}
        totalRecords={totalRecords}
        changeSelectedApplication={changeSelectedApplication}
        status={status}
        changeStatus={changeStatus}
        handleOpenChangeStatusApplicationPopup={handleOpenChangeStatusApplicationPopup}
      />
      <ChangeStatusApplicationPopup
        isOpen={isOpenChangeStatusApplicationPopup}
        handleHide={handleCloseChangeStatusApplicationPopup}
        selectedApplication={selectedApplication}
        editStatusApplication={editStatusApplication}
      />
    </>
  );
};

export default Applications;
