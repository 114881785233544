import { FC } from "react";

import Promo from "features/Promo/Promo";

type PromoPageProps = {};

const PromoPage: FC<PromoPageProps> = () => {
    return <Promo />;
};

export default PromoPage;
