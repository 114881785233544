import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { FC, useState, useEffect, useRef } from "react";
import { getDateMetaObject } from "utils/dates";
import { Calendar, CalendarProps, CalendarDateTemplateParams } from "primereact/calendar";

import styles from "./PopupDateTimeCalendar.module.scss";
import { PropsWithChildren } from "react";

type PopupDateTimeCalendarProps = {
  date?: Date | null;
  setDate?: (date: Date | null) => void;
  spanClassName?: string;
} & CalendarProps;

const PopupDateTimeCalendar = ({
  date: externalDate = null,
  setDate: externalSetDate,
  disabled,
  children,
  showTime,
  inputClassName,
  spanClassName,
  placeholder,
  ...rest
}: PropsWithChildren<PopupDateTimeCalendarProps>) => {
  const { i18n } = useTranslation();
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const [date, setDate] = useState<Date | null>(null);

  const inputValue = externalDate
    ? externalDate.toLocaleString(
      "ru",
      showTime
        ? {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        }
        : { day: "numeric", month: "numeric", year: "numeric" }
    )
    : "";

  useEffect(() => {
    if ((!externalDate && date) || (externalDate && externalDate !== date)) setDate(externalDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalDate]);

  const openCalendar = () => !disabled && setPopupIsOpen(true);
  const closeCalendar = () => {
    setDate(externalDate);
    setPopupIsOpen(false);
  };

  const onSubmit = () => {
    externalSetDate && externalSetDate(date);
    setPopupIsOpen(false);
  };

  const onChange = (e) => setDate(e.value);

  const dateTemplate = (localDate: CalendarDateTemplateParams) => {
    const metaDate = date && getDateMetaObject(date);

    if (
      metaDate &&
      metaDate.day === localDate.day &&
      metaDate.month === localDate.month &&
      metaDate.year === localDate.year
    ) {
      return (
        <div
          style={{
            backgroundColor: "#000A35",
            color: "#ffffff",
            fontWeight: "700",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            lineHeight: "40px",
            textAlign: "center",
            padding: 0,
          }}
        >
          {localDate.day}
        </div>
      );
    }
    return localDate.day;
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (popupIsOpen) {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  }, [popupIsOpen]);

  return (
    <>
      {children ? (
        <div onClick={openCalendar}>{children}</div>
      ) : (
        <span className={classNames("p-input-icon-left p-input-calendar", spanClassName)}>
          <i className={classNames("pi pi-calendar", styles["calendar-icon"])} />
          <InputText
            onClick={openCalendar}
            // @ts-ignore
            ref={inputRef}
            placeholder={inputValue || placeholder}
            className={classNames(
              {
                "p-input-calendar-open": popupIsOpen,
              },
              inputClassName
            )}
            disabled={disabled}
          />
        </span>
      )}
      <Dialog
        visible={popupIsOpen}
        modal
        onHide={closeCalendar}
        className={styles["dialog"]}
        showHeader={false}
      >
        <div className={styles["calendar-popup-wrapper"]}>
          <div className={styles["calendar-controls-wrapper"]}>
            <div className={styles["calendar-wrapper"]}>
              <Calendar
                inline
                value={date as Date}
                onChange={onChange}
                dateFormat="mm/yy"
                showTime={showTime}
                locale={i18n.language}
                dateTemplate={dateTemplate}
                {...rest}
              />
            </div>
            <div className={styles["popup-buttons-wrapper"]}>
              <Button className={styles["cancel-button"]} onClick={closeCalendar}>
                Отмена
              </Button>
              <Button className={styles["add-button"]} onClick={onSubmit}>
                Выбрать
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PopupDateTimeCalendar;
