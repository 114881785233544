import { FC } from "react";
import { TApplication } from "typings/applications";
import { tableControlsType } from "typings/table-controls";

import styles from "./Applications.module.scss";
import HeaderLayout from "./HeaderLayout";
import ApplicationsTable from "./table/ApplicationsTable";

type ApplicationsLayoutProps = {
  applicationsList: TApplication[];
  params: tableControlsType;
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  isLoadingTable: boolean;
  totalRecords: number;
  changeSelectedApplication: (application: TApplication) => void;
  status: string | undefined;
  changeStatus: (status: string | undefined) => void;
  handleOpenChangeStatusApplicationPopup: () => void;
};

const ApplicationsLayout: FC<ApplicationsLayoutProps> = (props) => {
  return (
    <div className={styles["content-card"]}>
      <HeaderLayout status={props.status} changeStatus={props.changeStatus} />

      <ApplicationsTable
        applicationsList={props.applicationsList}
        params={props.params}
        setParams={props.setParams}
        isLoadingTable={props.isLoadingTable}
        totalRecords={props.totalRecords}
        changeSelectedApplication={props.changeSelectedApplication}
        handleOpenChangeStatusApplicationPopup={props.handleOpenChangeStatusApplicationPopup}
      />
    </div>
  );
};

export default ApplicationsLayout;
