import { TAdmin } from "typings/admin";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router";
import { FC, useEffect, useState } from "react";
import { tableControlsType } from "typings/table-controls";
import { DataTable, DataTableRowClickEventParams } from "primereact/datatable";
import { renderColumn, renderDate, renderFIO, renderStatus, renderType } from "./actions";

import styled from "./AdminsTable.module.scss";
import useDebounce from "utils/use-debounce";

type AdminsTableProps = {
  params: tableControlsType;
  adminsList: TAdmin[];
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  totalRecords: number;
  isLoadingTable: boolean;
  handleOpenRemoveAdminPopup: () => void;
  changeSelectedAdmin: (data: TAdmin) => void;
  handleOpenEditAdminPopup: () => void;
  handleOpenEditAdminPasswordPopup: () => void;
};

const AdminsTable: FC<AdminsTableProps> = (props) => {
  const [searchLocalValue, setSearchLocalValue] = useState<string>("");

  const navigate = useNavigate();

  const debouncedSearchValue = useDebounce(searchLocalValue, 500);

  useEffect(() => {
    if (debouncedSearchValue !== props.params?.search) {
      props.setParams((prev) => ({ ...prev, search: debouncedSearchValue }));
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (searchLocalValue !== props.params?.search && typeof props.params?.search === "string") {
      setSearchLocalValue(props.params?.search);
    }
  }, [props.params?.search]);

  const onPage = (event) => {
    props.setParams(event);
  };

  const renderAction = (rowData: TAdmin) => {
    return (
      <div className={styled["col-action-wrapper"]} onClick={() => {}}>
        <button
          className={classNames(styled["action-btn"], styled["edit-btn"], styled["settings-btn"])}
          onClick={() => {
            props.changeSelectedAdmin(rowData);
            props.handleOpenEditAdminPopup();
          }}
        >
          <i className="pi pi-pencil" />
        </button>
        <button
          className={classNames(styled["action-btn"], styled["edit-btn"], styled["settings-btn"])}
          onClick={() => {
            props.changeSelectedAdmin(rowData);
            props.handleOpenEditAdminPasswordPopup();
          }}
        >
          <i className="pi pi-key" />
        </button>
        <button
          className={classNames(styled["action-btn"], styled["delete-btn"], styled["settings-btn"])}
          onClick={() => {
            props.changeSelectedAdmin(rowData);
            props.handleOpenRemoveAdminPopup();
          }}
        >
          <i className="pi pi-trash" />
        </button>
      </div>
    );
  };

  const onRowClick = (event: DataTableRowClickEventParams) => {
    const data = event.data as TAdmin;
    navigate(`${data.id}/courses`);
  };

  return (
    <div className={styled["table-wrapper"]}>
      <div className={styled["table-header"]}>
        <p className={styled["table-name"]}>Администраторы</p>
        <span
          className={classNames("p-input-icon-left p-main-input", styled["search-input-wrapper"])}
        >
          <i className="pi pi-search" />
          <InputText
            maxLength={700}
            value={searchLocalValue}
            type="text"
            placeholder="Поиск"
            onChange={(e) => setSearchLocalValue(e.target.value)}
            className={styled["search-input"]}
          />
        </span>
      </div>
      <DataTable
        value={props.adminsList}
        responsiveLayout="scroll"
        dataKey="id"
        lazy
        paginator
        first={props.params.first}
        rows={15}
        totalRecords={props.totalRecords}
        onPage={onPage}
        loading={props.isLoadingTable}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        emptyMessage="Администраторов не найдено"
        onRowClick={onRowClick}
      >
        <Column field="id" header="ID" style={{ width: "10%" }} body={renderColumn("id")}></Column>
        <Column field="fio" header="ФИО" style={{ width: "20%" }} body={renderFIO}></Column>
        <Column field="role" header="Роль" style={{ width: "10%" }} body={renderType}></Column>
        <Column
          field="createdAt"
          header="Создано"
          style={{ width: "10%" }}
          body={renderDate("createdAt")}
        ></Column>
        <Column
          field="updatedAt"
          header="Обновлено"
          style={{ width: "10%" }}
          body={renderDate("updatedAt")}
        ></Column>
        <Column
          field="email"
          header="Email"
          style={{ width: "10%" }}
          body={renderColumn("email")}
        ></Column>
        <Column
          field="status"
          header="Статус"
          style={{ width: "20%" }}
          body={renderStatus}
        ></Column>
        <Column field="actions" header="" style={{ width: "10%" }} body={renderAction}></Column>
      </DataTable>
    </div>
  );
};

export default AdminsTable;
