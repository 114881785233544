import { FC, ChangeEvent, KeyboardEvent } from "react";

import InputMask from "react-input-mask";

const formatChars = {
  n: "[0-1]",
  m: "[0-9]",
  e: "[0-3]",
  d: "[0-9]",
  z: "[1-2]",
  y: "[0-9]",
};

type ReactInputDateMaskProps = {
  value?: string;
  onChange?: (value: string) => void;
  onBlur: () => void;
  inputRef?: any;
};

const ReactInputDateMask: FC<ReactInputDateMaskProps> = (props) => {
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(event.target.value);
  };

  const onKeyUp = (event: KeyboardEvent<HTMLImageElement>) => {
    if (event.key === "Enter") {
      props.inputRef.current.blur();
      props.onBlur();
    }
  };

  return (
    <InputMask
      className="p-inputtext"
      alwaysShowMask={false}
      formatChars={formatChars}
      mask="ed.nm.zyyy - ed.nm.zyyy"
      maskChar={"_"}
      value={props.value}
      onChange={onInputChange}
      placeholder={"дд.мм.гггг - дд.мм.гггг"}
      onBlur={props.onBlur}
      onKeyUp={onKeyUp}
      inputRef={(ref) => {
        if (ref) {
          props.inputRef.current = ref;
        }
      }}
    />
  );
};

export default ReactInputDateMask;
