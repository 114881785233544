import styles from "./Courses.module.scss";
import { TabMenu } from "primereact/tabmenu";
import CoursesTable from "./table/CoursesTable";
import { DEFAULT_PARAMS } from "constants/table-params";
import { useCallback, useEffect, useMemo, useState } from "react";
import { tableControlsType } from "typings/table-controls";
import { TCourse, TCourseStats } from "typings/course";
import TakeOffCourseModal from "popups/take-off-course/TakeOffCourseModal";
import { useParams } from "react-router-dom";
import { getMyCourses } from "api/courses/getMyCourses";
import { getCoursesMyStat } from "api/courses/getCoursesMyStat";
import { phrases } from "constants/phrases";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { useAppDispatch } from "utils/hooks";
import RemoveCoursePopup from "popups/remove-course/RemoveCoursePopup";
import { removeCourseAsync } from "api/courses/removeCourse";
import { useProfileData } from "../useProfileData";
import { assignToCourse } from "api/admins/assignToCourse";
import { COURSE_STATUSES } from "constants/course";
import { getSort } from "utils/getSort";

const itemsTable = [
  { label: "Опубликованные", key: COURSE_STATUSES.publish },
  { label: "Нужны правки", key: COURSE_STATUSES.returnRevision },
  { label: "На рассмотрении", key: COURSE_STATUSES.forVerification },
  { label: "Черновики", key: COURSE_STATUSES.draft },
  { label: "Деактивированные", key: COURSE_STATUSES.blocked },
] as const;

const Courses = () => {
  const [coursesList, setCoursesList] = useState<TCourse[]>([]);
  const [params, setParams] = useState<tableControlsType>(DEFAULT_PARAMS);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isOpenTakeOffCoursePopup, setIsOpenTakeOffCoursePopup] = useState<boolean>(false);
  const { id } = useParams();
  const { profile } = useProfileData();
  let [status, setStatus] = useState(3);

  const handleCloseTakeOffCoursePopup = () => setIsOpenTakeOffCoursePopup(false);

  const handleOpenTakeOffCoursePopup = () => setIsOpenTakeOffCoursePopup(true);

  const [isOpenRemoveCoursePopup, setIsOpenRemoveCoursePopup] = useState<boolean>(false);

  const handleOpenRemoveCoursePopup = () => setIsOpenRemoveCoursePopup(true);
  const handleCloseRemoveCoursePopup = () => setIsOpenRemoveCoursePopup(false);

  const dispatch = useAppDispatch();

  const getCoursesList = useCallback(async () => {
    setIsLoadingTable(true);
    const res = await getMyCourses({
      page: params.page,
      limit: params.rows,
      adminId: id ? Number(id) : undefined,
      sort: getSort(params),
      status,
    });

    setCoursesList(res.data.data);
    setTotalRecords(res.data.pagination.totalItems);
    setIsLoadingTable(false);
  }, [id, params, status]);

  useEffect(() => {
    getCoursesList();
  }, [getCoursesList]);

  const [coursesStats, setCoursesStats] = useState<TCourseStats | null>(null);

  const getCoursesStats = useCallback(async () => {
    try {
      const res = await getCoursesMyStat({ adminId: id ? Number(id) : undefined });
      setCoursesStats(res.data as TCourseStats);
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
  }, [dispatch, id]);

  useEffect(() => {
    getCoursesStats();
  }, [getCoursesStats]);

  const activeIndex = itemsTable.findIndex((item) => item.key === status);

  const itemsTableWithStat = useMemo(
    () =>
      itemsTable.map((el) => ({
        ...el,
        label:
          coursesStats && coursesStats[el.key] ? `${el.label} (${coursesStats[el.key]})` : el.label,
      })),
    [coursesStats]
  );

  const onTabChange = (event) => {
    const {
      value: { key },
    } = event;
    setStatus(key);
  };

  const [selectedCourse, setSelectedCourse] = useState<TCourse | null>(null);

  const deleteCourse = useCallback(async () => {
    setIsLoadingTable(true);
    try {
      if (selectedCourse) {
        const response = await removeCourseAsync(selectedCourse.id);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.course_sucess_deleted));
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    } finally {
      handleCloseRemoveCoursePopup();
      setIsLoadingTable(false);
    }
  }, [dispatch, selectedCourse]);

  const removeFromCourse = useCallback(async () => {
    setIsLoadingTable(true);
    try {
      if (selectedCourse) {
        const response = await assignToCourse(profile.id, {
          courseId: selectedCourse.id,
          isDelete: true,
        });
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.removed_from_course_success));
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    } finally {
      handleCloseRemoveCoursePopup();
      setIsLoadingTable(false);
    }
  }, [dispatch, profile.id, selectedCourse]);

  return (
    <div className={styles["corses-wrapper"]}>
      <div className={styles["corses-table-tabs"]}>
        <TabMenu model={itemsTableWithStat} activeIndex={activeIndex} onTabChange={onTabChange} />
      </div>
      <CoursesTable
        params={params}
        setParams={setParams}
        coursesList={coursesList}
        totalRecords={totalRecords}
        isLoadingTable={isLoadingTable}
        setSelectedCourse={setSelectedCourse}
        handleOpenTakeOffCoursePopup={handleOpenTakeOffCoursePopup}
        handleOpenRemoveCoursePopup={handleOpenRemoveCoursePopup}
      />
      <TakeOffCourseModal
        isOpen={isOpenTakeOffCoursePopup}
        handleHide={handleCloseTakeOffCoursePopup}
        profile={profile}
        removeFromCourse={removeFromCourse}
      />
      <RemoveCoursePopup
        isOpen={isOpenRemoveCoursePopup}
        handleHide={handleCloseRemoveCoursePopup}
        deleteCourse={deleteCourse}
      />
    </div>
  );
};

export default Courses;
