import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { TNewUser, TUser } from "typings/user";
import { FC, useEffect, useState } from "react";

import styles from "./EditUserPopup.module.scss";

type EditUserPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedUser: TUser | null;
  updateUser: (userData: TNewUser) => void;
};

const EditUserPopup: FC<EditUserPopupProps> = (props) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const closePopup = () => {
    props.handleHide();
  };

  useEffect(() => {
    if (props.isOpen && props.selectedUser) {
      setFirstName(props.selectedUser.firstName);
      setLastName(props.selectedUser.lastName);
    }
  }, [props.isOpen, props.selectedUser]);

  const editUser = () => {
    props.updateUser({ firstName, lastName });
    closePopup();
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <p className={styles["title"]}>Редактирование пользователя</p>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Имя</p>
          <InputText
            maxLength={600}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Имя"
            className={styles["input"]}
          />
        </div>
        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Фамилия</p>
          <InputText
            maxLength={600}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Фамилия"
            className={styles["input"]}
          />
        </div>
        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button
            className={styles["edit-button"]}
            onClick={editUser}
            disabled={
              !firstName ||
              !lastName ||
              (props.selectedUser?.firstName === firstName &&
                props.selectedUser.lastName === lastName)
            }
          >
            Изменить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditUserPopup;
