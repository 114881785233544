import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { TNewModule } from "typings/course";
import { FC, useEffect, useState } from "react";

import styles from "./AddModulePopup.module.scss";
import { InputTextarea } from "primereact/inputtextarea";

type AddModulePopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  createModule: (moduleData: TNewModule) => void;
};

const AddModulePopup: FC<AddModulePopupProps> = (props) => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const closePopup = () => {
    props.handleHide();
  };

  useEffect(() => {
    return () => {
      setName("");
      setDescription("");
    };
  }, [props.isOpen]);

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <p className={styles["title"]}>Добавление нового модуля</p>

        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Название</p>
          <InputText
            maxLength={700}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Название"
            className={styles["input"]}
          />
        </div>

        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Описание</p>
          <InputTextarea
            maxLength={1300}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={3}
            cols={30}
            autoResize
            placeholder="Описание"
            className={styles["text-area"]}
          />
        </div>

        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button
            className={styles["add-button"]}
            onClick={() => props.createModule({ name, description })}
            disabled={!name}
          >
            Добавить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AddModulePopup;
