import { Card } from "primereact/card";
import styles from "./Balance.module.scss";
import { useProfileData } from "../useProfileData";
import { formatMoney } from "utils/numberFormat";

const Balance = () => {
  const { balanceStatistics } = useProfileData();

  return (
    <Card>
      <div className={styles["balance"]}>
        <h5 className={styles["title"]}>Сумма доходов</h5>
        <p className={styles["balance-value"]}>{`${formatMoney(
          balanceStatistics?.balanceWithdraws ?? 0
        )}`}</p>
        <div className={styles["info-title"]}>
          <p className={styles["info-title-text"]}>Информация про способ вывода средств</p>
        </div>
        <div className={styles["info-wrapper"]}>
          <p>Для проведения выплаты свяжитесь с менеджером</p>
          <div className={styles["social-media-icons"]}>
            <i className="pi pi-telegram" />
            <i className="pi pi-envelope" />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Balance;
