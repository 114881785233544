import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import styles from "./RemoveCoursePopup.module.scss";

type RemoveCoursePopupProps = {
    isOpen: boolean;
    handleHide: () => void;
    deleteCourse: () => void;
};

const RemoveCoursePopup: FC<RemoveCoursePopupProps> = (props) => {
    const closePopup = () => props.handleHide();

    return (
        <Dialog
            visible={props.isOpen}
            modal
            onHide={closePopup}
            className={styles["dialog"]}
            showHeader={false}
        >
            <div className={styles["content"]}>
                <i className={classNames("pi pi-info-circle", styles["warning-icon"])} />
                <p className={styles["title"]}>Удалить курс и весь контент в нем?</p>
                <div className={styles["buttons-container"]}>
                    <Button className={styles["cancel-button"]} onClick={closePopup}>
                        Отмена
                    </Button>
                    <Button className={styles["remove-button"]} onClick={props.deleteCourse}>
                        Удалить
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default RemoveCoursePopup;
