import { ERRORS } from "constants/errors";
import { phrases } from "constants/phrases";
import { DEFAULT_PARAMS } from "constants/table-params";
import { getAdminsAsync } from "api/admins/getAdmins";
import { useAppDispatch } from "utils/hooks";
import { removeAdminAsync } from "api/admins/removeAdmin";
import { TAdmin, TNewAdmin } from "typings/admin";
import { tableControlsType } from "typings/table-controls";
import { createNewAdminAsync } from "api/admins/addAdmin";
import { FILTER_ADMIN_ROLES, STATUSES } from "constants/filters";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, useCallback, useEffect, useState } from "react";

import styled from "./Admins.module.scss";
import AdminsLayout from "./Admins.layout";
import AddAdminPopup from "popups/add-admin/AddAdminPopup";
import EditAdminPopup from "popups/edit-admin/EditAdminPopup";
import RemoveAdminPopup from "popups/remove-admin/RemoveAdminPopup";
import { updateAdminAsync } from "api/admins/editAdmin";
import EditUserPasswordPopup from "popups/edit-user-password/EditUserPasswordPopup";
import { editAdminPassword } from "api/admins/editAdminPassword";

type AdminsProps = {};

const Admins: FC<AdminsProps> = () => {
  const [adminsList, setAdminsList] = useState<TAdmin[]>([]);
  const [params, setParams] = useState<tableControlsType>(DEFAULT_PARAMS);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [selectedAdmin, setSelectedAdmin] = useState<TAdmin | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isOpenAddAdminPopup, setIsOpenAddAdminPopup] = useState<boolean>(false);
  const [isOpenRemoveAdminPopup, setIsOpenRemoveAdminPopup] = useState<boolean>(false);
  const [isOpenEditAdminPopup, setIsOpenEditAdminPopup] = useState<boolean>(false);
  const [isOpenEditAdminPasswordPopup, setIsOpenEditAdminPasswordPopup] = useState<boolean>(false);
  const [role, setRole] = useState<string>(FILTER_ADMIN_ROLES[0].value);
  const [status, setStatus] = useState<string>(STATUSES[0].value);

  const dispatch = useAppDispatch();

  const changeSelectedAdmin = (data: TAdmin) => setSelectedAdmin(data);

  const changeRole = (role: string) => setRole(role);

  const changeStatus = (status: string) => setStatus(status);

  const handleOpenAddAdminPopup = () => setIsOpenAddAdminPopup(true);
  const handleCloseAddAdminPopup = () => setIsOpenAddAdminPopup(false);

  const handleOpenRemoveAdminPopup = () => setIsOpenRemoveAdminPopup(true);
  const handleCloseRemoveAdminPopup = () => setIsOpenRemoveAdminPopup(false);

  const handleOpenEditAdminPopup = () => setIsOpenEditAdminPopup(true);
  const handleCloseEditAdminPopup = () => setIsOpenEditAdminPopup(false);

  const handleOpenEditAdminPasswordPopup = () => setIsOpenEditAdminPasswordPopup(true);
  const handleCloseEditAdminPasswordPopup = () => setIsOpenEditAdminPasswordPopup(false);

  const getAdminsList = useCallback(
    async (params) => {
      setIsLoadingTable(true);
      try {
        const response = await getAdminsAsync(params);
        if (response.status === 200 || response.status === 201) {
          setAdminsList(response.data.data);
          setTotalRecords(response.data.pagination.totalItems);
          setIsLoadingTable(false);
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getAdminsList({
      page: params.page,
      limit: params.rows,
      search: params.search || undefined,
      role: role !== "all" ? role : undefined,
      status: status !== "all" ? Number(status) : undefined,
    });
  }, [params, getAdminsList, role, status]);

  const createAdmin = useCallback(
    async (adminData: TNewAdmin) => {
      setIsLoadingTable(true);
      try {
        const response = await createNewAdminAsync(adminData);
        if (response.status === 200 || response.status === 201) {
          getAdminsList({
            page: params.page,
            limit: params.rows,
            search: params.search || undefined,
            role: role !== "all" ? role : undefined,
            status: status !== "all" ? Number(status) : undefined,
          });
          dispatch(addSuccessMessage(phrases.admin_success_added));
        } else throw new Error();
      } catch (err: any) {
        if (err.response.data.message.includes(ERRORS.invalid_email.backend_value))
          return dispatch(addErrorMessage(ERRORS.invalid_email.alert));
        else if (err.response.data.message.includes(ERRORS.uniq_email_required.backend_value))
          return dispatch(addErrorMessage(ERRORS.uniq_email_required.alert));
        else return dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrong));
      } finally {
        handleCloseAddAdminPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch, getAdminsList, params.page, params.rows, params.search, role, status]
  );

  const deleteAdmin = useCallback(async () => {
    setIsLoadingTable(true);
    try {
      if (selectedAdmin) {
        const response = await removeAdminAsync(selectedAdmin.id);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.admin_sucess_deleted));
          getAdminsList({
            page: params.page,
            limit: params.rows,
            search: params.search || undefined,
            role: role !== "all" ? role : undefined,
            status: status !== "all" ? Number(status) : undefined,
          });
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    } finally {
      handleCloseRemoveAdminPopup();
      setIsLoadingTable(false);
    }
  }, [
    dispatch,
    getAdminsList,
    params.page,
    params.rows,
    params.search,
    role,
    selectedAdmin,
    status,
  ]);

  const updateAdmin = useCallback(
    async (adminData: TNewAdmin) => {
      setIsLoadingTable(true);
      try {
        if (selectedAdmin) {
          const response = await updateAdminAsync(selectedAdmin?.id, adminData);
          if (response.status === 200 || response.status === 201) {
            getAdminsList({
              page: params.page,
              limit: params.rows,
              search: params.search || undefined,
              role: role !== "all" ? role : undefined,
              status: status !== "all" ? Number(status) : undefined,
            });
            dispatch(addSuccessMessage(phrases.admin_success_updated));
          } else throw new Error();
        } else return;
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseEditAdminPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch, getAdminsList, params.page, params.rows, params.search, role, selectedAdmin, status]
  );

  const updateAdminPassword = useCallback(
    async (password: string) => {
      setIsLoadingTable(true);
      try {
        if (selectedAdmin) {
          const response = await editAdminPassword(selectedAdmin?.id, password);
          if (response.status === 200 || response.status === 201) {
            getAdminsList({
              page: params.page,
              limit: params.rows,
              search: params.search || undefined,
              status: status !== "all" ? Number(status) : undefined,
            });
            dispatch(addSuccessMessage(phrases.user_password_success_updated));
          } else throw new Error();
        } else return;
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseEditAdminPasswordPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch, getAdminsList, params.page, params.rows, params.search, selectedAdmin, status]
  );

  return (
    <div className={styled["page-container"]}>
      <AdminsLayout
        params={params}
        setParams={setParams}
        adminsList={adminsList}
        totalRecords={totalRecords}
        isLoadingTable={isLoadingTable}
        handleOpenAddAdminPopup={handleOpenAddAdminPopup}
        handleOpenRemoveAdminPopup={handleOpenRemoveAdminPopup}
        changeSelectedAdmin={changeSelectedAdmin}
        handleOpenEditAdminPopup={handleOpenEditAdminPopup}
        handleOpenEditAdminPasswordPopup={handleOpenEditAdminPasswordPopup}
        role={role}
        status={status}
        changeRole={changeRole}
        changeStatus={changeStatus}
      />

      <AddAdminPopup
        isOpen={isOpenAddAdminPopup}
        handleHide={handleCloseAddAdminPopup}
        createAdmin={createAdmin}
      />

      <RemoveAdminPopup
        isOpen={isOpenRemoveAdminPopup}
        handleHide={handleCloseRemoveAdminPopup}
        selectedAdmin={selectedAdmin}
        deleteAdmin={deleteAdmin}
      />

      <EditAdminPopup
        isOpen={isOpenEditAdminPopup}
        handleHide={handleCloseEditAdminPopup}
        selectedAdmin={selectedAdmin}
        updateAdmin={updateAdmin}
      />
      <EditUserPasswordPopup
        isOpen={isOpenEditAdminPasswordPopup}
        handleHide={handleCloseEditAdminPasswordPopup}
        updateUserPassword={updateAdminPassword}
      />
    </div>
  );
};

export default Admins;
