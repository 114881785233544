import { FC } from "react";
import { TCourse } from "typings/course";
import { tableControlsType } from "typings/table-controls";

import styled from "./Courses.module.scss";
import HeaderLayout from "./HeaderLayout";
import CoursesTable from "./table/CoursesTable";

type CoursesLayoutProps = {
  params: tableControlsType;
  coursesList: TCourse[];
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  totalRecords: number;
  isLoadingTable: boolean;
  handleOpenRemoveCoursePopup: () => void;
  changeSelectedCourse: (data: TCourse) => void;
  handleOpenAddCoursePopup: () => void;
  directions: { value: number; label: string }[];
  selectedDirection: number;
  changeSelectedDirection: (direction: number) => void;
  speakers: { value: number; label: string }[];
  selectedSpeaker: number;
  changeSelectedSpeaker: (speakerId: number) => void;
};

const CoursesLayout: FC<CoursesLayoutProps> = (props) => {
  return (
    <div className={styled["content-card"]}>
      <HeaderLayout
        handleOpenAddCoursePopup={props.handleOpenAddCoursePopup}
        directions={props.directions}
        selectedDirection={props.selectedDirection}
        changeSelectedDirection={props.changeSelectedDirection}
        speakers={props.speakers}
        selectedSpeaker={props.selectedSpeaker}
        changeSelectedSpeaker={props.changeSelectedSpeaker}
      />
      <CoursesTable
        params={props.params}
        coursesList={props.coursesList}
        setParams={props.setParams}
        totalRecords={props.totalRecords}
        isLoadingTable={props.isLoadingTable}
        handleOpenRemoveCoursePopup={props.handleOpenRemoveCoursePopup}
        changeSelectedCourse={props.changeSelectedCourse}
      />
    </div>
  );
};

export default CoursesLayout;
