import { phrases } from "constants/phrases";
import { useAppDispatch } from "utils/hooks";
import { updateLessonAsync } from "api/courses/editLesson";
import { removeLessonAsync } from "api/courses/removeLesson";
import { FC, useCallback, useState } from "react";
import { TLessonInfo, TUpdateLessonData } from "typings/course";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { useLoaderData, useNavigate, useParams, useRevalidator } from "react-router-dom";

import styled from "./Lesson.module.scss";
import LessonLayout from "./Lesson.layout";
import RemoveLessonPopup from "popups/remove-lesson/RemoveLessonPopup";

type LessonProps = {};

const Lesson: FC<LessonProps> = () => {
  const lessonInfo = useLoaderData() as TLessonInfo;
  const [isOpenRemoveLessonPopup, setIsOpenRemoveLessonPopup] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleOpenRemoveLessonPopup = () => setIsOpenRemoveLessonPopup(true);
  const handleCloseRemoveLessonPopup = () => setIsOpenRemoveLessonPopup(false);

  const { id } = useParams();
  const { lessonId } = useParams();

  const revalidator = useRevalidator();

  const deleteLesson = useCallback(async () => {
    try {
      if (lessonId) {
        const response = await removeLessonAsync(Number(lessonId));
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.lesson_sucess_deleted));
          if (id) {
            navigate(`/courses/${id}`);
          } else navigate(`/courses`);
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    } finally {
      handleCloseRemoveLessonPopup();
    }
  }, [dispatch, id, lessonId, navigate]);

  const updateLesson = useCallback(
    async (lessonData: TUpdateLessonData) => {
      try {
        if (lessonInfo && lessonInfo.id) {
          const response = await updateLessonAsync(lessonInfo.id, lessonData);
          if (response.status === 200 || response.status === 201) {
            revalidator.revalidate();
            dispatch(addSuccessMessage(phrases.lesson_sucess_updated));
          } else throw new Error();
        }
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      }
    },
    [dispatch, lessonInfo, revalidator]
  );

  return (
    <div className={styled["page-container"]}>
      <LessonLayout
        handleOpenRemoveLessonPopup={handleOpenRemoveLessonPopup}
        lessonInfo={lessonInfo}
        updateLesson={updateLesson}
      />
      <RemoveLessonPopup
        isOpen={isOpenRemoveLessonPopup}
        handleHide={handleCloseRemoveLessonPopup}
        deleteLesson={deleteLesson}
      />
    </div>
  );
};

export default Lesson;
