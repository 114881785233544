import { FC } from "react";
import { TUser } from "typings/user";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import styles from "./BlockUserPopup.module.scss";

type BlockUserPopupProps = {
    isOpen: boolean;
    handleHide: () => void;
    selectedUser: TUser | null;
    editStatusUser: (newStatus: number) => void;
};

const BlockUserPopup: FC<BlockUserPopupProps> = (props) => {
    const closePopup = () => props.handleHide();

    const blockUser = () => {
        props.editStatusUser(0);
        closePopup();
    };

    return (
        <Dialog
            visible={props.isOpen}
            modal
            onHide={closePopup}
            className={styles["dialog"]}
            showHeader={false}
        >
            <div className={styles["content"]}>
                <i className={classNames("pi pi-ban", styles["warning-icon"])} />
                <p className={styles["title"]}>Вы уверены, что хотите заблокировать следующую запись?</p>
                <p className={styles["text"]}>
                    {props.selectedUser
                        ? props.selectedUser.firstName + " " + props.selectedUser.lastName
                        : "Пользователь"}
                </p>
                <p className={styles["label"]}>Это временно ограничит доступ пользователя к сервису.</p>
                <div className={styles["buttons-container"]}>
                    <Button className={styles["cancel-button"]} onClick={closePopup}>
                        Отмена
                    </Button>
                    <Button className={styles["block-button"]} onClick={blockUser}>
                        Блок
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default BlockUserPopup;
