import { Link } from "react-router-dom";
import styles from "./error.module.scss";
import { Button } from "primereact/button";

const NoAccessPage = () => {
  return (
    <div className={styles["error-page"]}>
      <h2>Нет доступа</h2>
      <Link to={`/profile`}>
        <Button>Вернуться к профилю</Button>
      </Link>
    </div>
  );
};

export default NoAccessPage;
