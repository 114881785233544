import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";

import styles from "./Service.module.scss";
import { TService } from "typings/service";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useState } from "react";
import DeleteServicePopup from "popups/delete-service-popup.tsx/DeleteServicePopup";

type ServiceProps = {
  service: TService;
  editService: (data: TService) => void;
  deleteService: (serviceId: number) => void;
  enableEdit?: boolean;
};

const Service = ({ service, editService, deleteService, enableEdit }: ServiceProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [name, setName] = useState<string>(service.name || "");
  const [amount, setAmount] = useState<number>(service.amount);

  const onEdit = () => {
    setIsEditing(true);
  };

  const onSave = () => {
    editService({ id: service.id, name, amount });
    setIsEditing(false);
  };

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);

  const openDeletePopup = () => {
    setIsDeletePopupOpen(true);
  };

  const closeDeletePopup = () => {
    setIsDeletePopupOpen(false);
  };

  return (
    <div className={styles["service"]}>
      <InputText
        maxLength={700}
        value={name}
        className={classNames("flex-1 p-inputtext-light-bg p-inputtext-md")}
        placeholder="Название услуги"
        disabled={!isEditing}
        onChange={(e) => setName(e.target.value)}
      />
      <InputNumber
        value={amount}
        className={classNames("flex-1 p-inputtext-light-bg p-inputtext-md", {
          "p-inputtext-display-value": !isEditing,
        })}
        placeholder="Стоимость"
        disabled={!isEditing}
        onChange={(e) => {
          setAmount(e.value || 0);
        }}
      />
      {enableEdit && (
        <div className={styles["service-buttons"]}>
          {isEditing ? (
            <Button
              icon="pi pi-save"
              className="p-button p-component p-button-rounded p-button-icon-only"
              type="button"
              onClick={onSave}
            />
          ) : (
            <Button
              icon="pi pi-pencil"
              className="p-button p-component p-button-rounded p-button-icon-only"
              type="button"
              onClick={onEdit}
            />
          )}
          <Button
            className="p-button-rounded p-button-secondary"
            icon="pi pi-trash"
            onClick={openDeletePopup}
          />
        </div>
      )}
      <DeleteServicePopup
        isOpen={isDeletePopupOpen}
        handleHide={closeDeletePopup}
        deleteService={deleteService}
        service={service}
      />
    </div>
  );
};

export default Service;
