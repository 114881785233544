import PromoEarinigs from "features/PromoEarinigs/Promo";
import { FC } from "react";

type PromoEarinigsPageProps = {};

const PromoEarinigsPage: FC<PromoEarinigsPageProps> = () => {
  return <PromoEarinigs />;
};

export default PromoEarinigsPage;
