import { TOnbordingStatArray } from "typings/onboardingStat";
import { Accordion, AccordionTab } from "primereact/accordion";

import styled from "./OnboardingStatistics.module.scss";

type OnboardingStatisticsLayout = {
    onboardingStat: TOnbordingStatArray;
};

const OnboardingStatisticsLayout = ({ onboardingStat }) => {
    return (
        <div className={styled["content-card"]}>
            <Accordion className={styled["accordion"]}>
                <AccordionTab header="Возраст">
                    <div className={styled["accordion-list"]}>
                        {onboardingStat &&
                            onboardingStat.age.map((el, index) => (
                                <div className={styled["row"]} key={index + el.value}>
                                    <span>{el.key}:</span>
                                    <p>{el.value}%</p>
                                </div>
                            ))}
                    </div>
                </AccordionTab>
            </Accordion>
            <Accordion className={styled["accordion"]}>
                <AccordionTab header="Пол">
                    <div className={styled["accordion-list"]}>
                        {onboardingStat &&
                            onboardingStat.gender.map((el, index) => (
                                <div className={styled["row"]} key={index + el.value}>
                                    <span>{el.key}:</span>
                                    <p>{el.value}%</p>
                                </div>
                            ))}
                    </div>
                </AccordionTab>
            </Accordion>
            <Accordion className={styled["accordion"]}>
                <AccordionTab header="Город">
                    <div className={styled["accordion-list"]}>
                        {onboardingStat &&
                            onboardingStat.city.map((el, index) => (
                                <div className={styled["row"]} key={index + el.value}>
                                    <span>{el.key}:</span>
                                    <p>{el.value}%</p>
                                </div>
                            ))}
                    </div>
                </AccordionTab>
            </Accordion>
            <Accordion className={styled["accordion"]}>
                <AccordionTab header="Сфера деятельности">
                    <div className={styled["accordion-list"]}>
                        {onboardingStat &&
                            onboardingStat.fieldActivity.map((el, index) => (
                                <div className={styled["row"]} key={index + el.value}>
                                    <span>{el.key}:</span>
                                    <p>{el.value}%</p>
                                </div>
                            ))}
                    </div>
                </AccordionTab>
            </Accordion>
            <Accordion className={styled["accordion"]}>
                <AccordionTab header="Доход">
                    <div className={styled["accordion-list"]}>
                        {onboardingStat &&
                            onboardingStat.target.map((el, index) => (
                                <div className={styled["row"]} key={index + el.value}>
                                    <span>{el.key}:</span>
                                    <p>{el.value}%</p>
                                </div>
                            ))}
                    </div>
                </AccordionTab>
            </Accordion>
            <Accordion className={styled["accordion"]}>
                <AccordionTab header="Цели">
                    <div className={styled["accordion-list"]}>
                        {onboardingStat &&
                            onboardingStat.profit.map((el, index) => (
                                <div className={styled["row"]} key={index + el.value}>
                                    <span>{el.key}:</span>
                                    <p>{el.value}%</p>
                                </div>
                            ))}
                    </div>
                </AccordionTab>
            </Accordion>
            <Accordion className={styled["accordion"]}>
                <AccordionTab header="Интересы">
                    <div className={styled["accordion-list"]}>
                        {onboardingStat &&
                            onboardingStat.interest.map((el, index) => (
                                <div className={styled["row"]} key={index + el.value}>
                                    <span>{el.key}:</span>
                                    <p>{el.value}%</p>
                                </div>
                            ))}
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default OnboardingStatisticsLayout;
