import { FC } from "react";
import { TLesson } from "typings/course";

import styled from "./ContentTab.module.scss";
import { Link } from "react-router-dom";

type LessonHeaderProps = {
  lesson: TLesson;
  courseId: number;
};

const LessonHeader: FC<LessonHeaderProps> = (props) => {
  return (
    <div className={styled["module-header"]}>
      <p className={styled["module-header-text"]}>{props.lesson.name}</p>
      <div className={styled["module-header-settings"]}>
        <Link
          to={`${props.lesson.id}`}
          onClick={(e) => e.stopPropagation()}
        >
          <button className={styled["lesson-header-button"]}>
            <i className="pi pi-arrow-right" />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default LessonHeader;
