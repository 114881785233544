import { FC } from "react";

import RefferalSystem from "features/RefferalSystem/RefferalSystem";

type RefferalSystemPageProps = {};

const RefferalSystemPage: FC<RefferalSystemPageProps> = () => {
    return <RefferalSystem />;
};

export default RefferalSystemPage;
