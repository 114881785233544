import styles from "./Questions.module.scss";
import { TabMenu } from "primereact/tabmenu";
import QuestionsTable from "./table/QuestionsTable";
import { DEFAULT_PARAMS } from "constants/table-params";
import { useCallback, useEffect, useState } from "react";
import { tableControlsType } from "typings/table-controls";
import { TQuestion } from "typings/question";
import AnswerPopup from "popups/answer/AnswerPopup";
import { phrases } from "constants/phrases";
import { addErrorMessage } from "slices/toastSlice";
import { useAppDispatch } from "utils/hooks";
import { getQuestions } from "api/questions/getQuestions";
import { useProfileData } from "../useProfileData";
import { useGetRoles } from "utils/useGetRoles";

const itemsTable = [
  { label: "Ждут ответа", key: "waiting" },
  { label: "Прочитанные", key: "answered" },
];

const Questions = () => {
  const [questionsList, setQuestionsList] = useState<TQuestion[]>([]);
  const [params, setParams] = useState<tableControlsType>(DEFAULT_PARAMS);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [answerPopupOpen, setAnswerPopupOpen] = useState<boolean>(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState<number | null>(null);
  const [selectedTab, setSelectedTab] = useState<"waiting" | "answered">("waiting");
  const isRead = selectedTab === "answered";
  const { profile } = useProfileData();

  const { isAdmin } = useGetRoles();

  const dispatch = useAppDispatch();

  const getQuestionsData = useCallback(async () => {
    setIsLoadingTable(true);
    try {
      const response = await getQuestions({
        page: params.page,
        limit: params.rows,
        isRead,
        adminId: isAdmin ? profile?.id : undefined,
      });
      if (response.status === 200 || response.status === 201) {
        setQuestionsList(response.data.data);
        setTotalRecords(response.data.pagination.totalItems);
        setIsLoadingTable(false);
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
  }, [dispatch, isRead, params.page, params.rows, profile?.id]);

  useEffect(() => {
    getQuestionsData();
  }, [getQuestionsData]);

  const openAnswerPopup = (questionId: number) => {
    setSelectedQuestionId(questionId);
    setAnswerPopupOpen(true);
  };

  const activeIndex = itemsTable.findIndex((item) => item.key === selectedTab);
  const onTabChange = (event) => {
    const {
      value: { key },
    } = event;
    setSelectedTab(key);
  };

  return (
    <div className={styles["questions-wrapper"]}>
      <div className={styles["questions-table-tabs"]}>
        <TabMenu model={itemsTable} activeIndex={activeIndex} onTabChange={onTabChange} />
      </div>
      <QuestionsTable
        params={params}
        setParams={setParams}
        questionsList={questionsList}
        totalRecords={totalRecords}
        isLoadingTable={isLoadingTable}
        openAnswerPopup={openAnswerPopup}
      />
      <AnswerPopup
        isOpen={answerPopupOpen}
        handleHide={() => setAnswerPopupOpen(false)}
        selectedQuestionId={selectedQuestionId}
      />
    </div>
  );
};

export default Questions;
