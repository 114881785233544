import { phrases } from "constants/phrases";
import { useAppDispatch } from "utils/hooks";
import { COURSE_MENU_LIST } from "constants/course";
import { updateCourseAsync } from "api/courses/editCourse";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import { changeCourseStatusAsync } from "api/courses/changeCourseStatus";
import { TCourseInfo, TUpdateCourseData } from "typings/course";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, useCallback, useState } from "react";

import styled from "./Course.module.scss";
import CourseLayout from "./Couse.layout";

type CourseProps = {};

const Course: FC<CourseProps> = () => {
  const courseInfo = useRouteLoaderData("course") as TCourseInfo;
  const [activeTab, setActiveTab] = useState<string>(COURSE_MENU_LIST.promo);
  const [isOpenDeactivateCoursePopup, setIsOpenDeactivateCoursePopup] = useState(false);
  const [isOpenPublishCoursePopup, setIsOpenPublishCoursePopup] = useState(false);
  const [isOpenRemoveCoursePopup, setIsOpenRemoveCoursePopup] = useState(false);
  const [isOpenMovingLessonsCoursePopup, setIsOpenMovingLessonsPopup] = useState(false);
  const [isOpenMovingModulesPopup, setIsOpenMovingModulesPopup] = useState(false);
  const [isOpenReturnForRevisionCoursePopup, setIsOpenReturnForRevisionCoursePopup] =
    useState(false);

  const dispatch = useAppDispatch();

  const handleOpenDeactivateCoursePopup = () => setIsOpenDeactivateCoursePopup(true);
  const handleCloseDeactivateCoursePopup = () => setIsOpenDeactivateCoursePopup(false);

  const handleOpenPublishCoursePopup = () => setIsOpenPublishCoursePopup(true);
  const handleClosePublishCoursePopup = () => setIsOpenPublishCoursePopup(false);

  const handleOpenRemoveCoursePopup = () => setIsOpenRemoveCoursePopup(true);
  const handleCloseRemoveCoursePopup = () => setIsOpenRemoveCoursePopup(false);

  const handleOpenMovingLessonsPopup = () => setIsOpenMovingLessonsPopup(true);
  const handleCloseMovingLessonsPopup = () => setIsOpenMovingLessonsPopup(false);

  const handleOpenMovingModulesPopup = () => setIsOpenMovingModulesPopup(true);
  const handleCloseMovingModulesPopup = () => setIsOpenMovingModulesPopup(false);

  const handleOpenReturnForRevisionCoursePopup = () => setIsOpenReturnForRevisionCoursePopup(true);
  const handleCloseReturnForRevisionCoursePopup = () =>
    setIsOpenReturnForRevisionCoursePopup(false);

  const changeTab = (tab: string) => setActiveTab(tab);

  const revalidator = useRevalidator();

  const updateCourse = useCallback(
    async (courseData: TUpdateCourseData) => {
      try {
        if (courseInfo && courseInfo.id) {
          const response = await updateCourseAsync(courseInfo.id, courseData);
          if (response.status === 200 || response.status === 201) {
            revalidator.revalidate();
            dispatch(addSuccessMessage(phrases.course_sucess_updated));
          } else throw new Error();
        }
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      }
    },
    [courseInfo, revalidator, dispatch]
  );

  const changeCourseStatus = useCallback(
    async (status: number) => {
      try {
        if (courseInfo && courseInfo.id) {
          const response = await changeCourseStatusAsync(courseInfo.id, status);
          if (response.status === 200 || response.status === 201) {
            revalidator.revalidate();
            dispatch(addSuccessMessage(phrases.course_sucess_updated));
          } else throw new Error();
        }
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      }
    },
    [courseInfo, revalidator, dispatch]
  );

  return (
    <div className={styled["page-container"]}>
      <CourseLayout
        changeTab={changeTab}
        activeTab={activeTab}
        isOpenDeactivateCoursePopup={isOpenDeactivateCoursePopup}
        handleOpenDeactivateCoursePopup={handleOpenDeactivateCoursePopup}
        handleCloseDeactivateCoursePopup={handleCloseDeactivateCoursePopup}
        isOpenPublishCoursePopup={isOpenPublishCoursePopup}
        handleOpenPublishCoursePopup={handleOpenPublishCoursePopup}
        handleClosePublishCoursePopup={handleClosePublishCoursePopup}
        isOpenReturnForRevisionCoursePopup={isOpenReturnForRevisionCoursePopup}
        handleOpenReturnForRevisionCoursePopup={handleOpenReturnForRevisionCoursePopup}
        handleCloseReturnForRevisionCoursePopup={handleCloseReturnForRevisionCoursePopup}
        isOpenRemoveCoursePopup={isOpenRemoveCoursePopup}
        handleOpenRemoveCoursePopup={handleOpenRemoveCoursePopup}
        handleCloseRemoveCoursePopup={handleCloseRemoveCoursePopup}
        isOpenMovingLessonsCoursePopup={isOpenMovingLessonsCoursePopup}
        handleOpenMovingLessonsPopup={handleOpenMovingLessonsPopup}
        handleCloseMovingLessonsPopup={handleCloseMovingLessonsPopup}
        isOpenMovingModulesPopup={isOpenMovingModulesPopup}
        handleOpenMovingModulesPopup={handleOpenMovingModulesPopup}
        handleCloseMovingModulesPopup={handleCloseMovingModulesPopup}
        courseInfo={courseInfo}
        updateCourse={updateCourse}
        changeCourseStatus={changeCourseStatus}
      />
    </div>
  );
};

export default Course;
