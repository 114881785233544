import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { TNewCourse } from "typings/course";
import { FC, useState } from "react";

import styles from "./AddCoursePopup.module.scss";

type AddCoursePopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  createCourse: (courseData: TNewCourse) => void;
};

const AddCoursePopup: FC<AddCoursePopupProps> = (props) => {
  const [title, setTitle] = useState<string>("");

  const closePopup = () => {
    props.handleHide();
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <p className={styles["title"]}>Добавление нового курса</p>

        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Название курса</p>
          <InputText
            maxLength={700}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Название"
            className={styles["input"]}
          />
        </div>

        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button
            className={styles["add-button"]}
            onClick={() => props.createCourse({ name: title })}
            disabled={!title}
          >
            Добавить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AddCoursePopup;
