import { Dialog } from "primereact/dialog";
import styles from "./AnswerPopup.module.scss";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { getQuestion } from "api/questions/getQuestion";
import { phrases } from "constants/phrases";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { useAppDispatch } from "utils/hooks";
import { TQuestionData } from "typings/question";
import formatDate from "utils/formatDate";
import { answerQuestion } from "api/questions/answerQuestion";

type AnswerPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedQuestionId: number | null;
};

type Inputs = {
  answer: string;
};

const AnswerPopup = ({ isOpen, handleHide, selectedQuestionId }: AnswerPopupProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<Inputs>();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!selectedQuestionId) return;
    try {
      await answerQuestion(selectedQuestionId, {
        answer: data.answer,
        status: 1,
      });

      dispatch(addSuccessMessage(phrases.answer_success_added));
      setValue("answer", "");
      handleHide();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
    handleHide();
  };

  const [questionData, setQuestionData] = useState<TQuestionData | null>(null);

  const getQuestionData = useCallback(async () => {
    if (selectedQuestionId) {
      try {
        const response = await getQuestion(selectedQuestionId);
        if (response.status === 200 || response.status === 201) {
          setQuestionData(response.data);
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      }
    }
  }, [dispatch, selectedQuestionId]);

  useEffect(() => {
    getQuestionData();
  }, [getQuestionData]);

  if (!questionData) return null;

  const {
    lesson: {
      name: lessonName,
      module: {
        name: moduleName,
        course: { name: courseName },
      },
    },
    student: { firstName, lastName, avatar },
    createdAt,
    question,
  } = questionData;

  const headerTexts = [courseName, moduleName, lessonName];

  return (
    <Dialog visible={isOpen} modal onHide={handleHide} showHeader={false} style={{ width: 597 }}>
      <form className={styles["wrapper"]} onSubmit={handleSubmit(onSubmit)}>
        <header>
          <nav>
            <ul>
              {headerTexts.map((text) => (
                <li key={text}>
                  {text} <i className="pi pi-angle-right" />
                </li>
              ))}
            </ul>
          </nav>
          <Button
            type="button"
            className={classNames("p-button-rounded p-button-text", styles["close-button"])}
            onClick={handleHide}
            icon="pi pi-times"
          />
        </header>
        <div className={styles["title-wrapper"]}>
          {avatar ? (
            <img src={avatar} alt="profile" className={styles["profile-image"]} />
          ) : (
            <div className={styles["profile-image"]}></div>
          )}
          <div className={styles["title"]}>
            <h5>{lastName}</h5>
            <h5>{firstName}</h5>
            <p>{formatDate(new Date(createdAt))}</p>
          </div>
        </div>
        <p className={styles["question-text"]}>{question}</p>
        <div className={classNames("field", styles["answer-field"])}>
          <label>Мой ответ</label>
          <InputTextarea
            autoResize
            className="w-full p-inputtext-light-bg p-inputtext-sm"
            placeholder="Текстовка"
            {...register("answer", {
              required: {
                value: true,
                message: phrases.required_field,
              },
            })}
          />
          {errors.answer && <p className={styles["error"]}>{errors.answer.message}</p>}
        </div>
        <Button
          className="p-button-sm w-full"
          onClick={handleSubmit(onSubmit)}
          label="Ответить"
          loading={isSubmitting}
        ></Button>
      </form>
    </Dialog>
  );
};

export default AnswerPopup;
