import { useOutletContext } from "react-router";
import { TAdminProfile } from "typings/admin";
import { TBalanceStatistics } from "typings/balance";

export type OutletContextType = {
  balanceStatistics?: TBalanceStatistics;
  profile: TAdminProfile;
  handleCloseAddCoursePopup: () => void;
  handleOpenAddCoursePopup: () => void;
  isOpenAddCoursePopup: boolean;
  addCourse: (courseId: number) => Promise<void>;
};

export const useProfileData = () => {
  const profileData = useOutletContext<OutletContextType>();
  return profileData;
};
