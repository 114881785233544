import { FC } from "react";
import { Dropdown } from "primereact/dropdown";
import { SUBSCRIPTIONS_STATUSES_ARRAY } from "constants/subscriptions";

import styled from "./Subscriptions.module.scss";
import { Button } from "primereact/button";

type HeaderLayoutProps = {
  status: string;
  changeStatus: (status: string) => void;
  downloadSubscriptions: () => void;
};

const HeaderLayout: FC<HeaderLayoutProps> = (props) => {
  return (
    <div className={styled["header-layout"]}>
      <div className={styled["dropdown-wrapper"]}>
        <Dropdown
          options={SUBSCRIPTIONS_STATUSES_ARRAY}
          onChange={(e) => props.changeStatus(e.value)}
          value={props.status}
          placeholder="Выберите статус"
          className={styled["dropdown"]}
        />
      </div>
      <Button label="Экспорт в csv" onClick={props.downloadSubscriptions} className="p-button-sm" />
    </div>
  );
};

export default HeaderLayout;
