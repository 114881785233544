import { Outlet, useNavigate, useParams } from "react-router";
import EarningsInformation from "../EarningsInformation/EarningsInformation";
import styled from "./ProfileDataPage.module.scss";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import useGetActiveLink from "utils/useGetActiveLink";
import { TabMenu } from "primereact/tabmenu";
import { useProfileData } from "../useProfileData";
import { OutletContextType } from "../useProfileData";
import AddCourseModal from "popups/add-course/AddCourseModal";
import { curatorProfileMenu, myProfileMenu, profileMenu } from "constants/profile";
import { useGetRoles } from "utils/useGetRoles";
import AddCoursePopup from "popups/add-course/AddCoursePopup";
import { useCallback, useState } from "react";
import { TNewCourse } from "typings/course";
import { createNewCourseAsync } from "api/courses/addCourse";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { useAppDispatch } from "utils/hooks";
import { phrases } from "constants/phrases";



const ProfileCoursesPage = () => {
  const [isOpenAddCoursePopup, setIsOpenAddCoursePopup] = useState<boolean>(false);


  const handleOpenAddCoursePopup = () => setIsOpenAddCoursePopup(true);
  const handleCloseAddCoursePopup = () => setIsOpenAddCoursePopup(false);

  const { id } = useParams();
  const data = useProfileData();

  const { isCurator, isAdmin, isSpeacker } = useGetRoles();

  const dispatch = useAppDispatch();

  const activeLink = useGetActiveLink(isCurator ? curatorProfileMenu.map((item) => item.to) : profileMenu.map((item) => item.to));
  const activeIndex = isCurator ? curatorProfileMenu.findIndex((item) => item.to === activeLink) : profileMenu.findIndex((item) => item.to === activeLink);

  const navigate = useNavigate();


  const onTabChange = (event) => {
    const {
      value: { to },
    } = event;
    navigate(to);
  };

  const createCourse = useCallback(
    async (courseData: TNewCourse) => {
      try {
        const response = await createNewCourseAsync(courseData);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.course_success_added));
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      } finally {
        handleCloseAddCoursePopup();
      }
    },
    [dispatch]
  );


  return (
    <div className={styled["additional-information"]}>
      <EarningsInformation />
      <div className={styled["profile-data-wrapper"]}>
        <div className={styled["tabs-title"]}>
          <TabMenu
            model={isCurator ? curatorProfileMenu : id ? profileMenu : myProfileMenu}
            activeIndex={activeIndex}
            onTabChange={onTabChange}
          />
          {activeLink === "courses" && isAdmin  && id && (
            <Button
              label="Назначить на курс"
              className={classNames("p-button-sm", styled["button"])}
              onClick={data.handleOpenAddCoursePopup}
            />
          )}
          {isSpeacker && activeLink === "courses" && (
            <Button
              label="Создать курс"
              className={classNames("p-button-sm", styled["button"])}
              onClick={handleOpenAddCoursePopup}
            />
          )}
        </div>
        <Outlet context={data satisfies OutletContextType} />
      </div>
      <AddCourseModal isOpen={data.isOpenAddCoursePopup} handleHide={data.handleCloseAddCoursePopup} addCourse={data.addCourse} />

      <AddCoursePopup
        isOpen={isOpenAddCoursePopup}
        handleHide={handleCloseAddCoursePopup}
        createCourse={createCourse}
      />
    </div>
  );
};

export default ProfileCoursesPage;
