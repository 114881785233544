import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { uploadFile } from "api/storage/uploadFile";
import { FileUpload } from "primereact/fileupload";
import { TUploadedFile } from "typings/file";
import { InputTextarea } from "primereact/inputtextarea";
import { HexColorPicker } from "react-colorful";
import { DEFAULT_DIRECTIONS_LIST } from "constants/directions";
import { TDirection, TNewDirection } from "typings/direction";
import { FC, useEffect, useRef, useState } from "react";

import styles from "./EditDirectionPopup.module.scss";
import AddedImage from "components/common/added-image/AddedImage";
import PopupDateTimeCalendar from "popups/date-time/PopupDateTimeCalendar";

type EditDirectionPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedDirection: TDirection | null;
  updateDirection: (directionData: TNewDirection) => void;
};

const EditDirectionPopup: FC<EditDirectionPopupProps> = (props) => {
  const [header, setHeader] = useState<string>("");
  const [color, setColor] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isOpenColorPallete, setIsOpenColorPallete] = useState<boolean>(false);
  const [additionalDescriptions, setAdditionalDescriptions] = useState(DEFAULT_DIRECTIONS_LIST);
  const [createdAt, setCreatedAt] = useState<Date | null>(null);
  const [file1, setFile1] = useState<TUploadedFile | null>(null);
  const [file2, setFile2] = useState<TUploadedFile | null>(null);

  const file1Reference = useRef(null);
  const file2Reference = useRef(null);

  const popover = useRef<any>();

  const closePopup = () => {
    props.handleHide();
    setDescription("");
    setHeader("");
    setColor("");
    setAdditionalDescriptions(DEFAULT_DIRECTIONS_LIST);
    setFile1(null);
    setFile2(null);
  };

  useEffect(() => {
    if (props.selectedDirection) {
      setDescription(props.selectedDirection.description);
      setHeader(props.selectedDirection.header);
      setColor(props.selectedDirection.color);
      setAdditionalDescriptions(props.selectedDirection.additionalDescriptions);
      setCreatedAt(
        props.selectedDirection.createdAt ? new Date(props.selectedDirection.createdAt) : null
      );
      setFile1(props.selectedDirection.files[0]);
      setFile2(props.selectedDirection.files[1]);
    }
  }, [props.isOpen, props.selectedDirection]);

  const changeAdditionalDescriptions = (index: number, field: string, value: string) => {
    const newList = additionalDescriptions.map((el, elIndex) => {
      if (elIndex === index) {
        return {
          ...el,
          [field]: value,
        };
      } else return el;
    });
    setAdditionalDescriptions(newList);
  };

  const editDirection = () => {
    props.updateDirection({
      header,
      description,
      color,
      additionalDescriptions,
      files: file1 && file2 ? [file1, file2] : [],
      createdAt: createdAt ? createdAt.toISOString() : undefined,
    });
    closePopup();
  };

  const customUploader = async (e, fileType: string) => {
    if (e.files[0]) {
      const formData = new FormData();
      formData.append("file", e.files[0]);
      const response = await uploadFile(formData);
      if (response.status === 200 || response.status === 201) {
        if (fileType === "file1") setFile1(response.data);
        else if (fileType === "file2") setFile2(response.data);
      }
    }
  };

  const Header = ({ options, isAdded, onRemove }) => {
    if (isAdded) return <AddedImage name={isAdded.name} deleteFile={onRemove} />;
    return <div>{options.chooseButton}</div>;
  };

  const onRemoveFile1 = () => {
    setFile1(null);
    //@ts-ignore
    file1Reference?.current?.clear();
  };

  const onRemoveFile2 = () => {
    setFile2(null);
    //@ts-ignore
    file2Reference?.current?.clear();
  };

  return (
    <Dialog
      visible={props.isOpen}
      modal
      onHide={closePopup}
      className={styles["dialog"]}
      showHeader={false}
    >
      <div className={styles["content"]}>
        <p className={styles["title"]}>Редактирование направления</p>
        <div className={styles["top-row"]}>
          <div className={styles["input-wrapper"]}>
            <p className={styles["input-label"]}>Заголовок</p>
            <InputText
              maxLength={1300}
              value={header}
              onChange={(e) => setHeader(e.target.value)}
              placeholder="Заголовок"
              className={styles["input"]}
            />
          </div>
          <div className={styles["input-wrapper"]}>
            <p className={styles["input-label"]}>Цвет панели направления</p>
            <div className={styles["picker"]}>
              <div
                className={styles["swatch"]}
                style={{ backgroundColor: color }}
                onClick={() => setIsOpenColorPallete(!isOpenColorPallete)}
              />

              {isOpenColorPallete && (
                <div className={styles["popover"]} ref={popover}>
                  <HexColorPicker color={color} onChange={(color) => setColor(color)} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles["input-wrapper"]}>
          <p className={styles["input-label"]}>Описание</p>
          <InputTextarea
            maxLength={1300}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={3}
            cols={30}
            autoResize
            placeholder="Описание"
            className={styles["text-area"]}
          />
        </div>
        <div className={styles["m-row"]}>
          <div>
            <p className={styles["input-label"]}>Дополнительное описание 1</p>
            <div className={styles["m-row"]}>
              <div className={styles["input-wrapper"]}>
                <p className={styles["input-label"]}>Сумма</p>
                <InputText
                  maxLength={100}
                  value={additionalDescriptions[0]?.title}
                  onChange={(e) => changeAdditionalDescriptions(0, "title", e.target.value)}
                  placeholder="Сумма"
                  className={styles["input"]}
                />
              </div>
              <div className={styles["input-wrapper"]}>
                <p className={styles["input-label"]}>Описание</p>
                <InputText
                  maxLength={1300}
                  value={additionalDescriptions[0]?.text}
                  onChange={(e) => changeAdditionalDescriptions(0, "text", e.target.value)}
                  placeholder="Описание"
                  className={styles["input"]}
                />
              </div>
            </div>
          </div>
          <div>
            <p className={styles["input-label"]}>Дополнительное описание 2</p>
            <div className={styles["m-row"]}>
              <div className={styles["input-wrapper"]}>
                <p className={styles["input-label"]}>Сумма</p>
                <InputText
                  maxLength={100}
                  value={additionalDescriptions[1]?.title}
                  onChange={(e) => changeAdditionalDescriptions(1, "title", e.target.value)}
                  placeholder="Сумма"
                  className={styles["input"]}
                />
              </div>
              <div className={styles["input-wrapper"]}>
                <p className={styles["input-label"]}>Описание</p>
                <InputText
                  maxLength={1300}
                  value={additionalDescriptions[1]?.text}
                  onChange={(e) => changeAdditionalDescriptions(1, "text", e.target.value)}
                  placeholder="Описание"
                  className={styles["input"]}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles["file-row"]}>
          <FileUpload
            ref={file1Reference}
            name="file-uploader1"
            accept="image/*"
            maxFileSize={1000000}
            customUpload
            uploadHandler={(e) => customUploader(e, "file1")}
            auto
            itemTemplate={() => null}
            chooseLabel={file1?.name || "Примерный файл.jpg"}
            className={styles["file-uploader"]}
            onRemove={() => setFile1(null)}
            contentClassName={styles["file-upload-content"]}
            headerTemplate={(options) => (
              <Header options={options} isAdded={file1} onRemove={onRemoveFile1} />
            )}
            progressBarTemplate={() => null}
          />
        </div>

        <div className={styles["file-row"]}>
          <FileUpload
            ref={file2Reference}
            name="file-uploader2"
            accept="image/*"
            maxFileSize={1000000}
            customUpload
            uploadHandler={(e) => customUploader(e, "file2")}
            auto
            itemTemplate={() => null}
            chooseLabel={file2?.name || "Примерный файл.jpg"}
            className={styles["file-uploader"]}
            onRemove={() => setFile2(null)}
            contentClassName={styles["file-upload-content"]}
            headerTemplate={(options) => (
              <Header options={options} isAdded={file2} onRemove={onRemoveFile2} />
            )}
            progressBarTemplate={() => null}
          />
        </div>
        <div className={styles["m-row"]}>
          <div className={styles["input-wrapper"]}>
            <p className={styles["input-label"]}>Дата создания</p>
            <PopupDateTimeCalendar
              placeholder="Дата создания"
              inputClassName={styles["date-wrapper"]}
              date={createdAt}
              setDate={(date) => {
                setCreatedAt(date);
              }}
            />
          </div>
        </div>
        <div className={styles["buttons-container"]}>
          <Button className={styles["cancel-button"]} onClick={closePopup}>
            Отмена
          </Button>
          <Button
            className={styles["add-button"]}
            onClick={editDirection}
            disabled={!header || !description || !file1 || !file2 || !color}
          >
            Изменить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditDirectionPopup;
