import { getFaq } from "api/faq/getFaq";
import { getTags } from "api/tags/getTags";
import { phrases } from "constants/phrases";
import { addErrorMessage } from "slices/toastSlice";
import { store } from "store";
import { TFaqLoaderResult } from "typings/loaders";

const { dispatch } = store;

const settingsAdminInfoLoader = async (): Promise<TFaqLoaderResult | undefined> => {
  try {
    const [faq, tags] = await Promise.all([getFaq("admin"), getTags("admin")]);
    return { faq: faq.data, tags: tags.data };
  } catch (err: any) {
    dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
  }
};

export default settingsAdminInfoLoader;
