import { TApplication } from "typings/applications";
import { APPLICATION_STATUSES_LABELS } from "constants/applications";

import styles from "./ApplicationsTable.module.scss";
import formattingDate from "utils/formattingDate";

export const renderColumn = (value: string) => (rowData: TApplication) => {
  return (
    <div className="col-wrapper" onClick={() => {}}>
      <p className="table-text">{rowData[value]}</p>
    </div>
  );
};

export const renderStatus = (rowData: TApplication) => {
  return (
    <div className="col-wrapper">
      <p className="table-text">{APPLICATION_STATUSES_LABELS[rowData.status]}</p>
    </div>
  );
};

export const renderDate = (field: string) => (rowData: TApplication) => {
  return (
    <div className="col-wrapper">
      <p className="table-text">{formattingDate(rowData[field], "dd.mm.yyyy")}</p>
    </div>
  );
};

export const renderAuthorApplication = (rowData: TApplication) => {
  return (
    <div className={styles["table-row"]}>
      <p className="table-text">{rowData.name}</p>
      <p className="table-text">{rowData.email}</p>
      <p className="table-text">{rowData.phone}</p>
    </div>
  );
};

export const renderSpeakerInfo = (rowData: TApplication) => {
  return (
    <div className={styles["table-row"]}>
      <p className="table-text">{rowData.speaker?.firstName + " " + rowData.speaker?.lastName}</p>
      <p className="table-text">{rowData.speaker?.email}</p>
    </div>
  );
};

export const renderServices = (rowData: TApplication) => {
  return (
    <div className={styles["table-row"]}>
      {rowData.services.map((service) => (
        <li key={service.id} className="table-text">
          {service.name}
        </li>
      ))}
    </div>
  );
};
