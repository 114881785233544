import { FC } from "react";

import Admins from "features/Admins/Admins";

type AdminsPageProps = {};

const AdminsPage: FC<AdminsPageProps> = () => {
    return <Admins />;
};

export default AdminsPage;
