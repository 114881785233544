import { FC } from "react";

import OnboardingStatistics from "features/OnboardingStatistics/OnboardingStatistics";

type OnboardingStatPageProps = {};

const OnboardingStatPage: FC<OnboardingStatPageProps> = () => {
    return <OnboardingStatistics />;
};

export default OnboardingStatPage;
