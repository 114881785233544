import { FC } from "react";
import { TModule } from "typings/course";
import { useGetRoles } from "utils/useGetRoles";
import { formatCounterLabel } from "utils/formatCounterLabel";
import { LESSON_COUNTER_LABELS } from "constants/course";

import styled from "./ContentTab.module.scss";

type ModuleHeaderProps = {
    handleOpenRemoveModulePopup: () => void;
    handleOpenMovingLessonsPopup: () => void;
    handleOpenEditModulePopup: () => void;
    module: TModule;
    changeSelectedModule: (module: TModule) => void;
};

const ModuleHeader: FC<ModuleHeaderProps> = (props) => {
    const { isCurator } = useGetRoles();

    return (
        <div className={styled["module-header"]}>
            <p className={styled["module-header-text"]}>{props.module.name}</p>
            <div className={styled["module-header-settings"]}>
                {!isCurator && (
                    <button
                        className={styled["module-header-button"]}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            props.changeSelectedModule(props.module);
                            props.handleOpenEditModulePopup();
                        }}
                    >
                        <i className="pi pi-pencil" />
                    </button>
                )}
                {!isCurator && (
                    <button
                        className={styled["module-header-button"]}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            props.changeSelectedModule(props.module);
                            props.handleOpenMovingLessonsPopup();
                        }}
                    >
                        <i className="pi pi-sort-alt" />
                    </button>
                )}
                {!isCurator && (
                    <button
                        className={styled["module-header-button"]}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            props.changeSelectedModule(props.module);
                            props.handleOpenRemoveModulePopup();
                        }}
                    >
                        <i className="pi pi-trash" />
                    </button>
                )}
                <p className={styled["module-header-text"]}>
                    {props.module.lessons.length}{" "}
                    {formatCounterLabel(props.module.lessons.length, LESSON_COUNTER_LABELS)}
                </p>
            </div>
        </div>
    );
};

export default ModuleHeader;
