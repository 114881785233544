import http from "./https";

export const getBalancesHistory = (params) => http.get("/api/balances-history", {}, { params });

export const getBalancesHistoryMy = (params) =>
  http.get("/api/balances-history/my", {}, { params });

export const getBalancesHistoryStatistics = (params) =>
  http.get("/api/balances-history/statistics", {}, { params });

export const getBalancesHistoryStatisticsMy = () => http.get("/api/balances-history/statistics/my");
