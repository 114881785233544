import { phrases } from "constants/phrases";
import { getCourses } from "api/courses/getCourses";
import { useNavigate } from "react-router";
import { useGetRoles } from "utils/useGetRoles";
import { DEFAULT_PARAMS } from "constants/table-params";
import { useAppDispatch } from "utils/hooks";
import { removeCourseAsync } from "api/courses/removeCourse";
import { tableControlsType } from "typings/table-controls";
import { TCourse, TNewCourse } from "typings/course";
import { createNewCourseAsync } from "api/courses/addCourse";
import { getShortAdminsListAsync } from "api/admins/getShortAdminsList";
import { getShortDirectionsListAsync } from "api/directions/getShortDirectionsList";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, useCallback, useEffect, useState } from "react";

import styled from "./Courses.module.scss";
import CoursesLayout from "./Courses.layout";
import AddCoursePopup from "popups/add-course/AddCoursePopup";
import RemoveCoursePopup from "popups/remove-course/RemoveCoursePopup";
import { getSort } from "utils/getSort";

type CoursesProps = {};

const Courses: FC<CoursesProps> = () => {
  const [coursesList, setCoursesList] = useState<TCourse[]>([]);
  const [params, setParams] = useState<tableControlsType>(DEFAULT_PARAMS);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<TCourse | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isOpenAddCoursePopup, setIsOpenAddCoursePopup] = useState<boolean>(false);
  const [isOpenRemoveCoursePopup, setIsOpenRemoveCoursePopup] = useState<boolean>(false);
  const [directions, setDirections] = useState<{ value: number; label: string }[]>([]);
  const [selectedDirection, setSelectedDirection] = useState<number>(-1);
  const [speakers, setSpeakers] = useState<{ value: number; label: string }[]>([]);
  const [selectedSpeaker, setSelectedSpeaker] = useState<number>(-1);

  const { isAdmin, isCurator, isSpeacker } = useGetRoles();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const changeSelectedCourse = (data: TCourse) => setSelectedCourse(data);

  const changeSelectedDirection = (direction: number) => setSelectedDirection(direction);
  const changeSelectedSpeaker = (speakerId: number) => setSelectedSpeaker(speakerId);

  const handleOpenAddCoursePopup = () => setIsOpenAddCoursePopup(true);
  const handleCloseAddCoursePopup = () => setIsOpenAddCoursePopup(false);

  const handleOpenRemoveCoursePopup = () => setIsOpenRemoveCoursePopup(true);
  const handleCloseRemoveCoursePopup = () => setIsOpenRemoveCoursePopup(false);

  const getCoursesList = useCallback(
    async (params) => {
      setIsLoadingTable(true);
      try {
        const response = await getCourses(params);
        if (response.status === 200 || response.status === 201) {
          setCoursesList(response.data.data);
          setTotalRecords(response.data.pagination.totalItems);
          setIsLoadingTable(false);
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      }
    },
    [dispatch, selectedDirection, selectedSpeaker]
  );

  useEffect(() => {
    getCoursesList({
      page: params.page,
      limit: params.rows,
      search: params.search || undefined,
      directionId: selectedDirection !== -1 ? selectedDirection : undefined,
      speakerId: selectedSpeaker !== -1 ? selectedSpeaker : undefined,
      sort: getSort(params),
    });
  }, [params, getCoursesList, selectedDirection, selectedSpeaker]);

  const createCourse = useCallback(
    async (courseData: TNewCourse) => {
      setIsLoadingTable(true);
      try {
        const response = await createNewCourseAsync(courseData);
        if (response.status === 200 || response.status === 201) {
          navigate(`/courses/${response.data.id}`);
          dispatch(addSuccessMessage(phrases.course_success_added));
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      } finally {
        handleCloseAddCoursePopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch]
  );

  const deleteCourse = useCallback(async () => {
    setIsLoadingTable(true);
    try {
      if (selectedCourse) {
        const response = await removeCourseAsync(selectedCourse.id);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.course_sucess_deleted));
          getCoursesList({
            page: params.page,
            limit: params.rows,
            search: params.search || undefined,
            directionId: selectedDirection !== -1 ? selectedDirection : undefined,
            speakerId: selectedSpeaker !== -1 ? selectedSpeaker : undefined,
          });
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    } finally {
      handleCloseRemoveCoursePopup();
      setIsLoadingTable(false);
    }
  }, [dispatch, selectedCourse]);

  const getDirections = useCallback(async () => {
    try {
      const response = await getShortDirectionsListAsync();
      if (response.status === 200 || response.status === 201) {
        setDirections([
          { value: -1, label: "Все направления" },
          ...response.data.map((el) => ({ value: el.id, label: el.header })),
        ]);
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
  }, [dispatch]);

  useEffect(() => {
    getDirections();
  }, [getDirections]);

  const getSpeakers = useCallback(async () => {
    try {
      const response = await getShortAdminsListAsync("speaker");
      if (response.status === 200 || response.status === 201) {
        setSpeakers([
          { value: -1, label: "Все спикеры" },
          ...response.data.map((el) => ({ value: el.id, label: `${el.firstName} ${el.lastName}` })),
        ]);
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
  }, [dispatch]);

  useEffect(() => {
    getSpeakers();
  }, [getSpeakers]);

  return (
    <div className={styled["page-container"]}>
      <CoursesLayout
        params={params}
        coursesList={coursesList}
        setParams={setParams}
        totalRecords={totalRecords}
        isLoadingTable={isLoadingTable}
        handleOpenRemoveCoursePopup={handleOpenRemoveCoursePopup}
        changeSelectedCourse={changeSelectedCourse}
        handleOpenAddCoursePopup={handleOpenAddCoursePopup}
        directions={directions}
        selectedDirection={selectedDirection}
        changeSelectedDirection={changeSelectedDirection}
        speakers={speakers}
        selectedSpeaker={selectedSpeaker}
        changeSelectedSpeaker={changeSelectedSpeaker}
      />

      <AddCoursePopup
        isOpen={isOpenAddCoursePopup}
        handleHide={handleCloseAddCoursePopup}
        createCourse={createCourse}
      />

      <RemoveCoursePopup
        isOpen={isOpenRemoveCoursePopup}
        handleHide={handleCloseRemoveCoursePopup}
        deleteCourse={deleteCourse}
      />
    </div>
  );
};

export default Courses;
