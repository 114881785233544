import { phrases } from "constants/phrases";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { useGetRoles } from "utils/useGetRoles";
import { InputTextarea } from "primereact/inputtextarea";
import { assignToCourse } from "api/admins/assignToCourse";
import { useAppDispatch } from "utils/hooks";
import { useRevalidator } from "react-router";
import { changeCourseViewAsync } from "api/courses/changeCourseView";
import { getShortAdminsListAsync } from "api/admins/getShortAdminsList";
import { getShortDirectionsListAsync } from "api/directions/getShortDirectionsList";
import { TCourseInfo, TUpdateCourseData } from "typings/course";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, useCallback, useEffect, useState } from "react";
import {
  ADMIN_COURSE_STATUSES_LIST,
  COURSE_LEVEL_LIST,
  SPEAKER_COURSE_STATUSES_LIST,
} from "constants/course";

import styled from "./PromoTab.module.scss";
import PopularQuestions from "./PopularQuestions";
import WhatWillYouLearn from "./WhatWillYouLearn";
import OutCourseToTopPopup from "popups/out-course-to-top/OutCourseToTopPopup";
import OutCourseFromTopPopup from "popups/out-course-from-top/OutCourseFromTopPopup";
import WhoIsCourseSuitableFor from "./WhoIsCourseSuitableFor";
import OutCourseToMainPagePopup from "popups/out-course-to-main-page/OutCourseToMainPagePopup";
import OutCourseFromMainPagePopup from "popups/out-course-from-main-page/OutCourseFromMainPagePopup";
import AdditionalInfo from "./AdditionalInfo";
import { InputNumber } from "primereact/inputnumber";
import PopupDateTimeCalendar from "popups/date-time/PopupDateTimeCalendar";

type PromoTabProps = {
  courseInfo: TCourseInfo;
  updateCourse: (courseData: TUpdateCourseData) => void;
  changeCourseStatus: (status: number) => void;
};

const PromoTab: FC<PromoTabProps> = (props) => {
  const [status, setStatus] = useState<number | null>(null);
  const [directions, setDirections] = useState<{ value: number; label: string }[]>([]);
  const [selectedDirection, setSelectedDirection] = useState<number | null>(null);
  const [speakers, setSpeakers] = useState<{ value: number; label: string }[]>([]);
  const [curators, setCurators] = useState<{ value: number; label: string }[]>([]);
  const [selectedCurators, setSelectedCurators] = useState<number[]>([]);
  const [selectedSpeaker, setSelectedSpeaker] = useState<number | null>(null);
  const [level, setLevel] = useState<number | null>(null);
  const [description, setDescription] = useState<string>("");
  const [isOpenOutCourseToTopPopup, setIsOpenOutCourseToTopPopup] = useState<boolean>(false);
  const [isOpenOutCourseFromTopPopup, setIsOpenOutCourseFromTopPopup] = useState<boolean>(false);
  const [isOpenOutCourseFromMainPagePopup, setIsOpenOutCourseFromMainPagePopup] =
    useState<boolean>(false);
  const [isOpenOutCourseToMainPagePopup, setIsOpenOutCourseToMainPagePopup] =
    useState<boolean>(false);

  const [duration, setDuration] = useState<number | null>();
  const [createdAt, setCreatedAt] = useState<Date | null>();

  const { isAdmin, isSpeacker, isCurator } = useGetRoles();

  const dispatch = useAppDispatch();

  const handleOpenOutCourseToTopPopup = () => setIsOpenOutCourseToTopPopup(true);
  const handleCloseOutCourseToTopPopup = () => setIsOpenOutCourseToTopPopup(false);

  const handleOpenOutCourseFromTopPopup = () => setIsOpenOutCourseFromTopPopup(true);
  const handleCloseOutCourseFromTopPopup = () => setIsOpenOutCourseFromTopPopup(false);

  const handleOpenOutCourseToMainPagePopup = () => setIsOpenOutCourseToMainPagePopup(true);
  const handleCloseOutCourseToMainPagePopup = () => setIsOpenOutCourseToMainPagePopup(false);

  const handleOpenOutCourseFromMainPagePopup = () => setIsOpenOutCourseFromMainPagePopup(true);
  const handleCloseOutCourseFromMainPagePopup = () => setIsOpenOutCourseFromMainPagePopup(false);

  const changeOutToTopValue = () => {
    if (props.courseInfo.isTop) handleOpenOutCourseFromTopPopup();
    else handleOpenOutCourseToTopPopup();
  };

  const changeOutToMainPage = () => {
    if (props.courseInfo.isPromo) handleOpenOutCourseFromMainPagePopup();
    else handleOpenOutCourseToMainPagePopup();
  };

  useEffect(() => {
    if (props.courseInfo) {
      setDescription(props.courseInfo.description);
      setSelectedDirection(props.courseInfo?.direction?.id || null);
      setSelectedSpeaker(props.courseInfo?.speaker?.id || null);
      setLevel(props.courseInfo.level ?? null);
      setStatus(props.courseInfo.status);
      setSelectedCurators(props.courseInfo.curators.map((el) => el.id));
      setDuration(props.courseInfo.duration);
      setCreatedAt(props.courseInfo.createdAt ? new Date(props.courseInfo.createdAt) : null);
    }
  }, [props.courseInfo]);

  const getDirections = useCallback(async () => {
    try {
      const response = await getShortDirectionsListAsync();
      if (response.status === 200 || response.status === 201) {
        setDirections(response.data.map((el) => ({ value: el.id, label: el.header })));
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
  }, [dispatch]);

  useEffect(() => {
    getDirections();
  }, [getDirections]);

  const getSpeakers = useCallback(async () => {
    try {
      const response = await getShortAdminsListAsync("speaker");
      if (response.status === 200 || response.status === 201) {
        setSpeakers(
          response.data.map((el) => ({ value: el.id, label: `${el.firstName} ${el.lastName}` }))
        );
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
  }, [dispatch]);

  const getCurators = useCallback(async () => {
    try {
      const response = await getShortAdminsListAsync("curator");
      if (response.status === 200 || response.status === 201) {
        setCurators(
          response.data.map((el) => ({ value: el.id, label: `${el.firstName} ${el.lastName}` }))
        );
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
    }
  }, [dispatch]);

  useEffect(() => {
    getSpeakers();
    getCurators();
  }, [getCurators, getSpeakers]);

  const revalidator = useRevalidator();

  const changeCoursePromo = useCallback(
    async (value: boolean) => {
      try {
        const response = await changeCourseViewAsync(props.courseInfo.id, { isPromo: value });
        if (response.status === 200 || response.status === 201) {
          revalidator.revalidate();
          dispatch(
            addSuccessMessage(
              value
                ? phrases.course_sucess_out_to_main_page
                : phrases.course_sucess_out_from_main_page
            )
          );
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      } finally {
        value ? handleCloseOutCourseToMainPagePopup() : handleCloseOutCourseFromMainPagePopup();
      }
    },
    [dispatch, props.courseInfo.id, revalidator]
  );

  const changeCourseViewTop = useCallback(
    async (value: boolean) => {
      try {
        const response = await changeCourseViewAsync(props.courseInfo.id, { isTop: value });
        if (response.status === 200 || response.status === 201) {
          revalidator.revalidate();
          dispatch(
            addSuccessMessage(
              value ? phrases.course_sucess_out_to_top : phrases.course_sucess_out_from_top
            )
          );
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
      } finally {
        value ? handleCloseOutCourseToTopPopup() : handleCloseOutCourseFromTopPopup();
      }
    },
    [dispatch, props.courseInfo.id, revalidator]
  );

  const changeDirection = (value: number) => {
    setSelectedDirection(value);
    props.updateCourse({ directionId: value });
  };

  const changeSpeaker = (value: number) => {
    setSelectedSpeaker(value);
    props.updateCourse({ speakerId: value });
  };

  const changeLevel = (value: number) => {
    setLevel(value);
    props.updateCourse({ level: value });
  };

  const changeDesription = () => {
    if (props.courseInfo.description === description) return;
    props.updateCourse({ description });
  };

  const changeDuration = () => {
    if (duration === props.courseInfo.duration) return;
    props.updateCourse({ duration: duration ?? undefined });
  };

  const changeCreatedAt = (value: Date | null) => {
    if (createdAt === value) return;
    props.updateCourse({ createdAt: value ? value.toISOString() : undefined });
  };

  const changeStatus = (value: number) => {
    setStatus(value);
    props.changeCourseStatus(value);
  };

  const changeCurators = async (value: number) => {
    if (selectedCurators.includes(value)) {
      setSelectedCurators((prev) => prev.filter((el) => el !== value));
      await assignToCourse(value, { courseId: props.courseInfo.id, isDelete: true });
    } else {
      setSelectedCurators((prev) => [...prev, value]);
      await assignToCourse(value, { courseId: props.courseInfo.id, isDelete: false });
    }
  };

  const filtredCurators = curators.filter((el) => !selectedCurators.includes(el.value));

  return (
    <div className={styled["container"]}>
      <div className={styled["checkboxes-and-curators"]}>
        <div className={styled["checkboxes"]}>
          <div className={styled["checkbox-wrapper"]}>
            <Checkbox
              onChange={changeOutToMainPage}
              checked={props.courseInfo?.isPromo}
              disabled={!isAdmin}
            ></Checkbox>
            <p className={styled["checkbox-label"]}>Выводить на главную</p>
          </div>
          <div className={styled["checkbox-wrapper"]}>
            <Checkbox
              onChange={changeOutToTopValue}
              checked={props.courseInfo?.isTop}
              disabled={!isAdmin}
            ></Checkbox>
            <p className={styled["checkbox-label"]}>Вывести в ТОП</p>
          </div>
        </div>
        <div className={styled["curators-list"]}>
          <p>Кураторы:</p>
          {curators.map((curator) => {
            if (selectedCurators.some((el) => el === curator.value)) {
              return (
                <p key={curator.value} className={styled["curator-label"]}>
                  {curator.label}{" "}
                  <i className="pi pi-times" onClick={() => changeCurators(curator.value)}></i>
                </p>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
      <div className={styled["card"]}>
        <div className={styled["card-row"]}>
          <div className={styled["dropdown-wrapper"]}>
            <p className={styled["dropdown-label"]}>Спикер</p>
            <Dropdown
              options={speakers}
              onChange={(e) => changeSpeaker(e.value)}
              value={selectedSpeaker}
              placeholder="Выберите спикера"
              className={styled["dropdown"]}
              disabled={!isAdmin}
            />
          </div>
          <div className={styled["dropdown-wrapper"]}>
            <p className={styled["dropdown-label"]}>Статус</p>
            <Dropdown
              options={
                isAdmin
                  ? ADMIN_COURSE_STATUSES_LIST
                  : isSpeacker
                  ? SPEAKER_COURSE_STATUSES_LIST
                  : []
              }
              onChange={(e) => changeStatus(e.value)}
              value={status}
              placeholder="Выберите статус"
              className={styled["dropdown"]}
              disabled={isCurator}
            />
          </div>
        </div>
        <div className={styled["card-row"]}>
          <div className={styled["dropdown-wrapper"]}>
            <p className={styled["dropdown-label"]}>Направление</p>
            <Dropdown
              options={directions}
              onChange={(e) => changeDirection(e.value)}
              value={selectedDirection}
              placeholder="Выберите направление"
              className={styled["dropdown"]}
              disabled={!isAdmin && !isSpeacker}
            />
          </div>
          <div className={styled["dropdown-wrapper"]}>
            <p className={styled["dropdown-label"]}>Уровень</p>
            <Dropdown
              options={COURSE_LEVEL_LIST}
              onChange={(e) => changeLevel(e.value)}
              value={level}
              placeholder="Выберите уровень"
              className={styled["dropdown"]}
              disabled={!isAdmin}
            />
          </div>
        </div>
        <div className={styled["curators-row"]}>
          <p className={styled["dropdown-label"]}>Кураторы</p>
          <Dropdown
            value={null}
            onChange={(e) => changeCurators(e.value)}
            options={filtredCurators}
            placeholder="Выберите кураторов"
            className={styled["dropdown"]}
            disabled={!isAdmin}
          />
        </div>

        <div className={styled["curators-row"]}>
          <p className={styled["dropdown-label"]}>Описание</p>
          <InputTextarea
            maxLength={1300}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={3}
            cols={30}
            autoResize
            placeholder="Заполнитель"
            className={styled["text-area"]}
            onBlur={changeDesription}
            disabled={!isAdmin && !isSpeacker}
          />
        </div>
        <div className={styled["card-row"]}>
          <div className={styled["dropdown-wrapper"]}>
            <p className={styled["dropdown-label"]}>Длительность курса (в часах)</p>
            <InputNumber
              className={styled["input-number"]}
              value={duration}
              onChange={(e) => setDuration(e.value)}
              onBlur={changeDuration}
              disabled={!isAdmin && !isSpeacker}
            />
          </div>
          <div className={styled["dropdown-wrapper"]}>
            <p className={styled["dropdown-label"]}>Дата создания</p>
            <PopupDateTimeCalendar
              placeholder="Дата создания"
              inputClassName={styled["date"]}
              spanClassName={styled["date-wrapper"]}
              date={createdAt}
              setDate={(date) => {
                setCreatedAt(date);
                changeCreatedAt(date);
              }}
            />
          </div>
        </div>

        <AdditionalInfo updateCourse={props.updateCourse} courseInfo={props.courseInfo} />

        <WhatWillYouLearn updateCourse={props.updateCourse} courseInfo={props.courseInfo} />

        <WhoIsCourseSuitableFor updateCourse={props.updateCourse} courseInfo={props.courseInfo} />

        <PopularQuestions updateCourse={props.updateCourse} courseInfo={props.courseInfo} />
      </div>

      <OutCourseToTopPopup
        isOpen={isOpenOutCourseToTopPopup}
        handleHide={handleCloseOutCourseToTopPopup}
        changeCourseViewTop={changeCourseViewTop}
      />

      <OutCourseFromTopPopup
        isOpen={isOpenOutCourseFromTopPopup}
        handleHide={handleCloseOutCourseFromTopPopup}
        changeCourseViewTop={changeCourseViewTop}
      />

      <OutCourseToMainPagePopup
        isOpen={isOpenOutCourseToMainPagePopup}
        handleHide={handleCloseOutCourseToMainPagePopup}
        changeCoursePromo={changeCoursePromo}
      />

      <OutCourseFromMainPagePopup
        isOpen={isOpenOutCourseFromMainPagePopup}
        handleHide={handleCloseOutCourseFromMainPagePopup}
        changeCoursePromo={changeCoursePromo}
      />
    </div>
  );
};

export default PromoTab;
