import http from "../https";

type getAllAdminsParamsType = {
    page?: number;
    limit?: number;
    search?: string;
};

export const getAdminsAsync = (params: getAllAdminsParamsType) =>
    http.get(
        `/api/admins`,
        {},
        {
            params,
        }
    );
